import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";

import { MyLoading} from "../common/MyModal";
import { API_URLS } from "../../utils/RequestManager";
// import { MySelect } from "../common/MySelect";
// import { MyDateInput } from "../common/MyDateInput";
// import { MyInput } from "../common/MyInput";
import { IReservation, IReservationsFilterOptions } from "../common/types";

import { WEB_ROUTERS } from "../../utils/routes";
// import { ReservationsBlock } from "../common/ReservationsBlock";
import { InfoDialog } from "./TouristRegistration/components/InfoDialog";
// import { PropertySelector } from "./TouristRegistration/components/PropertySelector";
// import { DateRangeFilter } from "./TouristRegistration/components/DateRangeFilter";
// import { SearchBar } from "./TouristRegistration/components/SearchBar";
import { Property } from "./TouristRegistration/types/booking";
import { ReservationsBlockV2 } from "../common/ReservationsBlockV2";
import { FilterHeader } from "../common/components/FilterHeader";
// import { PrimaryButton } from "../common/components/PrimaryButton";

interface IReservationsPageProps {
  tableName: string;
}

export const ReservationsPage: React.FC<IReservationsPageProps> = ({
  tableName,
}) => {
  const { getText, user, requestManager } = useContext(AppContext);

  const [filterOptions, setFilterOptions] =
    useState<IReservationsFilterOptions>({
      reservationId: "",
      from: "",
      to: "",
      guestName: "",
    });
  const [showAllGuests, setShowAllGuests] = useState<boolean>(true);

  const [reservationsInfo, setReservationsInfo] = useState<IReservation[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const inProgress = useRef(false);

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  useEffect(() => {
    refreshReservationsInfo();
  }, []);

  const refreshReservationsInfo = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await loadReservationsInfo();
    inProgress.current = false;
  };

  const loadReservationsInfo = async () => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_INFO_GETALL
    );
    if (resp.status === 200) {
      const reservations_ = resp.data;
      setReservationsInfo(reservations_);
      setProperties(
        reservations_.map((r: IReservation) => ({
          id: r.id,
          name: r.roat_number,
          location: r.address,
        }))
      );
      setLoading(false);
      return reservations_;
    } else {
      setModalText("Failed to load reservation info");
    }
    setLoading(false);
    return [];
  };

  const handleReservationCancel = async (
    reservation_application_id: number,
    canceled_at: string | null
  ) => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_APPLICATIONS_SAVE,
      { id: reservation_application_id, canceled_at }
    );
    if (resp.status === 200) {
      await refreshReservationsInfo();
      setLoading(false);
      return;
    } else {
      setModalText("Failed to change cancel");
    }
    setLoading(false);
  };

  const handleReservationDeposit = async (
    reservation_application_id: number,
    deposit_amount: number
  ) => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_APPLICATIONS_SAVE,
      { id: reservation_application_id, deposit_amount }
    );
    if (resp.status === 200) {
      await refreshReservationsInfo();
      setLoading(false);
      return;
    } else {
      setModalText("Failed to change keep");
    }
    setLoading(false);
  };

  // const reservationSelectOptions = reservationsInfo.map((rinfo, ri) => {
  //   return { value: rinfo.id, label: rinfo.address };
  // });

  const selects = properties.map((p) => ({
    key: p.id,
    label: p.name + " - " + p.location,
  }));

  return (
    <div className="page-content">
      <div className="form-card" style={{ overflow: "auto" }}>
        <FilterHeader
          selects={selects}
          selected={filterOptions.reservationId}
          setSelected={(v) =>
            setFilterOptions((s) => ({ ...s, reservationId: v }))
          }
          startDate={filterOptions.from}
          endDate={filterOptions.to}
          setStartDate={(v) => setFilterOptions((s) => ({ ...s, from: v }))}
          setEndDate={(v) => setFilterOptions((s) => ({ ...s, to: v }))}
          searchTerm={filterOptions.guestName}
          setSearchTerm={(v) => setFilterOptions((s) => ({ ...s, guestName: v }))}
          searchPlaceholder="Search"
        />
        {/* <div className="mb-6 space-y-4">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
              <PropertySelector
                properties={properties}
                selectedProperty={filterOptions.reservationId}
                onPropertyChange={(v) =>
                  setFilterOptions((s) => ({ ...s, reservationId: v }))
                }
              />
              <DateRangeFilter
                startDate={filterOptions.from}
                endDate={filterOptions.to}
                onStartDateChange={(v) =>
                  setFilterOptions((s) => ({ ...s, from: v }))
                }
                onEndDateChange={(v) =>
                  setFilterOptions((s) => ({ ...s, to: v }))
                }
              />
            </div>
          </div>

          <SearchBar
            value={filterOptions.guestName}
            onChange={(v) => setFilterOptions((s) => ({ ...s, guestName: v }))}
            placeholder="Search tourist names..."
          />
        </div> */}

        {/* <div className="reservation-filters">
          <MySelect
            options={[{ value: -1, label: "All" }, ...reservationSelectOptions]}
            value={filterOptions.reservationId}
            onChange={(v: any) =>
              setFilterOptions({ ...filterOptions, reservationId: v })
            }
          />
          <MyDateInput
            value={filterOptions.from}
            setValue={(v) => setFilterOptions({ ...filterOptions, from: v })}
          />
          <MyDateInput
            value={filterOptions.to}
            setValue={(v) => setFilterOptions({ ...filterOptions, to: v })}
          />
          <MyInput
            type="text"
            label={""}
            value={filterOptions.guestName}
            setValue={(v) =>
              setFilterOptions({ ...filterOptions, guestName: v })
            }
            placeholder="Search Guest Name"
          />
          <div
            className={showAllGuests ? "btn filled" : "btn outline"}
            onClick={() => setShowAllGuests((v) => !v)}
          >
            {getText("btn_guests")}
          </div>
        </div> */}
        <div style={{width: '200px', paddingBottom: '1rem'}}>
        <div
            className={showAllGuests ? "btn filled" : "btn outline"}
            onClick={() => setShowAllGuests((v) => !v)}
          >
            {getText("btn_guests")}
          </div>
          </div>
        <ReservationsBlockV2
          reservationsInfo={reservationsInfo}
          tableName={tableName}
          onCancel={handleReservationCancel}
          onDeposit={handleReservationDeposit}
          filterOptions={filterOptions}
          showAllGuests={showAllGuests}
        />
      </div>
      <InfoDialog
        isOpen={!!modalText}
        title="Information"
        message={modalText ?? ""}
        onClose={() => setModalText("")}
        onConfirm={() => setModalText("")}
      />
      {/* {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )} */}
      {loading && <MyLoading />}
    </div>
  );
};
