export const WEB_ROUTERS = {
  ROOT: "/",
  PRICES: "/prices",
  // TERMS: "/terms",
  SERVICES: "/services",
  USER_GUIDE: "/guide",
  LEGISLATION_IN_FORCE: "/legislation",
  APP_DOWNLOAD: "/app_download",
  TOURIST_RESERVATION: "/tourist/reservation",
  // TOURIST_GUESTS: "/tourist/reservation/guests",
  TOURIST_GUESTS_STEPS: "/tourist/reservation/guests_steps",
  TOURIST_COMPLETE: "/tourist/reservation/guests/complete",
  TOURIST_PAYMENT: "/tourist/payment",
  TOURIST_PAYMENT_TEST: "/tourist/payment/test",
  TOURIST_PAYMENT_SUCCESS_PAGE: "/tourist/payment/success",
  // TOURIST_PAYMENT_CHECKOUT: "/tourist/payment/checkout",

  CUSTOMER_LOGIN: "/customer/login",
  CUSTOMER_REGISTER: "/customer/register",
  // CUSTOMER_LOGIN_OR_REGISTER: "/customer/login_or_register",
  CUSTOMER_PASSWORD_RESET: "/customer/password_reset",
  // CUSTOMER_ACCOMODATIONS: "/customer/accomodations",
  CUSTOMER_DASHBOARD: "/customer/dashboard",
  CUSTOMER_ROAT: "/customer/roat",
  CUSTOMER_REGISTER_USER_BATCH: "/customer/register_user_batch",

  // NEW
  CUSTOMER_PROPERTIES: "/customer/properties",
  CUSTOMER_RESERVATIONS: "/customer/reservations",
  // CUSTOMER_RESERVATIONS_NEW: "/customer/reservations_new",
  CUSTOMER_IEAT: "/customer/ieat",
  CUSTOMER_DEPOSIT: "/customer/deposit",
  CUSTOMER_ACCOUNT: "/customer/account",
  CUSTOMER_INVOICES: "/customer/invoices",
  CUSTOMER_PAYMENT: "/customer/payment",
  CUSTOMER_TOURIST_REGISTRATIONS: "/customer/tourist_registrations",
  CUSTOMER_SUBSCRIPTION_TEST: "/customer/subscription/test",

  ADMIN_LOGIN: "/admin/login",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_MANAGERS: "/admin/managers",
  ADMIN_PROPERTIES: "/admin/properties",
  ADMIN_RESERVATIONS: "/admin/reservations",
  ADMIN_IEAT: "/admin/ieat",
  ADMIN_DEPOSIT: "/admin/deposit",
  ADMIN_ACCOUNT: "/admin/account",
  ADMIN_INVOICES: "/admin/invoices",

  
};
