import React from "react";

interface ISelectFieldProps {
  label: string;
  icon?: any;
  value: string | number;
  onChange: (vakue: string | number) => void;
  messages?: string[];
  options: { value: string | number; label: string }[];
}

export const SelectField: React.FC<ISelectFieldProps> = ({
  label,
  icon,
  value,
  onChange,
  messages,
  options,
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 relative">
        {!!icon && (
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
            {icon}
          </span>
        )}
        <select
          value={value}
          className={
            "block w-full border-gray-300 rounded-md focus:ring-[#E47E7B] focus:border-[#E47E7B]" +
            (messages && messages.length > 0 ? " border-red-500" : "") +
            (!!icon ? " pl-10" : "")
          }
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((o, i) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
      {messages?.map((m, i) => (
        <p key={i} className="mt-1 text-sm text-red-500">
          {m}
        </p>
      ))}
    </div>
  );
};
