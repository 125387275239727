import React from "react";
import "./App.css";
import { HashRouter } from "react-router-dom";
import { Main } from "./components/Main";

window.onbeforeunload = function (event) {
  // Recommended
  event.preventDefault();

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};

function App() {
  return (
    <HashRouter>
      <Main />
    </HashRouter>
  );
}

export default App;
