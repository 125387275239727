import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import {
  notEmptyValidator,
  lengthValidator,
  trimForm,
  validateFormOptional,
  validateFormOptionalExt,
} from "../../utils/FormManager";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";
import { MyLoading } from "../common/MyModal";
import { TextField } from "../common/components/TextField";
import { Building2, Mail, Phone, User, Lock } from "lucide-react";
import { InfoDialog } from "./TouristRegistration/components/InfoDialog";
import { PrimaryButton } from "../common/components/PrimaryButton";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
  company_name: [],
  company_name_nrt: [],
};

const name2validatorsPassword = {
  password_old: [lengthValidator(8, 255)],
  password: [lengthValidator(8, 255)],
  password2: [lengthValidator(8, 255)],
};

interface IFormData {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  company_name: string;
  company_name_nrt: string;
  password_old: string;
  password: string;
  password2: string;
}

export function CustomerAccount() {
  const { requestManager, user, setUser, getText } = useContext(AppContext);

  const [formData, setFormData] = useState<IFormData>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    company_name: "",
    company_name_nrt: "",
    password_old: "",
    password: "",
    password2: "",
  });

  const [formMessages, setFormMessages] = useState<{
    [key in keyof IFormData]: string[];
  }>({
    firstname: [],
    lastname: [],
    phone: [],
    email: [],
    company_name: [],
    company_name_nrt: [],
    password_old: [],
    password: [],
    password2: [],
  });

  const [infoText, setInfoText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    setFormData((s) => ({
      ...s,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      email: user.email,
    }));
  }, [user]);

  // const updateMessages = (invalidFields: [string, string][]) => {
  //   if (invalidFields.length > 0) {
  //     const msgs = invalidFields.reduce((r: any, v) => {
  //       const key = v[0];
  //       if (!(key in r)) {
  //         r[key] = [];
  //       }
  //       r[key].push(v[1]);
  //       return r;
  //     }, {});
  //     setFormMessages((formMessages) => ({ ...formMessages, ...msgs }));
  //   }
  // };

  const refreshUser = async () => {
    setLoading(true);
    const res_user = await requestManager.postJson(API_URLS.USER_GET);
    if (res_user.status !== 200) {
      setLoading(false);
      return;
    }

    const user_ = res_user.data;

    setUser(user_);
    setLoading(false);
  };

  const checkData = () => {
    const data = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      phone: formData.phone,
      email: formData.email,
      company_name: formData.company_name,
      company_name_nrt: formData.company_name_nrt,
    };
    trimForm(data);
    const [isValid, msgs] = validateFormOptionalExt(data, name2validators);
    setFormMessages((s) => ({ ...s, ...msgs }));
    if (isValid) {
      return data;
    }
    return null;
  };

  const checkDataPassword = () => {
    const data = {
      password_old: formData.password_old,
      password: formData.password,
      password2: formData.password2,
    };
    trimForm(data);
    const [isValid, msgs] = validateFormOptionalExt(
      data,
      name2validatorsPassword
    );
    setFormMessages((s) => ({ ...s, ...msgs }));
    if (isValid) {
      return data;
    }
    return null;
  };

  const handleSaveUserData = async () => {
    setLoading(true);
    const data = checkData();
    if (!data) {
      setInfoText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_USER_DATA_SAVE,
      data
    );

    if (resp.status === 200) {
      // console.log(resp);
      setInfoText(getText("m_user_data_update_succ"));
      refreshUser();
    } else {
      setInfoText(getText("m_user_data_update_fail"));
    }

    setLoading(false);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const data = checkDataPassword();
    if (!data) {
      setInfoText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_USER_PASSWORD_SAVE,
      data
    );

    if (resp.status === 200) {
      // console.log(resp);
      setInfoText(getText("m_user_password_update_succ"));
    } else {
      setInfoText(getText("m_user_password_update_fail"));
    }

    setLoading(false);
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setFormData({ ...formData, ...newData });
    const newMsgs = validateFormOptional(newData, name2validators);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  return (
    <div className="page-content">
      <div className="form-container w520_ifw">
        <div className="form-card">
          <div className="control__wrap">
            <div className="form__control">
              <TextField
                icon={<User className="h-5 w-5 text-gray-400" />}
                label={getText("t_firstname")}
                placeholder={getText("ph_firstname")}
                type="text"
                value={formData.firstname}
                onChange={(v) => handleChange("firstname", v)}
                messages={formMessages.firstname}
              />
            </div>
            <div className="form__control">
              <TextField
                icon={<User className="h-5 w-5 text-gray-400" />}
                label={getText("t_lastname")}
                placeholder={getText("ph_lastname")}
                type="text"
                value={formData.lastname}
                onChange={(v) => handleChange("lastname", v)}
                messages={formMessages.lastname}
              />
            </div>
            <div className="form__control">
              <TextField
                icon={<Mail className="h-5 w-5 text-gray-400" />}
                label={getText("l_email")}
                placeholder={getText("ph_email")}
                type="email"
                value={formData.email}
                onChange={(v) => handleChange("email", v)}
                messages={formMessages.email}
              />
            </div>
            <div className="form__control">
              <TextField
                icon={<Mail className="h-5 w-5 text-gray-400" />}
                label={getText("l_email")}
                placeholder={getText("ph_email")}
                type="email"
                value={formData.email}
                onChange={(v) => handleChange("email", v)}
                messages={formMessages.email}
              />
            </div>

            <div className="form__control">
              <TextField
                icon={<Phone className="h-5 w-5 text-gray-400" />}
                label={getText("l_phone")}
                placeholder={getText("ph_phone")}
                type="phone"
                value={formData.phone}
                onChange={(v) => handleChange("phone", v)}
                messages={formMessages.phone}
              />
            </div>

            <div className="form__control">
              <TextField
                icon={<Building2 className="h-5 w-5 text-gray-400" />}
                label={getText("t_companyname")}
                placeholder={getText("ph_companyname")}
                type="text"
                value={formData.company_name}
                onChange={(v) => handleChange("company_name", v)}
                messages={formMessages.company_name}
              />
            </div>
            <div className="form__control">
              <TextField
                icon={<Building2 className="h-5 w-5 text-gray-400" />}
                label={getText("t_companyname_nrt")}
                placeholder={getText("ph_companyname_nrt")}
                type="text"
                value={formData.company_name_nrt}
                onChange={(v) => handleChange("company_name_nrt", v)}
                messages={formMessages.company_name_nrt}
              />
            </div>
          </div>

          <div className="control_center">
            <PrimaryButton handleClick={handleSaveUserData}>
              {getText("btn_save_data")}
            </PrimaryButton>
          </div>
        </div>

        <div className="control__wrap">
          <div className="form-card">
            <div className="control__wrap">
              <div className="form__control">
                <TextField
                  icon={<Lock className="h-5 w-5 text-gray-400" />}
                  label={getText("l_password_old")}
                  placeholder={getText("ph_password_old")}
                  type="password"
                  value={formData.password_old}
                  onChange={(v) => handleChange("password_old", v)}
                  messages={formMessages.password_old}
                />
              </div>
              <div className="form__control">
                <TextField
                  icon={<Lock className="h-5 w-5 text-gray-400" />}
                  label={getText("l_password")}
                  placeholder={getText("ph_password")}
                  type="password"
                  value={formData.password}
                  onChange={(v) => handleChange("password", v)}
                  messages={formMessages.password}
                />
              </div>
              <div className="form__control">
                <TextField
                  icon={<Lock className="h-5 w-5 text-gray-400" />}
                  label={getText("l_password2")}
                  placeholder={getText("ph_password")}
                  type="password"
                  value={formData.password2}
                  onChange={(v) => handleChange("password2", v)}
                  messages={formMessages.password2}
                />
                {formData.password !== formData.password2 && (
                  <p className="error-message">{getText("m_pass_not_match")}</p>
                )}
              </div>
            </div>
            <div className="control_center">
              <PrimaryButton handleClick={handleChangePassword}>
                {getText("btn_save_new_password")}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText("")}
        onConfirm={() => setInfoText("")}
      />

      {/* {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )} */}
      {loading && <MyLoading />}
    </div>
  );
}
