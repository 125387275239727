import React, { useContext, useEffect, useState } from "react";
import { IFormInitData, InvoiceForm } from "./components/InvoiceForm";
import { InvoiceCalculator } from "./components/InvoiceCalculator";
import { InvoicePreview } from "./components/InvoicePreview";
import { InvoiceData, CalculationResult } from "./types";
import { Receipt } from "lucide-react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/RequestManager";
import { WEB_ROUTERS } from "../../utils/routes";

function calculateAge(date: Date) {
  // birthday is a date
  var ageDifMs = Date.now() - date.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function InvoicesPageCustomer() {
  const [raInfos, setRaInfos] = useState<any | null>(null);
  const [formInitData, setFormInitData] = useState<IFormInitData | null>(null);
  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
  const [calculation, setCalculation] = useState<CalculationResult | null>(
    null
  );
  const { requestManager, user } = useContext(AppContext);

  // const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    }
    getReservationApplocationData();
  }, []);

  useEffect(() => {
    setUpRAData(raInfos);
  }, [raInfos]);

  

  const getReservationApplocationData = async () => {
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_INFO_GETALL,
      {}
    );
    if (resp.status === 200) {
      const info = resp.data;
      setRaInfos(info);
    } else {
    }
  };

  const setUpRAData = (raInfos: any) => {
    if (
      !raInfos ||
      raInfos.length === 0
    ) {
      return;
    }
    const reservationWithReservaitonApplications = raInfos.find((r:any) => r["reservation_applications"] && r["reservation_applications"].length > 0);
    if (!reservationWithReservaitonApplications) {
      return;
    }
    const raInfo = reservationWithReservaitonApplications["reservation_applications"][0];
    raInfo.address = reservationWithReservaitonApplications.address;
    raInfo.owner = `${user?.firstname ?? ''} ${user?.lastname ?? ''}`;
    raInfo.email = user?.email ?? '';

    let nights: number = 0;
    if (raInfo.checkin_date && raInfo.checkout_date) {
      const checkin_date_d = new Date(raInfo.checkin_date);
      const checkout_date_d = new Date(raInfo.checkout_date);
      nights = Math.floor(
        (checkout_date_d.getTime() - checkin_date_d.getTime()) /
          (1000 * 60 * 60 * 24)
      );
    }
    let ng16 = 0;
    let nle16 = 0;

    raInfo.guests?.forEach((guest: any) => {
      let age = 0;
      if (guest.birth_date) {
        const birth_date_d = new Date(guest.birth_date);
        age = calculateAge(birth_date_d);
      }

      if (age > 16) {
        ng16++;
      } else {
        nle16++;
      }
    });

    const res: IFormInitData = {
      customerName: raInfo.owner,
      email: raInfo.email,
      adults: ng16,
      nights: nights,
      checkIn: raInfo.checkin_date,
      checkOut: raInfo.checkout_date,
      address: raInfo.address,
    };
    setFormInitData(res);
  };

  const handleInvoiceSubmit = (data: InvoiceData) => {
    setInvoiceData(data);
  };

  const handleCalculation = (result: CalculationResult) => {
    setCalculation(result);
  };

  const handleProceedToPayment = () => {
    // TODO: Integrate with Stripe payment link
    alert("Stripe payment integration will be added here");
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center gap-2">
            <Receipt className="w-8 h-8 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">
              IEAT Tourist Tax Invoice Generator
            </h1>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        {!invoiceData ? (
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Enter Invoice Details
              </h2>
              <InvoiceForm
                onSubmit={handleInvoiceSubmit}
                formInitData={formInitData}
              />
            </div>
          </div>
        ) : !calculation ? (
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Calculate Tax Amount
              </h2>
              <InvoiceCalculator
                adults={invoiceData.adults}
                nights={invoiceData.nights}
                onCalculate={handleCalculation}
              />
            </div>
          </div>
        ) : (
          <InvoicePreview
            invoiceData={invoiceData}
            calculation={calculation}
            onProceedToPayment={handleProceedToPayment}
          />
        )}
      </main>
    </div>
  );
}
