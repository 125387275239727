import { CheckCircle } from "lucide-react";
import React from "react";

export const SuccessPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-2xl mx-auto px-4">
        <div className="text-center space-y-4">
          <CheckCircle className="w-16 h-16 text-primary mx-auto" />
          <h2 className="text-2xl font-bold text-gray-700">
            Check-in Complete!
          </h2>
          <p className="text-gray-600">
            All tourists have been successfully registered.
          </p>
        </div>
      </div>
    </div>
  );
};
