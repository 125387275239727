import { PropsWithChildren } from "react";

interface IPrimaryButtonProps {
  type?: "button" | "submit" | "reset";
  handleClick?: any;
  disabled?: boolean;

}

export const PrimaryButton: React.FC<
  IPrimaryButtonProps & PropsWithChildren
> = ({ type = "button", handleClick,disabled=false, children }) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primaryDark transition-colors"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
