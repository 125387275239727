import React from "react";
import { Building } from "lucide-react";
import { Property } from "../types/booking";

// const mockProperties: Property[] = [
//   { id: 'prop1', name: 'Mountain View Villa', location: 'Andorra la Vella' },
//   { id: 'prop2', name: 'Ski Lodge', location: 'Soldeu' },
//   { id: 'prop3', name: 'City Center Apartment', location: 'Escaldes-Engordany' },
// ];

interface PropertySelectorProps {
  properties: Property[];
  selectedProperty: string;
  onPropertyChange: (propertyId: string) => void;
}

export function PropertySelector({
  properties,
  selectedProperty,
  onPropertyChange,
}: PropertySelectorProps) {
  return (
    <div className="flex items-center space-x-2">
      <Building className="w-5 h-5 text-gray-500" />
      <select
        value={selectedProperty}
        onChange={(e) => onPropertyChange(e.target.value)}
        className="rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
      >
        <option value="">All Properties</option>
        {properties.map((property) => (
          <option key={property.id} value={property.id}>
            {property.name} - {property.location}
          </option>
        ))}
      </select>
    </div>
  );
}
