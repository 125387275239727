import React, { useContext } from "react";
import { PrimaryButton } from "../components/PrimaryButton";
import { AppContext } from "../../../utils/AppContext";

interface IPaymentButtonProps {
  url: string;
  payload: any;
  auth?: boolean;
  loading: boolean;
  setLoading: (v: boolean) => void;
  setInfoText?: (v: string) => void;
  text?: string;
}

export const PaymentButton: React.FC<IPaymentButtonProps> = ({
  url,
  payload,
  auth = true,
  loading,
  setLoading,
  setInfoText,
  text='Pay',
}) => {
  const { requestManager } = useContext(AppContext);

  const handleClick = async () => {
    setLoading(true);
    // const resp = await requestManager.postJson(
    //   API_URLS.TOURIST_V2_PAYMENT_TEST,
    //   {},
    //   false
    // );
    const resp = await requestManager.postJson(url, payload, auth);
    setLoading(false);
    if (resp.status === 200) {
      const session = resp.data;
      // console.log(session);
      window.onbeforeunload = null;
      window.location.href = session.url;
      return true;
    } else {
      setInfoText && setInfoText("Failed initialize payment");
    }
  };

  return (
    <PrimaryButton disabled={loading} handleClick={handleClick}>
      {text}
    </PrimaryButton>
  );
};
