import React from "react";
import { DateRangeFilter } from "../../Customer/TouristRegistration/components/DateRangeFilter";
import { SearchBar } from "../../Customer/TouristRegistration/components/SearchBar";
import { FilterSelector } from "./FilterSelector";

interface ISortedTableProps {
  selects: { key: string; label: string }[];
  selected: string;
  setSelected: (key: string) => void;
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  searchPlaceholder?: string;
}

export function FilterHeader({
  selects,
  selected,
  setSelected,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchTerm,
  setSearchTerm,
  searchPlaceholder,
}: ISortedTableProps) {
  return (
    <div className="mb-6 space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
          <FilterSelector
            selects={selects}
            selected={selected}
            setSelected={setSelected}
          />
          <DateRangeFilter
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
        </div>
      </div>

      <SearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder={searchPlaceholder}
      />
    </div>
  );
}
