import React, { useContext, useState } from "react";
import { Mail, Lock } from "lucide-react";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
  validateFormOptional,
  trimForm,
} from "../../../utils/FormManager";
import { API_URLS } from "../../../utils/RequestManager";
import { WEB_ROUTERS } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../utils/AppContext";
import { TextField } from "../../common/components/TextField";

const name2validators = {
  email: [notEmptyValidator()],
  password: [lengthValidator(8)],
};

interface ILoginFormProps {
  setModalText: (msg: string) => void;
  setLoading: (loading: boolean) => void;
  onRegisterClick: () => void;
  onForgotPasswordClick: () => void;
}

export function LoginForm({
  setModalText,
  setLoading,
  onRegisterClick,
  onForgotPasswordClick,
}: ILoginFormProps) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formMessages, setFormMessages] = useState<{
    email: string[];
    password: string[];
  }>({ email: [], password: [] });

  const navigate = useNavigate();

  const { requestManager, setUser, getText } = useContext(AppContext);

  const checkData = () => {
    const data = { ...formData };
    trimForm(data);
    const invalidFields = validateForm(data, name2validators);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages(msgs);
      return null;
    }
    return data;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle login logic here
    // console.log("Login:", formData);
    setLoading(true);

    const data = checkData();
    if (!data) {
      setLoading(false);
      return;
    }
    const res = await requestManager.login(data.email, data.password);
    if (res.status === 401) {
      setLoading(false);
      setModalText(getText("m_invalid_creds"));
      return;
    } else if (res.status !== 200) {
      setLoading(false);
      alert("Debug: " + JSON.stringify(res));
      return;
    }
    const res_user = await requestManager.postJson(API_URLS.USER_GET);
    if (res_user.status !== 200) {
      setLoading(false);
      alert("Debug: " + JSON.stringify(res));
      return;
    }

    const user_ = res_user.data;

    setUser(user_);
    setLoading(false);
    navigate(WEB_ROUTERS.CUSTOMER_PROPERTIES);
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setFormData({ ...formData, ...newData });
    const newMsgs = validateFormOptional(newData, name2validators);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <TextField
        icon={<Mail className="h-5 w-5 text-gray-400" />}
        label={getText("l_email")}
        placeholder={getText("ph_email")}
        type="email"
        value={formData.email}
        onChange={(v) => handleChange("email", v)}
        messages={formMessages.email}
      />

      <TextField
        icon={<Lock className="h-5 w-5 text-gray-400" />}
        label={getText("l_password")}
        placeholder={getText("ph_password")}
        type="password"
        value={formData.password}
        onChange={(v) => handleChange("password", v)}
        messages={formMessages.password}
      />

      <div className="flex items-center justify-between">
        <button
          type="button"
          onClick={onForgotPasswordClick}
          className="text-sm text-[#E47E7B] hover:text-[#D16B68]"
        >
          {getText("btn_reset_password")}
        </button>
      </div>

      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#E47E7B] hover:bg-[#D16B68] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E47E7B]"
      >
        {getText("btn_login")}
      </button>

      <div className="text-center">
        <span className="text-sm text-gray-600">
          {getText("m_dont_have_account")}
        </span>
        &nbsp;
        <button
          type="button"
          onClick={onRegisterClick}
          className="text-sm text-[#E47E7B] hover:text-[#D16B68]"
        >
          {getText("btn_register_now")}
        </button>
      </div>
    </form>
  );
}
