import React from 'react';
import { Tourist } from '../types/booking';
import { formatDate } from '../utils/dateUtils';
import { User } from 'lucide-react';

interface TouristListProps {
  tourists: Tourist[];
}

export function TouristList({ tourists }: TouristListProps) {
  return (
    <div className="space-y-4">
      {tourists.map((tourist) => (
        <div 
          key={tourist.id}
          className="bg-white rounded-lg shadow p-4 border-l-4 border-[#E47E7B]"
        >
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              <User className="h-5 w-5 text-[#E47E7B] mr-2" />
              <div>
                <h3 className="font-medium text-gray-900">
                  {tourist.firstName} {tourist.name}
                </h3>
                <p className="text-sm text-gray-500">
                  Born: {formatDate(tourist.dateOfBirth)}
                </p>
              </div>
            </div>
            <span className={`px-2 py-1 rounded-full text-xs font-medium ${
              tourist.isOver16 
                ? 'bg-green-100 text-green-800' 
                : 'bg-yellow-100 text-yellow-800'
            }`}>
              {tourist.isOver16 ? 'Adult' : 'Minor'}
            </span>
          </div>
          {tourist.photoId && (
            <div className="mt-2 text-sm text-gray-500">
              ID: {tourist.photoId}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}