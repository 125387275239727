import { IdCard } from "lucide-react";
import React from "react";

interface IMyFileInputProps {
  label?: string;
  btn_label?: string;
  setValue: (f: any) => void;
  mimes?: string;
  clsName?: string;
}
export const MyFileInput: React.FC<IMyFileInputProps> = ({
  label,
  btn_label,
  setValue,
  mimes = "image/png, image/jpeg",
  clsName="bg_success",
}) => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    const element = e.target;
    const file = element.files.length > 0 ? element.files[0] : null;
    // console.log("file:", file);
    setValue(file);
  };
  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <label className="flex flex-col items-center p-6 border-2 rounded-lg hover:border-primary transition-colors">
        <input
          type="file"
          style={{ position: "absolute", left: "-99999rem" }}
          accept={mimes}
          onChange={(e) => handleChange(e)}
        />
        <IdCard className="w-12 h-12 text-primary mb-2" />
        <span className="text-gray-700">{btn_label}</span>
      </label>
    </div>
  );
};
