import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../../utils/AppContext";
import { IGuest, IReservationApplication } from "../../common/types";
import { BookOpen, CreditCard } from "lucide-react";
import { MyFileInput } from "../../common/MyFileInput";
import { API_URLS } from "../../../utils/RequestManager";
import { TextField } from "../../common/components/TextField";
import {
  inDictValidator,
  isValidDateStr,
  notEmptyValidator,
  validateFormOptional,
} from "../../../utils/FormManager";
import { COUNTRY_ENGLISH, COUNTRY_TO_IDX } from "../../../utils/constants";
import { DateField } from "../../common/components/DateField";
import { AutocompleteField } from "../../common/components/AutocompleteField";
import { PrimaryButton } from "../../common/components/PrimaryButton";
import { LightButton } from "../../common/components/LightButton";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  place_of_birth: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")], // FIXME: translate this
  citizenship: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  residency: [inDictValidator(COUNTRY_TO_IDX, true, "Country not found")],
  birth_date: [isValidDateStr()],
};

interface ITouristFormsProps {
  reservationApplication: IReservationApplication;
  guest: IGuest;
  setGuest: (v: any) => void;
  onSave: () => void;
  setLoading: (v: boolean) => void;
  setInfoText: (v: string) => void;

  onBadPhoto?: () => void;
  onBadSave?: () => void;
  onShowModal?: (v: string) => void;
  onShowModalCounter?: (v: string | number | null) => void;
}
const DOCUMENT_PASSPORT = "passport";
const DOCUMENT_IDENTITY_CARD = "identity card";

export const TouristForms: React.FC<ITouristFormsProps> = ({
  reservationApplication,
  guest,
  setGuest,
  onSave,
  setLoading,
  setInfoText,

  onBadPhoto,
  onBadSave,
  onShowModal,
  onShowModalCounter,
}) => {
  // images
  const [state, setState] = useState<{
    documentType: string | null;
    imageRecto: any | null;
    imageVerso: any | null;
    extracted: boolean;
    confirmed: boolean;
  }>({
    documentType: null,
    imageRecto: null,
    imageVerso: null,
    extracted: false,
    confirmed: false,
  });

  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});
  // extraction timer
  // const [timedOut, setTimedOut] = useState<boolean>(false);
  const inProgress = useRef(false);
  const intervalId = useRef<number | null>(null);
  const { requestManager, getText } = useContext(AppContext);

  const renderDocumentSelect = () => {
    return (
      <div className="space-y-4">
        <h2 className="text-2xl font-bold text-center text-gray-700">
          Select Document Type
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() =>
              setState((s) => ({ ...s, documentType: DOCUMENT_IDENTITY_CARD }))
            }
            className="flex flex-col items-center p-6 border-2 rounded-lg hover:border-primary transition-colors"
          >
            <CreditCard className="w-12 h-12 text-primary mb-2" />
            <span className="text-gray-700">ID Card</span>
          </button>
          <button
            onClick={() =>
              setState((s) => ({ ...s, documentType: DOCUMENT_PASSPORT }))
            }
            className="flex flex-col items-center p-6 border-2 rounded-lg hover:border-primary transition-colors"
          >
            <BookOpen className="w-12 h-12 text-primary mb-2" />
            <span className="text-gray-700">Passport</span>
          </button>
        </div>
      </div>
    );
  };

  const _clearTimer = () => {
    if (intervalId.current) {
      window.clearInterval(intervalId.current);
      intervalId.current = null;
    }
    onShowModalCounter && onShowModalCounter(null);
  };
  const _startTimer = () => {
    setState((s) => ({ ...s, extracted: false }));
    _clearTimer();
    let counter = 10;
    onShowModalCounter && onShowModalCounter(counter);
    intervalId.current = window.setInterval(() => {
      // console.log("counter", counter);
      if (counter <= 0) {
        intervalId.current && window.clearInterval(intervalId.current);
        // setState((s) => ({ ...s, extracted: true }));
        onShowModalCounter && onShowModalCounter(null);
        setState((s) => ({ ...s, extracted: true }));
        setLoading(false);
        return;
      }
      onShowModalCounter && onShowModalCounter(counter - 1);
      counter--;
    }, 1000);
  };

  const sendImages = async () => {
    setLoading(true);
    const files = [];
    if (
      state.documentType === DOCUMENT_IDENTITY_CARD &&
      state.imageRecto &&
      state.imageVerso
    ) {
      files.push(state.imageRecto);
      files.push(state.imageVerso);
    } else if (state.documentType === DOCUMENT_PASSPORT && state.imageRecto) {
      files.push(state.imageRecto);
    } else {
      onShowModal && onShowModal(getText("m_no_data_specified"));
      setLoading(false);
      return;
    }

    const guest_info: any = {};
    let new_id_images = [];
    const params = {
      reservation_application_id: reservationApplication.id,
      guest_id: guest.id,
    };
    const res = await requestManager.uploadMultipart(
      API_URLS.TOURIST_RESERVATIONS_APPLICATIONS_GUESTS_IMAGES_EXTRACT,
      files,
      params
    );
    // console.log(res);
    if (res.status === 200) {
      const imageDatas = res["data"];

      for (const imageData of imageDatas) {
        const extraction = imageData["extraction"];
        if (Object.keys(extraction).length > 0) {
          new_id_images.push(imageData["id_image"]);
        }
        for (const key of Object.keys(extraction)) {
          const val = extraction[key];
          if (val && !(key in guest_info)) {
            guest_info[key] = val;
          }
        }
      }
    } else if (res.status === 401) {
      onShowModal && onShowModal(getText("m_failed_authorize"));
      setLoading(false);
      return;
    }

    if (Object.keys(guest_info).length === 0 && onBadPhoto) {
      new_id_images = [];
      // console.log("bad photo because guest info empty")
      onBadPhoto();
    }
    setLoading(false);
    setGuest({
      ...guest,
      ...guest_info,
      id_images: new_id_images,
    });
  };
  const handleSendImages = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    // start timer
    _startTimer();
    try {
      await sendImages();
    } catch {
      setLoading(false);
      onShowModal && onShowModal(getText("m_image_extract_error"));
    }
    setState((s) => ({ ...s, extracted: true }));
    _clearTimer();

    inProgress.current = false;
  };
  const renderImagesUpload = () => {
    const readyToExtract =
      (state.documentType === DOCUMENT_IDENTITY_CARD &&
        state.imageRecto !== null &&
        state.imageVerso !== null) ||
      (state.documentType === DOCUMENT_PASSPORT && state.imageRecto !== null);
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="form__control" style={{ width: "100%" }}>
            <LightButton
              handleClick={() => {
                setState((s) => ({
                  ...s,
                  documentType: null,
                  extracted: false,
                }));
              }}
            >
              Back to Document Type select
            </LightButton>
          </div>
          <div className="form__control" style={{ paddingTop: 0 }}>
            <h4 className="step-header" style={{ paddingTop: 0 }}>
              {getText("s_take_photo_recto")}
            </h4>
            <MyFileInput
              label={""}
              btn_label={getText("btn_photo_recto")}
              setValue={(v) => setState((s) => ({ ...s, imageRecto: v }))}
            />
            {state.imageRecto && <p>{state.imageRecto.name}</p>}

            {state.documentType === DOCUMENT_IDENTITY_CARD &&
              state.imageRecto && (
                <div>
                  <h4 className="step-header" style={{ paddingTop: 20 }}>
                    {getText("s_take_photo_verso")}
                  </h4>
                  <div className="form__control" style={{ paddingTop: 0 }}>
                    <MyFileInput
                      label={""}
                      btn_label={getText("btn_photo_verso")}
                      setValue={(v) =>
                        setState((s) => ({ ...s, imageVerso: v }))
                      }
                    />
                    {state.imageVerso && <p>{state.imageVerso.name}</p>}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="example-img-container">
          <img
            src={"/images/mrz_example.png"}
            alt="example"
            className="example-img"
          />
          <h4>{getText("s_take_photo_note")}</h4>
        </div>
        {readyToExtract && (
          <div className="control_center" style={{ paddingTop: 0 }}>
            <PrimaryButton handleClick={handleSendImages}>
              {getText("btn_read")}
            </PrimaryButton>
          </div>
        )}
      </React.Fragment>
    );
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setGuest({ ...guest, ...newData });
    const newMsgs = validateFormOptional(newData, name2validators);
    console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  const handleSave = async () => {
    const msgs = validateFormOptional(guest, name2validators);
    const invalidKey = Object.keys(msgs).find((key) => msgs[key].length > 0);
    if (!invalidKey) {
      onSave();
    } else {
      if (onBadSave) {
        onBadSave();
      }
    }
  };
  const renderEdit = () => {
    return (
      <div className="control__wrap">
        <div className="form__control" style={{ width: "100%" }}>
          <LightButton
            handleClick={() => {
              setState((s) => ({ ...s, extracted: false }));
            }}
          >
            Back to Image select
          </LightButton>
        </div>
        <div className="form__control">
          <TextField
            type="text"
            label={getText("t_firstname")}
            placeholder={getText("ph_firstname")}
            value={guest.firstname}
            onChange={(v) => handleChange("firstname", v)}
            messages={formMessages.firstname}
            readonly={true}
          />
        </div>
        <div className="form__control">
          <TextField
            type="text"
            label={getText("t_lastname")}
            placeholder={getText("ph_lastname")}
            value={guest.lastname}
            onChange={(v) => handleChange("lastname", v)}
            messages={formMessages.lastname}
            readonly={true}
          />
        </div>

        <div className="form__control">
          <DateField
            label={getText("t_birth_date")}
            value={guest.birth_date}
            onChange={(v) => handleChange("birth_date", v)}
            messages={formMessages.birth_date}
          />
        </div>
        <div className="form__control">
          <AutocompleteField
            label={getText("t_citizenship")}
            placeholder={getText("ph_france")}
            values={COUNTRY_ENGLISH}
            value={guest.citizenship}
            setValue={(v) => setGuest({ ...guest, citizenship: v })}
            validators={name2validators["citizenship"]}
            messages={formMessages["citizenship"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, citizenship: v })
            }
          />
        </div>

        <div className="form__control">
          <AutocompleteField
            label={getText("t_residency")}
            placeholder={getText("ph_france")}
            values={COUNTRY_ENGLISH}
            value={guest.residency}
            setValue={(v) => setGuest({ ...guest, residency: v })}
            validators={name2validators["residency"]}
            messages={formMessages["residency"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, residency: v })
            }
          />
        </div>
        <div className="form__control">
          <AutocompleteField
            label={getText("t_place_of_birth")}
            placeholder={getText("ph_france")}
            values={COUNTRY_ENGLISH}
            value={guest.place_of_birth}
            setValue={(v) => setGuest({ ...guest, place_of_birth: v })}
            validators={name2validators["place_of_birth"]}
            messages={formMessages["place_of_birth"] || []}
            setMessages={(v) =>
              setFormMessages({ ...formMessages, place_of_birth: v })
            }
          />
        </div>
        <div className="control_center">
          <PrimaryButton handleClick={handleSave}>
            {getText("btn_save_guest")}
          </PrimaryButton>
        </div>
      </div>
    );
  };

  return (
    <div>
      {state.documentType === null && renderDocumentSelect()}
      {state.documentType !== null && !state.extracted && renderImagesUpload()}
      {state.extracted && !state.confirmed && renderEdit()}
    </div>
  );
};
