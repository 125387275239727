import { Booking } from '../types/booking';

export type SortField = 'id' | 'property' | 'tourists' | 'entryDate' | 'exitDate' | 'status' | 'taxPaid';
export type SortDirection = 'asc' | 'desc';

export interface SortConfig {
  field: SortField;
  direction: SortDirection;
}

export const sortBookings = (bookings: Booking[], { field, direction }: SortConfig): Booking[] => {
  return [...bookings].sort((a, b) => {
    let comparison = 0;

    switch (field) {
      case 'id':
        comparison = a.id.localeCompare(b.id);
        break;
      case 'property':
        comparison = a.propertyId.localeCompare(b.propertyId);
        break;
      case 'tourists':
        comparison = a.tourists[0]?.name.localeCompare(b.tourists[0]?.name || '') || 0;
        break;
      case 'entryDate':
        comparison = new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime();
        break;
      case 'exitDate':
        comparison = new Date(a.exitDate).getTime() - new Date(b.exitDate).getTime();
        break;
      case 'status':
        comparison = a.status.localeCompare(b.status);
        break;
      case 'taxPaid':
        comparison = Number(a.taxPaid) - Number(b.taxPaid);
        break;
    }

    return direction === 'asc' ? comparison : -comparison;
  });
};