import React from "react";

interface ITextFieldProps {
  label: string;
  icon?: any;
  placeholder?: string;
  value: string;
  onChange: (vakue: string) => void;
  messages?: string[];
  type?: string;
  disabled?: boolean;
  readonly?: boolean;
}
export const TextField: React.FC<ITextFieldProps> = ({
  label,
  icon,
  placeholder,
  value,
  onChange,
  messages,
  type = "text",
  disabled=false,
  readonly=false,
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 relative">
        {!!icon && (
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
            {icon}
          </span>
        )}
        <input
          type={type}
          placeholder={placeholder}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
          className={
            "block w-full border-gray-300 rounded-md focus:ring-[#E47E7B] focus:border-[#E47E7B]" +
            (messages && messages.length > 0 ? " border-red-500" : "") +
            (!!icon ? " pl-10" : "")
          }
          disabled={disabled}
          readOnly={readonly}
        />
      </div>
      {messages?.map((m, i) => (
        <p key={i} className="mt-1 text-sm text-red-500">
          {m}
        </p>
      ))}
    </div>
  );
};
