import React from 'react';

export function Logo() {
  return (
    <div className="flex items-center justify-center py-4">
      <img 
        src={"/images/roatandorra_logo2.png"}
        alt="Roatandorra - Obligations Made Simple" 
        className="h-12"
      />
    </div>
  );
}