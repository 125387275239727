import React from "react";
import { Modal } from "./Modal";
import { Booking, Tourist } from "../types/booking";
import { formatDate } from "../utils/dateUtils";
import { CreditCard, BookOpen } from "lucide-react";
import { AppContext } from "../../../../utils/AppContext";
import { API_URLS } from "../../../../utils/RequestManager";
import { MyLoading } from "../../../common/MyModal";

interface TouristDetailsModalProps {
  booking: Booking;
  isOpen: boolean;
  onClose: () => void;
}

interface IIdImageUri {
  uri: string;
  id: number;
}

export function TouristDetailsModal({
  booking,
  isOpen,
  onClose,
}: TouristDetailsModalProps) {
  // const [selectedPhoto, setSelectedPhoto] = React.useState<string | null>(null);
  const [selectedTourist, setSelectedTourist] = React.useState<Tourist | null>(
    null
  );
  const [dataUris, setDataUris] = React.useState<IIdImageUri[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { requestManager } = React.useContext(AppContext);

  React.useEffect(() => {
    if (!selectedTourist) {
      setDataUris(null);
      return;
    }
    requestDocuments(selectedTourist);
  }, [selectedTourist]);

  const requestDocuments = async (selectedTourist: Tourist) => {
    if (!selectedTourist.photoId) {
      return;
    }
    const idImageIds = selectedTourist.photoId.split(",").map((id) => +id);
    const resultUris: IIdImageUri[] = [];
    setLoading(true);
    for (const idImageId of idImageIds) {
      const resp = await requestManager.postBlob(
        API_URLS.MANAGER_V2_GET_GUEST_IMAGE,
        { id_image_id: idImageId }
      );
      if (resp.status === 200) {
        var encodedUri = window.URL.createObjectURL(resp.data);
        resultUris.push({ id: idImageId, uri: encodedUri });
      } else {
      }
    }
    setLoading(false);
    setDataUris(resultUris);
  };

  const renderImages = () => {
    return dataUris?.map((dataUri) => (
      <img key={dataUri.id} src={dataUri.uri} alt="document"></img>
    ));
  };

  const renderDocumentIcon = (tourist: Tourist) => {
    if (!tourist.photoId) return null;

    const isPassport = tourist.photoId.split(",").length === 1;
    const Icon = isPassport ? BookOpen : CreditCard;

    return (
      <button
        onClick={() => setSelectedTourist(tourist)}
        className="inline-flex items-center text-primary hover:text-primaryDark"
      >
        <Icon className="h-5 w-5 mr-1" />
        <span className="text-sm">View {isPassport ? "Passport" : "ID"}</span>
      </button>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="p-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Booking Details
          </h2>

          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <span className="text-gray-500">Entry Date:</span>
                <span className="ml-2 font-medium">
                  {formatDate(booking.entryDate)}
                </span>
              </div>
              <div>
                <span className="text-gray-500">Exit Date:</span>
                <span className="ml-2 font-medium">
                  {formatDate(booking.exitDate)}
                </span>
              </div>
            </div>

            <div className="border-t pt-4">
              <h3 className="text-lg font-semibold mb-4">Tourists</h3>
              <div className="space-y-4">
                {booking.tourists.map((tourist, index) => (
                  <div key={tourist.id} className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex justify-between items-start mb-2">
                      <h4 className="font-medium">Tourist {index + 1}</h4>
                      {renderDocumentIcon(tourist)}
                    </div>
                    <div className="grid grid-cols-2 gap-4 text-sm">
                      <div>
                        <span className="text-gray-500">Name:</span>
                        <span className="ml-2">{tourist.name}</span>
                      </div>
                      <div>
                        <span className="text-gray-500">First Name:</span>
                        <span className="ml-2">{tourist.firstName}</span>
                      </div>
                      <div>
                        <span className="text-gray-500">Date of Birth:</span>
                        <span className="ml-2">
                          {formatDate(tourist.dateOfBirth)}
                        </span>
                      </div>
                      <div>
                        <span className="text-gray-500">Age Status:</span>
                        <span
                          className={`ml-2 ${
                            tourist.isOver16 ? "text-green-600" : "text-red-600"
                          }`}
                        >
                          {tourist.isOver16 ? "✓ Over 16" : "✗ Under 16"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="border-t pt-4">
              <h3 className="text-lg font-semibold mb-2">Status Information</h3>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <span className="text-gray-500">Tax Status:</span>
                  <span
                    className={`ml-2 ${
                      booking.taxPaid ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {booking.taxPaid ? "✓ Paid" : "✗ Unpaid"}
                  </span>
                </div>
                {booking.taxPaidDate && (
                  <div>
                    <span className="text-gray-500">Tax Paid Date:</span>
                    <span className="ml-2">
                      {formatDate(booking.taxPaidDate)}
                    </span>
                  </div>
                )}
                {booking.governmentSubmissionDate && (
                  <div>
                    <span className="text-gray-500">
                      Submitted to Government:
                    </span>
                    <span className="ml-2">
                      {formatDate(booking.governmentSubmissionDate)}
                    </span>
                  </div>
                )}
                {booking.cancellationDate && (
                  <div>
                    <span className="text-gray-500">Cancelled On:</span>
                    <span className="ml-2">
                      {formatDate(booking.cancellationDate)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {selectedTourist && (
        <Modal isOpen={true} onClose={() => setSelectedTourist(null)}>
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">ID Document</h3>
            <div className="aspect-[4/3] bg-gray-100 rounded-lg flex items-center justify-center">
              {/* In a real app, this would display the actual document image */}
              <div className="text-gray-500 text-center">
                {dataUris ? (
                  renderImages()
                ) : (
                  <CreditCard className="h-12 w-12 mx-auto mb-2" />
                )}
                <p>Document ID: {selectedTourist.photoId}</p>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {loading && <MyLoading />}
    </>
  );
}
