import React from 'react';

interface Language {
  value: string;
  code: string;
  name: string;
  flag: string;
}

const languages: Language[] = [
  { value: "english", code: 'en', name: 'English', flag: '🇬🇧' },
  { value: "french", code: 'fr', name: 'Français', flag: '🇫🇷' },
  { value: "spanish", code: 'es', name: 'Español', flag: '🇪🇸' },
  { value: "russian", code: 'ru', name: 'Русский', flag: '🇷🇺' },
];

interface ILanguageSelectorProps {
  value: string
  onChange: (v: string) => void;
}

export const LanguageSelector: React.FC<ILanguageSelectorProps> = ({value,onChange}) =>  {
  const [isOpen, setIsOpen] = React.useState(false);
  // const [selectedLang, setSelectedLang] = React.useState(languages[0]);

  const selectedLang = languages.find(l => l.value === value) ?? languages[0];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 rounded-md hover:bg-gray-100 transition-colors"
      >
        <span className="text-lg">{selectedLang.flag}</span>
        <span className="text-sm text-gray-600">{selectedLang.code.toUpperCase()}</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => {
                // setSelectedLang(lang);
                onChange(lang.value);
                setIsOpen(false);
              }}
              className={`w-full flex items-center space-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ${
                selectedLang.code === lang.code ? 'bg-gray-50' : ''
              }`}
            >
              <span className="text-lg">{lang.flag}</span>
              <span>{lang.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}