import React, { useContext, useEffect, useState } from "react";
import { IFormInitData, InvoiceForm } from "./components/InvoiceForm";
// import { InvoiceCalculator } from "./components/InvoiceCalculator";
import { InvoicePreview } from "./components/InvoicePreview";
import { InvoiceData, CalculationResult } from "./types";
import { Receipt } from "lucide-react";
import { AppContext } from "../../utils/AppContext";
// import { useLocation, useNavigate } from "react-router-dom";
import { API_URLS } from "../../utils/RequestManager";
import { IReservationApplication } from "../common/types";

// import { WEB_ROUTERS } from "../../utils/routes";
// import { Checkout } from "../common/Stripe/Checkout";
import { InfoDialog } from "../Customer/TouristRegistration/components/InfoDialog";
import { MyLoading } from "../common/MyModal";
import { PaymentButton } from "../common/Stripe/PaymentButton";

const TAX_RATE = 2.11;

function calculateAge(date: Date) {
  // birthday is a date
  var ageDifMs = Date.now() - date.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

interface IReservationApplicationExt extends IReservationApplication {
  owner: string;
  email: string;
  address: string;
  guestEmail: string;
}
const raInfo2FormInitData = (raInfo: IReservationApplicationExt) => {
  let nights: number = 0;
  if (raInfo.checkin_date && raInfo.checkout_date) {
    const checkin_date_d = new Date(raInfo.checkin_date);
    const checkout_date_d = new Date(raInfo.checkout_date);
    nights = Math.floor(
      (checkout_date_d.getTime() - checkin_date_d.getTime()) /
        (1000 * 60 * 60 * 24)
    );
  }
  let ng16 = 0;
  let nle16 = 0;

  raInfo.guests?.forEach((guest: any) => {
    let age = 0;
    if (guest.birth_date) {
      const birth_date_d = new Date(guest.birth_date);
      age = calculateAge(birth_date_d);
    }

    if (age >= 16) {
      ng16++;
    } else {
      nle16++;
    }
  });

  let owner = "";
  if (raInfo.guests && raInfo.guests.length > 0) {
    const firstGuest = raInfo.guests[0];
    owner = `${firstGuest.firstname} ${firstGuest.lastname}`;
  }

  const res: IFormInitData = {
    // customerName: raInfo.owner,
    // email: raInfo.email,
    customerName: owner,
    email: raInfo.guestEmail,
    adults: ng16,
    nights: nights,
    checkIn: raInfo.checkin_date,
    checkOut: raInfo.checkout_date,
    address: raInfo.address,
  };
  return res;
};

export function InvoicesPage() {
  const [raInfo, setRaInfo] = useState<any | null>(null);
  // const [formInitData, setFormInitData] = useState<IFormInitData | null>(null);
  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
  const [calculation, setCalculation] = useState<CalculationResult | null>(
    null
  );
  // const [confirmed, setConfirmed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [infoText, setInfoText] = useState<string>("");
  const { requestManager, reservationApplication } = useContext(AppContext);

  useEffect(() => {
    const reservation_application_id = reservationApplication.id;
    getReservationApplocationData(reservation_application_id);
  }, []);

  useEffect(() => {
    setUpRAData(raInfo);
  }, [raInfo]);

  useEffect(() => {
    if (!invoiceData) return;
    const quantity = invoiceData.adults * Math.min(7, invoiceData.nights);
    const subtotal = quantity * TAX_RATE;
    const total = subtotal;
    setCalculation({ quantity, subtotal, total, taxRate: TAX_RATE });
  }, [invoiceData]);

  const getReservationApplocationData = async (
    reservation_application_id: number
  ) => {
    setLoading(true);
    const res_user = await requestManager.postJson(API_URLS.USER_GET);
    let guestEmail = "";
    if (res_user.status === 200) {
      guestEmail = res_user.data.email;
    }
    const resp = await requestManager.postJson(
      API_URLS.TOURIST_V2_RESERVATION_APPLICATION_INFO_GET,
      { reservation_application_id }
    );
    setLoading(false);
    if (resp.status === 200) {
      const info = resp.data;
      info.guestEmail = guestEmail;
      setRaInfo(info);
    } else {
    }
  };

  const setUpRAData = (raInfo: IReservationApplicationExt) => {
    if (!raInfo) return;
    const res = raInfo2FormInitData(raInfo);
    // setFormInitData(res);
    handleFormInitData(res);
  };

  const handleFormInitData = (data: IFormInitData) => {
    const { customerName, email, adults, nights, checkIn, checkOut, address } =
      data;

    const invoiceData: InvoiceData = {
      id: new Date().getTime().toString(),
      customerName,
      email,
      adults: parseInt(""+adults),
      nights:  parseInt(""+nights),
      checkIn,
      checkOut,
      address,
      vatNumber: "",
    };
    setInvoiceData(invoiceData);
  };

  // const handleInvoiceSubmit = (data: InvoiceData) => {
  //   setInvoiceData(data);
  //   setConfirmed(true);
  // };

  // const handleCalculation = (result: CalculationResult) => {
  //   setCalculation(result);
  // };

  // const handleProceedToPayment = async () => {
  //   // TODO: Integrate with Stripe payment link
  //   // alert("Stripe payment integration will be added here");
  //   navigate(WEB_ROUTERS.TOURIST_PAYMENT_CHECKOUT);
  // };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center gap-2">
            <Receipt className="w-8 h-8 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">
              IEAT Tourist Tax Invoice Generator
            </h1>
          </div>
        </div>
      </header> */}

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        {/* {!confirmed && (
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Enter Invoice Details
              </h2>
              <InvoiceForm
                onSubmit={handleInvoiceSubmit}
                formInitData={formInitData}
              />
            </div>
          </div>
        )} */}
        {/* {invoiceData && (
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Calculate Tax Amount
              </h2>
              <InvoiceCalculator
                adults={invoiceData.adults}
                nights={invoiceData.nights}
                onCalculate={handleCalculation}
              />
            </div>
          </div>
        )} */}
        {invoiceData && calculation && (
          <React.Fragment>
            <InvoicePreview
              invoiceData={invoiceData}
              calculation={calculation}
              onProceedToPayment={null}
            />
            <div className="form__control control_center">
              <div style={{ width: "200px", alignSelf: "center" }}>
                <PaymentButton
                  url={API_URLS.TOURIST_V2_TAX_INIT}
                  payload={{
                    reservation_application_id: raInfo.id,
                    quantity: calculation.quantity,
                  }}
                  loading={loading}
                  setLoading={(v) => setLoading(v)}
                  setInfoText={(v) => setInfoText(v)}
                  auth={true}
                />
              </div>
            </div>
            {/* <Checkout
              reservation_application_id={raInfo.id}
              quantity={calculation.quantity}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            /> */}
          </React.Fragment>
        )}
      </main>

      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText("")}
        onConfirm={() => setInfoText("")}
      />
      {loading && <MyLoading />}
    </div>
  );
}
