import { Booking } from '../types/booking';

export const filterBookingsByTouristName = (bookings: Booking[], search: string): Booking[] => {
  if (!search.trim()) return bookings;
  
  const searchLower = search.toLowerCase();
  return bookings.filter(booking =>
    booking.tourists.some(tourist =>
      `${tourist.firstName} ${tourist.name}`.toLowerCase().includes(searchLower)
    )
  );
};