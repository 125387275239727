
interface IISOCodeInfo {
  alpha2: string;
  catalan: string;
  english: string;
}

export const iso_codes: IISOCodeInfo[] = [
  { alpha2: "alpha-2", catalan: "catalan", english: "english" },
  { alpha2: "AF", catalan: "Afganistan", english: "Afghanistan" },
  { alpha2: "AX", catalan: "\u00c5land, illes", english: "\u00c5land Islands" },
  { alpha2: "AL", catalan: "Alb\u00e0nia", english: "Albania" },
  { alpha2: "DE", catalan: "Alemanya", english: "Germany" },
  { alpha2: "DZ", catalan: "Alg\u00e8ria", english: "Algeria" },
  { alpha2: "AD", catalan: "Andorra", english: "Andorra" },
  { alpha2: "AO", catalan: "Angola", english: "Angola" },
  { alpha2: "AI", catalan: "Anguilla", english: "Anguilla" },
  { alpha2: "AQ", catalan: "Ant\u00e0rtida", english: "Antarctica" },
  {
    alpha2: "AG",
    catalan: "Antigua i Barbuda",
    english: "Antigua and Barbuda",
  },
  { alpha2: "SA", catalan: "Ar\u00e0bia Saudita", english: "Saudi Arabia" },
  { alpha2: "AR", catalan: "Argentina", english: "Argentina" },
  { alpha2: "AM", catalan: "Arm\u00e8nia", english: "Armenia" },
  { alpha2: "AW", catalan: "Aruba", english: "Aruba" },
  { alpha2: "AU", catalan: "Austr\u00e0lia", english: "Australia" },
  { alpha2: "AT", catalan: "\u00c0ustria", english: "Austria" },
  { alpha2: "AZ", catalan: "Azerbaidjan", english: "Azerbaijan" },
  { alpha2: "BS", catalan: "Bahames", english: "Bahamas" },
  { alpha2: "BH", catalan: "Bahrain", english: "Bahrain" },
  { alpha2: "BD", catalan: "Bangla Desh", english: "Bangladesh" },
  { alpha2: "BB", catalan: "Barbados", english: "Barbados" },
  { alpha2: "BE", catalan: "B\u00e8lgica", english: "Belgium" },
  { alpha2: "BZ", catalan: "Belize", english: "Belize" },
  { alpha2: "BJ", catalan: "Ben\u00edn", english: "Benin" },
  { alpha2: "BM", catalan: "Bermudes", english: "Bermuda" },
  { alpha2: "BT", catalan: "Bhutan", english: "Bhutan" },
  { alpha2: "BY", catalan: "Bielor\u00fassia", english: "Belarus" },
  {
    alpha2: "BO",
    catalan: "Bol\u00edvia",
    english: "Bolivia (Plurinational State of)",
  },
  {
    alpha2: "BQ",
    catalan: "Bonaire, Sint Eustatius i Saba; Carib Neerland\u00e8s; Illes BES",
    english: "Bonaire, Sint Eustatius and Saba",
  },
  {
    alpha2: "BA",
    catalan: "B\u00f2snia i Hercegovina",
    english: "Bosnia and Herzegovina",
  },
  { alpha2: "BW", catalan: "Botswana", english: "Botswana" },
  { alpha2: "BV", catalan: "Bouvet", english: "Bouvet Island" },
  { alpha2: "BR", catalan: "Brasil", english: "Brazil" },
  { alpha2: "BN", catalan: "Brunei", english: "Brunei Darussalam" },
  { alpha2: "BG", catalan: "Bulg\u00e0ria", english: "Bulgaria" },
  { alpha2: "BF", catalan: "Burkina Faso", english: "Burkina Faso" },
  { alpha2: "BI", catalan: "Burundi", english: "Burundi" },
  { alpha2: "KY", catalan: "Caiman, illes", english: "Cayman Islands" },
  { alpha2: "KH", catalan: "Cambodja", english: "Cambodia" },
  { alpha2: "CM", catalan: "Camerun", english: "Cameroon" },
  { alpha2: "CA", catalan: "Canad\u00e0", english: "Canada" },
  { alpha2: "CV", catalan: "Cap Verd", english: "Cabo Verde" },
  {
    alpha2: "CF",
    catalan: "Centreafricana, Rep\u00fablica",
    english: "Central African Republic",
  },
  { alpha2: "CX", catalan: "Christmas, illa", english: "Christmas Island" },
  { alpha2: "CC", catalan: "Cocos, illes", english: "Cocos (Keeling) Islands" },
  { alpha2: "CO", catalan: "Col\u00f2mbia", english: "Colombia" },
  { alpha2: "KM", catalan: "Comores", english: "Comoros" },
  { alpha2: "CG", catalan: "Congo, Rep\u00fablica del", english: "Congo" },
  {
    alpha2: "CD",
    catalan: "Congo, Rep\u00fablica Democr\u00e0tica del",
    english: "Congo, Democratic Republic of the",
  },
  { alpha2: "CK", catalan: "Cook, illes", english: "Cook Islands" },
  {
    alpha2: "KP",
    catalan: "Corea del Nord",
    english: "Korea (Democratic People's Republic of)",
  },
  { alpha2: "KR", catalan: "Corea del Sud", english: "Korea, Republic of" },
  { alpha2: "CI", catalan: "Costa d'Ivori", english: "C\u00f4te d'Ivoire" },
  { alpha2: "CR", catalan: "Costa Rica", english: "Costa Rica" },
  { alpha2: "HR", catalan: "Cro\u00e0cia", english: "Croatia" },
  { alpha2: "CU", catalan: "Cuba", english: "Cuba" },
  { alpha2: "CW", catalan: "Cura\u00e7ao", english: "Cura\u00e7ao" },
  { alpha2: "DK", catalan: "Dinamarca", english: "Denmark" },
  { alpha2: "DJ", catalan: "Djibouti", english: "Djibouti" },
  { alpha2: "DM", catalan: "Dominica", english: "Dominica" },
  {
    alpha2: "DO",
    catalan: "Dominicana, Rep\u00fablica",
    english: "Dominican Republic",
  },
  { alpha2: "EG", catalan: "Egipte", english: "Egypt" },
  { alpha2: "EC", catalan: "Equador", english: "Ecuador" },
  {
    alpha2: "AE",
    catalan: "Emirats \u00c0rabs Units",
    english: "United Arab Emirates",
  },
  { alpha2: "ER", catalan: "Eritrea", english: "Eritrea" },
  { alpha2: "SK", catalan: "Eslov\u00e0quia", english: "Slovakia" },
  { alpha2: "SI", catalan: "Eslov\u00e8nia", english: "Slovenia" },
  { alpha2: "ES", catalan: "Espanya", english: "Spain" },
  {
    alpha2: "US",
    catalan: "Estats Units (EUA)",
    english: "United States of America",
  },
  { alpha2: "EE", catalan: "Est\u00f2nia", english: "Estonia" },
  { alpha2: "ET", catalan: "Eti\u00f2pia", english: "Ethiopia" },
  { alpha2: "FO", catalan: "F\u00e8roe, illes", english: "Faroe Islands" },
  { alpha2: "FJ", catalan: "Fiji", english: "Fiji" },
  { alpha2: "PH", catalan: "Filipines", english: "Philippines" },
  { alpha2: "FI", catalan: "Finl\u00e0ndia", english: "Finland" },
  { alpha2: "FR", catalan: "Fran\u00e7a", english: "France" },
  { alpha2: "GA", catalan: "Gabon", english: "Gabon" },
  { alpha2: "GM", catalan: "G\u00e0mbia", english: "Gambia" },
  { alpha2: "GE", catalan: "Ge\u00f2rgia", english: "Georgia" },
  {
    alpha2: "GS",
    catalan: "Ge\u00f2rgia del Sud i Sandwich del Sud, illes",
    english: "South Georgia and the South Sandwich Islands",
  },
  { alpha2: "GH", catalan: "Ghana", english: "Ghana" },
  { alpha2: "GI", catalan: "Gibraltar", english: "Gibraltar" },
  { alpha2: "GR", catalan: "Gr\u00e8cia", english: "Greece" },
  { alpha2: "GD", catalan: "Grenada", english: "Grenada" },
  { alpha2: "GL", catalan: "Grenl\u00e0ndia", english: "Greenland" },
  { alpha2: "GP", catalan: "Guadeloupe", english: "Guadeloupe" },
  { alpha2: "GF", catalan: "Guaiana Francesa", english: "French Guiana" },
  { alpha2: "GU", catalan: "Guam", english: "Guam" },
  { alpha2: "GT", catalan: "Guatemala", english: "Guatemala" },
  { alpha2: "GG", catalan: "Guernsey", english: "Guernsey" },
  { alpha2: "GN", catalan: "Rep\u00fablica de Guinea", english: "Guinea" },
  { alpha2: "GW", catalan: "Guinea Bissau", english: "Guinea-Bissau" },
  { alpha2: "GQ", catalan: "Guinea Equatorial", english: "Equatorial Guinea" },
  { alpha2: "GY", catalan: "Guyana", english: "Guyana" },
  { alpha2: "HT", catalan: "Hait\u00ed", english: "Haiti" },
  {
    alpha2: "HM",
    catalan: "Heard, illa i McDonald, illes",
    english: "Heard Island and McDonald Islands",
  },
  { alpha2: "HN", catalan: "Hondures", english: "Honduras" },
  { alpha2: "HK", catalan: "Hong Kong", english: "Hong Kong" },
  { alpha2: "HU", catalan: "Hongria", english: "Hungary" },
  { alpha2: "YE", catalan: "Iemen", english: "Yemen" },
  { alpha2: "IM", catalan: "Illa de Man", english: "Isle of Man" },
  {
    alpha2: "UM",
    catalan: "Illes Perif\u00e8riques Menors dels EUA",
    english: "United States Minor Outlying Islands",
  },
  { alpha2: "IN", catalan: "\u00cdndia", english: "India" },
  { alpha2: "ID", catalan: "Indon\u00e8sia", english: "Indonesia" },
  { alpha2: "IR", catalan: "Iran", english: "Iran (Islamic Republic of)" },
  { alpha2: "IQ", catalan: "Iraq", english: "Iraq" },
  { alpha2: "IE", catalan: "Irlanda", english: "Ireland" },
  { alpha2: "IS", catalan: "Isl\u00e0ndia", english: "Iceland" },
  { alpha2: "IL", catalan: "Israel", english: "Israel" },
  { alpha2: "IT", catalan: "It\u00e0lia", english: "Italy" },
  { alpha2: "JM", catalan: "Jamaica", english: "Jamaica" },
  { alpha2: "JP", catalan: "Jap\u00f3", english: "Japan" },
  { alpha2: "JE", catalan: "Jersey", english: "Jersey" },
  { alpha2: "JO", catalan: "Jord\u00e0nia", english: "Jordan" },
  { alpha2: "KZ", catalan: "Kazakhstan", english: "Kazakhstan" },
  { alpha2: "KE", catalan: "Kenya", english: "Kenya" },
  { alpha2: "KG", catalan: "Kirguizistan", english: "Kyrgyzstan" },
  { alpha2: "KI", catalan: "Kiribati", english: "Kiribati" },
  { alpha2: "KW", catalan: "Kuwait", english: "Kuwait" },
  {
    alpha2: "LA",
    catalan: "Laos",
    english: "Lao People's Democratic Republic",
  },
  { alpha2: "LS", catalan: "Lesotho", english: "Lesotho" },
  { alpha2: "LV", catalan: "Let\u00f2nia", english: "Latvia" },
  { alpha2: "LB", catalan: "L\u00edban", english: "Lebanon" },
  { alpha2: "LR", catalan: "Lib\u00e8ria", english: "Liberia" },
  { alpha2: "LY", catalan: "L\u00edbia", english: "Libya" },
  { alpha2: "LI", catalan: "Liechtenstein", english: "Liechtenstein" },
  { alpha2: "LT", catalan: "Litu\u00e0nia", english: "Lithuania" },
  { alpha2: "LU", catalan: "Luxemburg", english: "Luxembourg" },
  { alpha2: "MO", catalan: "Macau", english: "Macao" },
  { alpha2: "MK", catalan: "Maced\u00f2nia", english: "North Macedonia" },
  { alpha2: "MG", catalan: "Madagascar", english: "Madagascar" },
  { alpha2: "MY", catalan: "Mal\u00e0isia", english: "Malaysia" },
  { alpha2: "MW", catalan: "Malawi", english: "Malawi" },
  { alpha2: "MV", catalan: "Maldives", english: "Maldives" },
  { alpha2: "ML", catalan: "Mali", english: "Mali" },
  { alpha2: "MT", catalan: "Malta", english: "Malta" },
  {
    alpha2: "FK",
    catalan: "Malvines, illes",
    english: "Falkland Islands (Malvinas)",
  },
  {
    alpha2: "MP",
    catalan: "Mariannes Septentrionals, illes",
    english: "Northern Mariana Islands",
  },
  { alpha2: "MA", catalan: "Marroc", english: "Morocco" },
  { alpha2: "MH", catalan: "Marshall, illes", english: "Marshall Islands" },
  { alpha2: "MQ", catalan: "Martinica", english: "Martinique" },
  { alpha2: "MU", catalan: "Maurici", english: "Mauritius" },
  { alpha2: "MR", catalan: "Maurit\u00e0nia", english: "Mauritania" },
  { alpha2: "YT", catalan: "Mayotte", english: "Mayotte" },
  { alpha2: "MX", catalan: "M\u00e8xic", english: "Mexico" },
  {
    alpha2: "FM",
    catalan: "Micron\u00e8sia, Estats Federats de",
    english: "Micronesia (Federated States of)",
  },
  { alpha2: "MZ", catalan: "Mo\u00e7ambic", english: "Mozambique" },
  { alpha2: "MD", catalan: "Mold\u00e0via", english: "Moldova, Republic of" },
  { alpha2: "MC", catalan: "M\u00f2naco", english: "Monaco" },
  { alpha2: "MN", catalan: "Mong\u00f2lia", english: "Mongolia" },
  { alpha2: "ME", catalan: "Montenegro", english: "Montenegro" },
  { alpha2: "MS", catalan: "Montserrat", english: "Montserrat" },
  { alpha2: "MM", catalan: "Myanmar", english: "Myanmar" },
  { alpha2: "NA", catalan: "Nam\u00edbia", english: "Namibia" },
  { alpha2: "NR", catalan: "Nauru", english: "Nauru" },
  { alpha2: "NP", catalan: "Nepal", english: "Nepal" },
  { alpha2: "NI", catalan: "Nicaragua", english: "Nicaragua" },
  { alpha2: "NE", catalan: "N\u00edger", english: "Niger" },
  { alpha2: "NG", catalan: "Nig\u00e8ria", english: "Nigeria" },
  { alpha2: "NU", catalan: "Niue", english: "Niue" },
  { alpha2: "NF", catalan: "Norfolk, illa", english: "Norfolk Island" },
  { alpha2: "NO", catalan: "Noruega", english: "Norway" },
  { alpha2: "NC", catalan: "Nova Caled\u00f2nia", english: "New Caledonia" },
  { alpha2: "NZ", catalan: "Nova Zelanda", english: "New Zealand" },
  { alpha2: "OM", catalan: "Oman", english: "Oman" },
  { alpha2: "NL", catalan: "Pa\u00efsos Baixos", english: "Netherlands" },
  { alpha2: "PK", catalan: "Pakistan", english: "Pakistan" },
  { alpha2: "PW", catalan: "Palau", english: "Palau" },
  { alpha2: "PS", catalan: "Palestina", english: "Palestine, State of" },
  { alpha2: "PA", catalan: "Panam\u00e0", english: "Panama" },
  { alpha2: "PG", catalan: "Papua Nova Guinea", english: "Papua New Guinea" },
  { alpha2: "PY", catalan: "Paraguai", english: "Paraguay" },
  { alpha2: "PE", catalan: "Per\u00fa", english: "Peru" },
  { alpha2: "PN", catalan: "Pitcairn, illes", english: "Pitcairn" },
  {
    alpha2: "PF",
    catalan: "Polin\u00e8sia Francesa",
    english: "French Polynesia",
  },
  { alpha2: "PL", catalan: "Pol\u00f2nia", english: "Poland" },
  { alpha2: "PT", catalan: "Portugal", english: "Portugal" },
  { alpha2: "PR", catalan: "Puerto Rico", english: "Puerto Rico" },
  { alpha2: "QA", catalan: "Qatar", english: "Qatar" },
  {
    alpha2: "GB",
    catalan: "Regne Unit",
    english: "United Kingdom of Great Britain and Northern Ireland",
  },
  { alpha2: "RE", catalan: "Reuni\u00f3, illa de la", english: "R\u00e9union" },
  { alpha2: "RO", catalan: "Romania", english: "Romania" },
  { alpha2: "RU", catalan: "R\u00fassia", english: "Russian Federation" },
  { alpha2: "RW", catalan: "Rwanda", english: "Rwanda" },
  {
    alpha2: "EH",
    catalan: "S\u00e0hara Occidental",
    english: "Western Sahara",
  },
  {
    alpha2: "KN",
    catalan: "Saint Kitts i Nevis",
    english: "Saint Kitts and Nevis",
  },
  { alpha2: "LC", catalan: "Saint Lucia", english: "Saint Lucia" },
  {
    alpha2: "PM",
    catalan: "Saint-Pierre i Miquelon",
    english: "Saint Pierre and Miquelon",
  },
  {
    alpha2: "VC",
    catalan: "Saint Vincent i les Grenadines",
    english: "Saint Vincent and the Grenadines",
  },
  {
    alpha2: "BL",
    catalan: "Saint-Barth\u00e9lemy; Saint-Barth\u00e9lemy (Antilles)",
    english: "Saint Barth\u00e9lemy",
  },
  {
    alpha2: "MF",
    catalan: "Saint-Martin; Saint-Martin (Antilles Franceses)",
    english: "Saint Martin (French part)",
  },
  { alpha2: "SB", catalan: "Salom\u00f3", english: "Solomon Islands" },
  { alpha2: "SV", catalan: "Salvador, El", english: "El Salvador" },
  { alpha2: "WS", catalan: "Samoa", english: "Samoa" },
  { alpha2: "AS", catalan: "Samoa Nord-americana", english: "American Samoa" },
  { alpha2: "SM", catalan: "San Marino", english: "San Marino" },
  {
    alpha2: "SH",
    catalan: "Santa Helena",
    english: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    alpha2: "ST",
    catalan: "S\u00e3o Tom\u00e9 i Pr\u00edncipe",
    english: "Sao Tome and Principe",
  },
  { alpha2: "SN", catalan: "Senegal", english: "Senegal" },
  { alpha2: "RS", catalan: "S\u00e8rbia", english: "Serbia" },
  { alpha2: "SC", catalan: "Seychelles", english: "Seychelles" },
  { alpha2: "SL", catalan: "Sierra Leone", english: "Sierra Leone" },
  { alpha2: "SG", catalan: "Singapur", english: "Singapore" },
  {
    alpha2: "SX",
    catalan: "Sint Maarten",
    english: "Sint Maarten (Dutch part)",
  },
  { alpha2: "SY", catalan: "S\u00edria", english: "Syrian Arab Republic" },
  { alpha2: "SO", catalan: "Som\u00e0lia", english: "Somalia" },
  { alpha2: "LK", catalan: "Sri Lanka", english: "Sri Lanka" },
  { alpha2: "ZA", catalan: "Sud-\u00e0frica", english: "South Africa" },
  { alpha2: "SD", catalan: "Sudan", english: "Sudan" },
  {
    alpha2: "SS",
    catalan: "Sudan del Sud; Rep\u00fablica del Sudan del Sud",
    english: "South Sudan",
  },
  { alpha2: "SE", catalan: "Su\u00e8cia", english: "Sweden" },
  { alpha2: "CH", catalan: "Su\u00efssa", english: "Switzerland" },
  { alpha2: "SR", catalan: "Surinam", english: "Suriname" },
  {
    alpha2: "SJ",
    catalan: "Svalbard i Jan Mayen",
    english: "Svalbard and Jan Mayen",
  },
  { alpha2: "SZ", catalan: "Swazil\u00e0ndia", english: "Eswatini" },
  { alpha2: "TJ", catalan: "Tadjikistan", english: "Tajikistan" },
  { alpha2: "TH", catalan: "Tail\u00e0ndia", english: "Thailand" },
  { alpha2: "TW", catalan: "Taiwan", english: "Taiwan, Province of China" },
  {
    alpha2: "TZ",
    catalan: "Tanz\u00e0nia",
    english: "Tanzania, United Republic of",
  },
  {
    alpha2: "IO",
    catalan: "Territori Brit\u00e0nic de l'Oce\u00e0 \u00cdndic",
    english: "British Indian Ocean Territory",
  },
  {
    alpha2: "TF",
    catalan: "Territoris Francesos del Sud",
    english: "French Southern Territories",
  },
  { alpha2: "TL", catalan: "Timor Oriental", english: "Timor-Leste" },
  { alpha2: "TG", catalan: "Togo", english: "Togo" },
  { alpha2: "TK", catalan: "Tokelau", english: "Tokelau" },
  { alpha2: "TO", catalan: "Tonga", english: "Tonga" },
  {
    alpha2: "TT",
    catalan: "Trinitat i Tobago",
    english: "Trinidad and Tobago",
  },
  { alpha2: "TN", catalan: "Tun\u00edsia", english: "Tunisia" },
  { alpha2: "TM", catalan: "Turkmenistan", english: "Turkmenistan" },
  {
    alpha2: "TC",
    catalan: "Turks i Caicos, illes",
    english: "Turks and Caicos Islands",
  },
  { alpha2: "TR", catalan: "Turquia", english: "Turkey" },
  { alpha2: "TV", catalan: "Tuvalu", english: "Tuvalu" },
  { alpha2: "TD", catalan: "Txad", english: "Chad" },
  { alpha2: "CZ", catalan: "Txeca, Rep\u00fablica", english: "Czechia" },
  { alpha2: "UA", catalan: "Ucra\u00efna", english: "Ukraine" },
  { alpha2: "UG", catalan: "Uganda", english: "Uganda" },
  { alpha2: "UY", catalan: "Uruguai", english: "Uruguay" },
  { alpha2: "UZ", catalan: "Uzbekistan", english: "Uzbekistan" },
  { alpha2: "VU", catalan: "Vanuatu", english: "Vanuatu" },
  { alpha2: "VA", catalan: "Vatic\u00e0, Ciutat del", english: "Holy See" },
  {
    alpha2: "VE",
    catalan: "Vene\u00e7uela",
    english: "Venezuela (Bolivarian Republic of)",
  },
  {
    alpha2: "VG",
    catalan: "Verges Brit\u00e0niques, illes",
    english: "Virgin Islands (British)",
  },
  {
    alpha2: "VI",
    catalan: "Verges Nord-americanes, illes",
    english: "Virgin Islands (U.S.)",
  },
  { alpha2: "VN", catalan: "Vietnam", english: "Viet Nam" },
  { alpha2: "WF", catalan: "Wallis i Futuna", english: "Wallis and Futuna" },
  { alpha2: "CL", catalan: "Xile", english: "Chile" },
  { alpha2: "CN", catalan: "Xina", english: "China" },
  { alpha2: "CY", catalan: "Xipre", english: "Cyprus" },
  { alpha2: "ZM", catalan: "Z\u00e0mbia", english: "Zambia" },
  { alpha2: "ZW", catalan: "Zimbabwe", english: "Zimbabwe" },
  {
    alpha2: "AN",
    catalan: "Antilles Neerlandeses",
    english: "Netherlands Antilles",
  },
];

export const ENGLISH_TO_ISO_INFO: {[key: string]: IISOCodeInfo} = {};
iso_codes.forEach((info: IISOCodeInfo) => {
  ENGLISH_TO_ISO_INFO[info.english] = info;
})