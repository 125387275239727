// import { CheckoutProvider } from "@stripe/react-stripe-js";
import React, { useContext } from "react";
import { AppContext } from "../../../utils/AppContext";
import { API_URLS } from "../../../utils/RequestManager";
// import { loadStripe } from "@stripe/stripe-js";
// import { CheckoutForm } from "./CheckoutForm";
import { InfoDialog } from "../../Customer/TouristRegistration/components/InfoDialog";
import { MyLoading } from "../MyModal";

// const stripePromise =  loadStripe(
//   "pk_live_51PE5iUHkofemSGKT4X3yw6vMOwlDGCzqwLf974jlMlEyXVTehSZThxGPjhxQlqF0e83rE85JMTAikqtPwM4AyUbU00YBr1QQGd",
//   { betas: ["custom_checkout_beta_5"] }
// );

export const PaymentTest: React.FC = () => {
  // const [session, setSession] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [infoText, setInfoText] = React.useState<string>("");
  React.useEffect(() => {
    initPayment();
  }, []);
  const { requestManager } = useContext(AppContext);
  const initPayment = async () => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.TOURIST_V2_PAYMENT_TEST,
      {},
      false
    );
    setLoading(false);
    if (resp.status === 200) {
      const session = resp.data;
      // setSession(session);
      console.log(session);
      window.location.href = session.url;
      return true;
    } else {
      setInfoText("Failed initialize payment");
    }
    return false;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">
          Payment Test
        </h2>
        {/* {session && (
          <CheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: session.client_secret }}
          >
            <CheckoutForm loading={loading} setLoading={setLoading} />
          </CheckoutProvider>
        )} */}
      </main>
      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText("")}
        onConfirm={() => setInfoText("")}
      />
      {loading && <MyLoading />}
    </div>
  );
};
