import React, { useEffect } from 'react';
import { AlertCircle } from 'lucide-react';

interface UndoToastProps {
  message: string;
  onUndo: () => void;
  onClose: () => void;
  duration?: number;
}

export function UndoToast({ message, onUndo, onClose, duration = 5000 }: UndoToastProps) {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="fixed bottom-4 right-4 flex items-center bg-gray-800 text-white px-4 py-3 rounded-lg shadow-lg">
      <AlertCircle className="h-5 w-5 mr-2" />
      <span className="mr-4">{message}</span>
      <button
        onClick={onUndo}
        className="text-[#E47E7B] hover:text-[#F8DFDE] font-medium transition-colors"
      >
        Undo
      </button>
    </div>
  );
}