import React, { useContext, useState } from "react";
import { Check, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { WEB_ROUTERS } from "../../utils/routes";
import { AppContext } from "../../utils/AppContext";
import { PaymentButton } from "../common/Stripe/PaymentButton";
import { API_URLS } from "../../utils/RequestManager";
import { InfoDialog } from "../Customer/TouristRegistration/components/InfoDialog";
import { MyLoading } from "../common/MyModal";

interface IPlan {
  name: string;
  price: string;
  note?: string;
  stripeLink: string;
  features: string[];
}

const PLANS: IPlan[] = [
  {
    name: "BASIC",
    price: "7,99€",
    note: "Per property",
    stripeLink: "https://buy.stripe.com/basic-plan-link",
    features: [
      "Travellers self-checkin",
      "Automated identity document reading",
      "Automated information transmission to Government Roat Server",
      "Traveller check-in confirmation email",
    ],
  },
  {
    name: "PRO",
    price: "14,99€",
    stripeLink: "https://buy.stripe.com/pro-plan-link",
    features: [
      "All BASIC features",
      "12 months travellers information storage",
      "IEAT automated invoicing",
      "Security Deposit invoice and Refund",
      "Manage reservation",
      "Reservation database",
      "Export",
    ],
  },
  {
    name: "PREMIUM",
    price: "24,99€",
    stripeLink: "https://buy.stripe.com/premium-plan-link",
    features: [
      "All PRO features",
      "Connect to Channel Manager",
      "Fully automated traveller check-in request",
      "Activities Dashboards",
    ],
  },
];
const BRAND_COLOR = "#E47E7B";

export const Services: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [infoText, setInfoText] = useState<string>("");

  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const handleConfirmSubscription = () => {
    if (!user) {
      navigate(WEB_ROUTERS.CUSTOMER_PROPERTIES);
    }
  };

  const renderButton = () => {
    if (user) {
      return (
        <PaymentButton
          url={API_URLS.MANAGER_V2_SUBSCRIPTION_INIT}
          payload={{
            plan: selectedPlan?.name.toLowerCase(),
          }}
          loading={loading}
          setLoading={(v) => setLoading(v)}
          setInfoText={(v) => setInfoText(v)}
          auth={true}
          text="Proceed to Payment"
        />
      );
    }

    return (
      <button
        className="w-full py-3 px-6 rounded-md text-white font-medium text-center block transition-colors hover:opacity-90"
        style={{ backgroundColor: BRAND_COLOR, cursor: "pointer" }}
        onClick={handleConfirmSubscription}
      >
        Proceed to Payment
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      {/* Header */}
      <div
        className="pt-12 pb-24 text-center"
        style={{ backgroundColor: BRAND_COLOR }}
      >
        <img
          src={"/images/roatandorra_logo2.png"}
          alt="RoatAndorra Logo"
          className="h-12 mx-auto mb-6"
        />
        <h1 className="text-4xl font-bold text-white mb-4">Choose Your Plan</h1>
        <p className="text-white/90">
          Streamline your property management with our flexible solutions
        </p>
      </div>

      {/* Pricing Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-16">
        <div className="grid md:grid-cols-3 gap-8">
          {PLANS.map((plan) => (
            <div
              key={plan.name}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105"
            >
              <div className="p-6">
                <h2
                  className="text-2xl font-bold text-center mb-4"
                  style={{ color: BRAND_COLOR }}
                >
                  {plan.name}
                </h2>
                <p className="text-center text-4xl font-bold mb-2">
                  {plan.price}
                  <span className="text-gray-500 text-base font-normal">
                    /month
                  </span>
                </p>
                {plan.note && (
                  <p className="text-center text-sm text-gray-500 mb-6">
                    {plan.note}
                  </p>
                )}
                <div className="space-y-4">
                  {plan.features.map((feature) => (
                    <div key={feature} className="flex items-center">
                      <Check
                        className="h-5 w-5 mr-3 flex-shrink-0"
                        style={{ color: BRAND_COLOR }}
                      />
                      <span className="text-gray-600">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-6 bg-gray-50 border-t">
                {/* {renderButton(plan)} */}
                <button
                  onClick={() => setSelectedPlan(plan)}
                  className="w-full py-3 px-6 rounded-md text-white font-medium transition-colors hover:opacity-90"
                  style={{ backgroundColor: BRAND_COLOR }}
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Service Conditions */}
        <div className="mt-16 bg-white rounded-lg shadow-lg p-8">
          <h3 className="text-xl font-bold mb-4" style={{ color: BRAND_COLOR }}>
            Service Conditions
          </h3>
          <div className="space-y-2 text-gray-600 text-sm">
            <p>• Cancel anytime, no cancellation fee</p>
            <p>• No minimum duration</p>
            <p>• No minimum properties</p>
            <p>• RGPD compliant</p>
            <p className="mt-4 font-semibold">Users' rights include:</p>
            <ul className="list-disc pl-5 space-y-1">
              <li>Access to personal data concerning them</li>
              <li>The right to rectify inaccurate data</li>
              <li>
                The right to erasure of personal data, subject to legal
                exceptions
              </li>
              <li>Data portability to export data in a readable format</li>
              <li>The right to object and the right to limit data</li>
            </ul>
            <p className="mt-4">
              In the event of a claim, it will be dealt by Andorran courts
            </p>
          </div>
        </div>
      </div>

      {/* Subscription Modal */}
      {selectedPlan && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-bold" style={{ color: BRAND_COLOR }}>
                Confirm Subscription
              </h3>
              <button
                onClick={() => setSelectedPlan(null)}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-2">
                {selectedPlan.name} Plan
              </h4>
              <p className="text-gray-600 mb-4">
                {selectedPlan.price}/month
                {selectedPlan.note && (
                  <span className="text-sm"> ({selectedPlan.note})</span>
                )}
              </p>

              <div className="space-y-2">
                {selectedPlan.features.map((feature) => (
                  <div key={feature} className="flex items-center">
                    <Check
                      className="h-4 w-4 mr-2"
                      style={{ color: BRAND_COLOR }}
                    />
                    <span className="text-sm text-gray-600">{feature}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* <a
              href={selectedPlan.stripeLink}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full py-3 px-6 rounded-md text-white font-medium text-center block transition-colors hover:opacity-90"
              style={{ backgroundColor: BRAND_COLOR }}
            >
              Proceed to Payment
            </a> */}

            {renderButton()}
            {/* <button
              className="w-full py-3 px-6 rounded-md text-white font-medium text-center block transition-colors hover:opacity-90"
              style={{ backgroundColor: BRAND_COLOR, cursor: "pointer" }}
              onClick={handleConfirmSubscription}
            >
              Proceed to Payment
            </button> */}
          </div>
        </div>
      )}

      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText("")}
        onConfirm={() => setInfoText("")}
      />
      {loading && <MyLoading />}
    </div>
  );
};
