import React, { useContext, useState } from "react";
import { AppContext } from "../../../utils/AppContext";
import { IGuest } from "../../common/types";
import { MyLoading, MyModal } from "../../common/MyModal";
import { SelectField } from "../../common/components/SelectField";
import { Users } from "lucide-react";
import { TouristForms } from "./TouristForms";
import { API_URLS } from "../../../utils/RequestManager";
import { InfoDialog } from "../../Customer/TouristRegistration/components/InfoDialog";
// import { ConfirmationDialog } from "../../Customer/TouristRegistration/components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
// import { PrimaryButton } from "../../common/components/PrimaryButton";
import { WEB_ROUTERS } from "../../../utils/routes";
import { LightButton } from "../../common/components/LightButton";

const prepareGuest = (guest: any) => {
  guest.firstname = guest.firstname.trim();
  guest.lastname = guest.lastname.trim();
  guest.birth_date = guest.birth_date.trim();
  guest.citizenship = guest.citizenship.trim();
  guest.residency = guest.residency.trim();
  guest.place_of_birth = guest.place_of_birth.trim();
  return guest;
};

export const TouristApplication: React.FC<{}> = () => {
  const [guestCount, setGuestCount] = useState<number>(1);
  // const [currentGuestIdx, setCurrentGuestIdx] = useState<number | null>(null);
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [guestsRef, setGuestsRef] = useState<IGuest[]>([]);
  // const [showCheckin, setShowCheckin] = useState<boolean>(false);

  const [infoText, setInfoText] = useState("");
  const [loading, setLoading] = useState(false);

  // const [confirmState, setConfirmState] = useState<{
  //   text: string;
  //   callback: any;
  // }>({
  //   text: "",
  //   callback: null,
  // });
  const [modalCounter, setModalCounter] = useState<string | number | null>(
    null
  );

  const { requestManager, reservationApplication, getText } =
    useContext(AppContext);

  const navigate = useNavigate();

  const removeAllGuests = async () => {
    const guests_ = Array.from(
      new Set([...reservationApplication.guests, ...guests])
    );
    const guestIds = guests_.map((g: any) => g.id);
    for (const guestId of guestIds) {
      await deleteGuestById(guestId);
    }
  };
  const handleGuestCountSubmit = async (e: any) => {
    e.preventDefault();
    await removeAllGuests();
    await addGuest();
  };

  const renderTouristCount = () => {
    const guestCountOptions = [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
      { value: 6, label: "6" },
    ];
    return (
      <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-center mb-6">
          <Users className="w-12 h-12 text-primary" />
        </div>
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-700">
          How many tourists?
        </h2>
        <form onSubmit={handleGuestCountSubmit} className="space-y-4">
          <div>
            <SelectField
              label=""
              options={guestCountOptions}
              value={guestCount}
              onChange={(v) => setGuestCount(+v)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primaryDark transition-colors"
          >
            Continue
          </button>
        </form>
      </div>
    );
  };

  const updateGuest = (idx: number, value: any) => {
    const newGuests = [
      ...guests.slice(0, idx),
      value,
      ...guests.slice(idx + 1),
    ];
    setGuests(newGuests);
  };
  const addGuest = async () => {
    if (guests.length >= 6) {
      setInfoText(getText("m_guest_limit"));
      return;
    }
    setLoading(true);
    const data = {
      reservation_application_id: reservationApplication.id,
      citizenship: "",
      residency: "",
      valid: true,
      firstname: "",
      lastname: "",
      place_of_birth: "",
      birth_date: "",
    };
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_GUESTS_SAVE,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      const newGuest = res.data;
      setGuests((gs) => [...gs, { ...newGuest }]);
      setGuestsRef((gs) => [...gs, { ...newGuest }]);
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const deleteGuestById = async (guestId: number) => {
    setLoading(true);
    const data = {
      reservation_application_id: reservationApplication.id,
      id: guestId,
      valid: false,
    };
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_GUESTS_SAVE,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      setGuests((gs) => gs.filter((g) => g.id !== guestId));
      setGuestsRef((gs) => gs.filter((g) => g.id !== guestId));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const handleSaveGuest = async (idx: number) => {
    // show modal
    // const func = async () => {
    const [success, guests_, guestsRef_] = await saveGuest(idx);
    if (success) {
      if (guests_ && guests_.length < guestCount) {
        await addGuest();
      } else {
        setLoading(true);
        const data = {
          reservation_application_id: reservationApplication.id,
        };
        const res = await requestManager.postJson(
          API_URLS.TOURIST_RESERVATIONS_APPLICATIONS_CHECKIN,
          data
        );
        setLoading(false);
        if (res.status === 200) {
          const data = {
            reservation_application_id: reservationApplication.id,
          };
          navigate(WEB_ROUTERS.TOURIST_PAYMENT, { state: data });
        } else {
          setInfoText("Failed to checkin");
        }
      }
    }
    // };
    // setConfirmState((s) => ({
    //   ...s,
    //   text: getText("m_photo_confirm"),
    //   callback: func,
    // }));
  };
  const saveGuest = async (
    idx: number
  ): Promise<[boolean, IGuest[] | null, IGuest[] | null]> => {
    setLoading(true);
    const guest = {
      ...guests[idx],
      reservation_application_id: reservationApplication.id,
    };
    prepareGuest(guest);
    const res = await requestManager.postJson(
      API_URLS.TOURIST_V2_GUESTS_SAVE,
      guest
    );
    let guests_ = null;
    let guestsRef_ = null;
    let succ = false;
    if (res.status === 200) {
      succ = true;
      const savedGuest = res.data;
      // console.log("savedGuest", savedGuest);
      guests_ = [
        ...guests.slice(0, idx),
        { ...savedGuest },
        ...guests.slice(idx + 1),
      ];
      guestsRef_ = [
        ...guestsRef.slice(0, idx),
        { ...savedGuest },
        ...guestsRef.slice(idx + 1),
      ];
      setGuests(guests_);
      setGuestsRef(guestsRef_);
    } else if (res.status === 400) {
      setInfoText(getText("m_some_fields_or_images_invalid"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
    setLoading(false);
    return [succ, guests_, guestsRef_];
  };

  const renderTouristEdit = () => {
    const idx = guests.length - 1;
    return (
      <div className="space-y-6">
        <LightButton
          handleClick={() => {
            removeAllGuests();
          }}
        >
          Back to Tourist count select
        </LightButton>
        <h2 className="text-2xl font-bold text-center text-gray-700">
          Tourist {idx + 1} of {guestCount}
        </h2>
        {guests.map((g_, i) =>
          i !== idx ? null : (
            <TouristForms
              key={g_.id}
              reservationApplication={reservationApplication}
              guest={guests[idx!]}
              setGuest={(g: any) => updateGuest(idx, g)}
              onSave={() => handleSaveGuest(idx)}
              setLoading={(v: any) => setLoading(v)}
              setInfoText={(v: string) => setInfoText(v)}
              onBadPhoto={() => setInfoText(getText("m_image_extract_error"))}
              onBadSave={() => setInfoText(getText("m_some_fields_invalid"))}
              onShowModal={(text: any) => setInfoText(text)}
              onShowModalCounter={(c) => setModalCounter(c)}
            />
          )
        )}
      </div>
    );
  };

  // const renderCheckin = () => {
  //   return (
  //     <div className="text-center space-y-4">
  //       <CheckCircle className="w-16 h-16 text-primary mx-auto" />
  //       <h2 className="text-2xl font-bold text-gray-700">
  //         Check-in almost Complete!
  //       </h2>
  //       <p className="text-gray-600">
  //         All tourists have been successfully registered.
  //       </p>
  //       <PrimaryButton
  //         handleClick={() => {
  //           const data = {
  //             reservation_application_id: reservationApplication.id,
  //           };
  //           navigate(WEB_ROUTERS.TOURIST_PAYMENT, { state: data });
  //         }}
  //       >
  //         Pay Tourist Tax
  //       </PrimaryButton>
  //     </div>
  //   );
  // };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-2xl mx-auto px-4">
        {/* Guest count */}
        {guests.length === 0 && renderTouristCount()}

        {/* Guest #1 */}
        {guests.length > 0 && renderTouristEdit()}
        {/* {showCheckin && renderCheckin()} */}

        {/* <ConfirmationDialog
          isOpen={!!confirmState.text}
          onClose={() =>
            setConfirmState((s) => ({ ...s, text: "", callback: null }))
          }
          onConfirm={() => {
            const callback = confirmState.callback;
            setConfirmState((s) => ({ ...s, text: "", callback: null }));
            callback();
          }}
          title="Confirmation"
          message={confirmState.text}
        /> */}
        {loading && <MyLoading />}
        {modalCounter && (
          <MyModal>
            <div className="modal-text">{modalCounter}</div>
          </MyModal>
        )}
        <InfoDialog
          isOpen={!!infoText}
          title="Information"
          message={infoText ?? ""}
          onClose={() => setInfoText("")}
          onConfirm={() => setInfoText("")}
        />
      </div>
    </div>
  );
};
