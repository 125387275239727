import React from "react";
import { Building } from "lucide-react";


interface IFilterSelectorProps {
  selects: { key: string; label: string }[];
  selected: string;
  setSelected: (key: string) => void;
}

export function FilterSelector({
    selects,
    selected,
    setSelected,
}: IFilterSelectorProps) {
  return (
    <div className="flex items-center space-x-2">
      <Building className="w-5 h-5 text-gray-500" />
      <select
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
        className="rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
      >
        <option value="">All</option>
        {selects.map((select) => (
          <option key={select.key} value={select.key}>
            {select.label}
          </option>
        ))}
      </select>
    </div>
  );
}
