export const isOver16 = (dateOfBirth: string, entryDate: string): boolean => {
  const birth = new Date(dateOfBirth);
  const entry = new Date(entryDate);
  const age = entry.getFullYear() - birth.getFullYear();
  const monthDiff = entry.getMonth() - birth.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && entry.getDate() < birth.getDate())) {
    return age - 1 >= 16;
  }
  
  return age >= 16;
};

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString('en-GB');
};