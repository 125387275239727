import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface SidebarItemProps {
  icon: React.ReactNode;
  label: string;
  href: string;
}

export function SidebarItem({ icon, label, href }: SidebarItemProps) {
  const location = useLocation();
  const isActive = location.pathname.endsWith(href);

  return (
    <Link
      to={href}
      className={`flex items-center space-x-3 px-4 py-3 text-sm font-medium transition-colors ${
        isActive
          ? 'text-[#E47E7B] bg-[#F8DFDE]'
          : 'text-gray-600 hover:text-[#E47E7B] hover:bg-[#F8DFDE]'
      }`}
    >
      <span className={`${isActive ? 'text-[#E47E7B]' : 'text-gray-400'}`}>
        {icon}
      </span>
      <span>{label}</span>
    </Link>
  );
}