import React from 'react';
import { Tourist } from '../types/booking';
import { Users, UserCheck, UserMinus } from 'lucide-react';

interface TouristStatsProps {
  tourists: Tourist[];
}

export function TouristStats({ tourists }: TouristStatsProps) {
  const totalTourists = tourists.length;
  const adults = tourists.filter(t => t.isOver16).length;
  const minors = totalTourists - adults;

  return (
    <div className="grid grid-cols-3 gap-4 mb-6">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex items-center">
          <Users className="h-6 w-6 text-[#E47E7B]" />
          <div className="ml-3">
            <p className="text-sm text-gray-500">Total Tourists</p>
            <p className="text-2xl font-semibold">{totalTourists}</p>
          </div>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex items-center">
          <UserCheck className="h-6 w-6 text-green-500" />
          <div className="ml-3">
            <p className="text-sm text-gray-500">Adults</p>
            <p className="text-2xl font-semibold">{adults}</p>
          </div>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex items-center">
          <UserMinus className="h-6 w-6 text-yellow-500" />
          <div className="ml-3">
            <p className="text-sm text-gray-500">Minors</p>
            <p className="text-2xl font-semibold">{minors}</p>
          </div>
        </div>
      </div>
    </div>
  );
}