import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MySelect } from "../common/MySelect";
import { MyInput } from "../common/MyInput";
import {
  IReservation,
  IReservationsFilterOptions,
  IRow,
} from "../common/types";
import {
  createRows,
  filterRows,
  format_date,
  headerInfosDeposit,
  headerInfosIEAT,
  headerInfosInvoices,
  headerInfosReservations,
  sortRows,
  monthDiff,
} from "../../utils/helpers";
import { MyRadioButton } from "../common/MyButton";
import { PrimaryButton } from "./components/PrimaryButton";
import { SortedTable } from "./components/SortedTable";
import { TextField } from "./components/TextField";

interface IReservationsPageProps {
  reservationsInfo: IReservation[];
  tableName: string;
  onCancel: (
    reservation_application_id: number,
    canceledAt: string | null
  ) => Promise<void>;
  onDeposit: (
    reservation_application_id: number,
    depositAmount: number
  ) => Promise<void>;
  filterOptions: IReservationsFilterOptions;
  showAllGuests: boolean;
}

export const ReservationsBlockV2: React.FC<IReservationsPageProps> = ({
  reservationsInfo,
  tableName,
  onCancel,
  onDeposit,
  filterOptions,
  showAllGuests,
}) => {
  const { getText } = useContext(AppContext);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortAsc, setSortAsc] = useState<boolean>(true);

  const [rowsRef, setRowsRef] = useState<IRow[]>([]);
  const [rowsFiltered, setRowsFiltered] = useState<IRow[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);

  const [deposit, setDeposit] = useState<number>(0);
  const [depositsRef, setDepositsRef] = useState<{ [key: number]: number }>({});
  const [deposits, setDeposits] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    prepareRows(reservationsInfo, showAllGuests);
  }, [reservationsInfo, showAllGuests, tableName]);

  useEffect(() => {
    const rows_ = filterRows(
      rowsRef,
      filterOptions.reservationId,
      filterOptions.from,
      filterOptions.to,
      filterOptions.guestName
    );
    setRowsFiltered(rows_);
  }, [rowsRef, filterOptions, tableName]);

  useEffect(() => {
    const rows_ = sortRows(rowsFiltered, sortKey, sortAsc);
    setRows(rows_);
  }, [rowsFiltered, sortKey, sortAsc]);

  const handleSortChange = (key: string) => {
    if (sortKey === key) {
      if (sortAsc) {
        setSortAsc(false);
      } else {
        setSortKey("");
      }
    } else {
      setSortKey(key);
      setSortAsc(true);
    }
  };

  const prepareRows = (
    reservations_: IReservation[],
    allGuests: boolean = true
  ) => {
    const deposits_: { [key: number]: number } = {};
    reservations_.forEach((reservation) => {
      reservation.reservation_applications?.forEach(
        (reservation_application) => {
          deposits_[reservation_application.id] =
            reservation_application.deposit_amount;
        }
      );
    });
    setDepositsRef(deposits_);
    setDeposits({ ...deposits_ });
    // const allGuests = tableName === "reservations";
    const newRows = createRows(reservations_, allGuests);
    // console.log("newRows", newRows);
    if (tableName === "invoices") {
      const newRows_: IRow[] = [];

      newRows.forEach((row) => {
        const d1 = row.checkin ? new Date(row.checkin) : row.checkin;
        const d2 = row.checkout ? new Date(row.checkout) : row.checkout;
        if (d1 && d2) {
          const monthCount = monthDiff(d2, d1) + 1;
          for (let i = 0; i < monthCount; i++) {
            const d = new Date(d1.getFullYear(), d1.getMonth() + i);
            const dStr = d.getFullYear() + "-" + (d.getMonth() + 1); //d.toLocaleDateString("en-US");
            const row_ = { ...row, date: dStr };
            newRows_.push(row_);
          }
        }
      });
      const newRows_2: any[] = [];
      const date2set: any = {};
      newRows_.forEach((r: any) => {
        if (!(r.date in date2set)) {
          date2set[r.date] = new Set();
        }
        date2set[r.date].add(r.r_id);
      });
      Object.keys(date2set).forEach((date: any) => {
        const count = date2set[date].size;
        const invoiced = (9.88 * count).toFixed(2);
        newRows_2.push({ count, date, invoiced });
      });
      // console.log("newRows_2", newRows_2);

      setRowsRef(newRows_2);
      return newRows_2;
    }
    setRowsRef(newRows);
    return newRows;
  };

  const renderReservationRow = (row: any, index: number) => {
    const rowId = `r${row.r_id}_${row.ra_id}_${row.g_id}`;
    return (
      <tr key={rowId} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.checkin}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.checkout}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.nights}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.ng16}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.nle16}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <MySelect
            options={[{ value: -1, label: "No" }]}
            value={-1}
            onChange={(v: any) => {}}
            cls="outline"
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div
            className={row.canceled ? "btn filled" : "btn outline"}
            onClick={() =>
              onCancel(row.ra_id, row.canceled ? null : format_date(new Date()))
            }
          >
            {row.canceled ? row.canceled_at : "cancel"}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div
            className={
              row.transmitted ? "filled filled_green" : "outline outline_green"
            }
            onClick={() => {}}
          >
            {row.transmitted ? row.transmitted_at : ""}
          </div>
        </td>
      </tr>
    );
  };

  const renderIEATRow = (row: any, index: number) => {
    const rowId = `ieat${row.r_id}_${row.ra_id}_${row.g_id}`;
    return (
      <tr key={rowId} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.checkin}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.checkout}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.nights}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.ng16}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.nle16}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.ieat}&nbsp;€
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.igi}&nbsp;€
        </td>
        <td className="px-6 py-4 items-center whitespace-nowrap text-sm text-gray-500">
          <div
            className={row.canceled ? "btn filled" : "btn outline"}
            onClick={() =>
              onCancel(row.ra_id, row.canceled ? null : format_date(new Date()))
            }
          >
            {row.canceled ? row.canceled_at : "cancel"}
          </div>
        </td>
        <td className="px-6 py-4 items-center whitespace-nowrap text-sm text-gray-500">
          <div
            className={
              row.ieat_paid ? "filled filled_green" : "outline outline_green"
            }
            onClick={() => {}}
          >
            {row.ieat_paid_at}
          </div>
        </td>
      </tr>
    );
  };

  const currentDate = new Date();
  const currentDateTime = currentDate.getTime();
  const renderDepositRow = (row: any, index: number) => {
    const kept =
      deposits[row.ra_id] === depositsRef[row.ra_id] &&
      deposits[row.ra_id] >= 0;
    let paid = false;
    if (row.checkout) {
      const checkoutTime = new Date(row.checkout).getTime();
      paid = currentDateTime > checkoutTime;
    }

    const rowId = `d${row.r_id}_${row.ra_id}_${row.g_id}`;
    return (
      <tr key={rowId} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.checkin}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.checkout}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.nights}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.ng16}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {row.nle16}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <MyInput
            type="number"
            label={""}
            value={deposits[row.ra_id]}
            setValue={(v) => setDeposits((d) => ({ ...d, [row.ra_id]: +v }))}
            placeholder="300 €"
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div
            className={kept ? "btn filled" : "btn outline"}
            onClick={() => !kept && onDeposit(row.ra_id, deposits[row.ra_id])}
          >
            {kept ? "kept" : "keep"}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div
            className={
              kept && paid ? "filled filled_green" : "outline outline_green"
            }
          >
            {row.checkout}
          </div>
        </td>
      </tr>
    );
  };

  const renderInvoicesRow = (row: any, index: number) => {
    const rowId = `i${row.r_id}_${row.ra_id}_${row.g_id}`;
    return rows.map((row: any) => {
      return (
        <tr key={rowId} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {row.count}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {row.date}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {row.invoiced} €
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <div>
              <MyRadioButton checked={false} />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableReservations = () => {
    return (
      <SortedTable
        sortConfig={{
          field: sortKey ?? "",
          direction: sortAsc ? "asc" : "desc",
        }}
        onSort={(v) => handleSortChange(v)}
        headers={headerInfosReservations.map((h) => ({ ...h, sortable: true }))}
        rows={rows}
        renderRow={renderReservationRow}
      />
    );
  };

  const renderTableIEAT = () => {
    return (
      <SortedTable
        sortConfig={{
          field: sortKey ?? "",
          direction: sortAsc ? "asc" : "desc",
        }}
        onSort={(v) => handleSortChange(v)}
        headers={headerInfosIEAT.map((h) => ({ ...h, sortable: true }))}
        rows={rows}
        renderRow={renderIEATRow}
      />
    );
  };

  const renderTableDeposit = () => {
    return (
      <SortedTable
        sortConfig={{
          field: sortKey ?? "",
          direction: sortAsc ? "asc" : "desc",
        }}
        onSort={(v) => handleSortChange(v)}
        headers={headerInfosDeposit.map((h) => ({ ...h, sortable: true }))}
        rows={rows}
        renderRow={renderDepositRow}
      />
    );
  };

  const renderTableInvoices = () => {
    return (
      <SortedTable
        sortConfig={{
          field: sortKey ?? "",
          direction: sortAsc ? "asc" : "desc",
        }}
        onSort={(v) => handleSortChange(v)}
        headers={headerInfosInvoices.map((h) => ({ ...h, sortable: true }))}
        rows={rows}
        renderRow={renderInvoicesRow}
      />
    );
  };

  return (
    <div>
      {tableName === "deposit" && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", padding: "1rem" }}>
          <TextField
            type="number"
            label={"Set Deposit"}
            placeholder="300 €"
            value={""+deposit}
            onChange={(v:any) => setDeposit(+v)}
            messages={[]}
          />
        </div>
      )}
      {tableName === "reservations" && renderTableReservations()}
      {tableName === "ieat" && renderTableIEAT()}
      {tableName === "deposit" && renderTableDeposit()}
      {tableName === "invoices" && renderTableInvoices()}
      {tableName === "invoices" && (
        <div className="control_right">
          <div style={{ width: "200px" }}>
            <PrimaryButton handleClick={() => {}}>
              {getText("btn_download")}
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};
