import React from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

interface SortableTableHeaderProps {
  label: string;
  field: string;
  currentSort: {
    field: string;
    direction: 'asc'|'desc';
  };
  onSort: (field: string) => void;
}

export function SortedHeader({
  label,
  field,
  currentSort,
  onSort,
}: SortableTableHeaderProps) {
  const isActive = currentSort.field === field;

  return (
    <th
      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer hover:bg-[#D16B68] transition-colors"
      onClick={() => onSort(field)}
    >
      <div className="flex items-center space-x-1">
        <span>{label}</span>
        <span className="inline-flex flex-col">
          <ChevronUp
            className={`h-3 w-3 ${
              isActive && currentSort.direction === "asc"
                ? "text-white"
                : "text-gray-300"
            }`}
          />
          <ChevronDown
            className={`h-3 w-3 ${
              isActive && currentSort.direction === "desc"
                ? "text-white"
                : "text-gray-300"
            }`}
          />
        </span>
      </div>
    </th>
  );
}
