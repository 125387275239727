import React from 'react';
import { Booking, Property } from '../types/booking';
import { formatDate } from '../utils/dateUtils';
import { getPropertyName } from '../utils/propertyUtils';
import { CheckCircle, XCircle, Eye, XSquare } from 'lucide-react';
import { SortableTableHeader } from './SortableTableHeader';
import { SortConfig, sortBookings } from '../utils/sortUtils';

interface BookingTableProps {
  properties: Property[];
  bookings: Booking[];
  sortConfig: SortConfig;
  onSort: (field: SortConfig['field']) => void;
  onCancelBooking: (id: string) => void;
  onViewDetails: (id: string) => void;
}

export function BookingTable({
  properties,
  bookings,
  sortConfig,
  onSort,
  onCancelBooking,
  onViewDetails
}: BookingTableProps) {
  const sortedBookings = sortBookings(bookings, sortConfig);

  const getTouristDisplay = (tourists: Booking['tourists']) => {
    if (tourists.length === 0) return 'No tourists';
    const mainTourist = `${tourists[0].firstName} ${tourists[0].name}`;
    if (tourists.length === 1) return mainTourist;
    return `${mainTourist} +${tourists.length - 1}`;
  };

 

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-[#E47E7B]">
        <thead className="bg-[#E47E7B]">
          <tr>
            <SortableTableHeader
              label="Check-in ID"
              field="id"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <SortableTableHeader
              label="Property"
              field="property"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <SortableTableHeader
              label="Tourists"
              field="tourists"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <SortableTableHeader
              label="Entry Date"
              field="entryDate"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <SortableTableHeader
              label="Exit Date"
              field="exitDate"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <SortableTableHeader
              label="Status"
              field="status"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <SortableTableHeader
              label="Tax Paid"
              field="taxPaid"
              currentSort={sortConfig}
              onSort={onSort}
            />
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-[#E47E7B]">
          {sortedBookings.map((booking, index) => (
            <tr key={booking.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {booking.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {getPropertyName(booking.propertyId, properties)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {getTouristDisplay(booking.tourists)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDate(booking.entryDate)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDate(booking.exitDate)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${booking.status === 'complete' ? 'bg-green-100 text-green-800' :
                    booking.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                    'bg-yellow-100 text-yellow-800'}`}>
                  {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {booking.taxPaid ? (
                  <CheckCircle className="w-5 h-5 text-green-500" />
                ) : (
                  <XCircle className="w-5 h-5 text-red-500" />
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <div className="flex space-x-2">
                  <button
                    onClick={() => onViewDetails(booking.id)}
                    className="inline-flex items-center px-3 py-1.5 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
                  >
                    <Eye className="w-4 h-4 mr-1" />
                    View
                  </button>
                  {booking.status !== 'cancelled' && (
                    <button
                      onClick={() => onCancelBooking(booking.id)}
                      className="inline-flex items-center px-3 py-1.5 bg-red-50 text-red-600 rounded-md hover:bg-red-100 transition-colors"
                    >
                      <XSquare className="w-4 h-4 mr-1" />
                      Cancel
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}