import React, { useContext, useState } from "react";
import { AppContext } from "../../../utils/AppContext";
import { MyInput } from "../../common/MyInput";
import { useNavigate } from "react-router-dom";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
  trimForm,
  validateFormOptionalExt,
  validateFormOptional,
} from "../../../utils/FormManager";
import { MyLoading, MyModal } from "../../common/MyModal";
import { API_URLS } from "../../../utils/RequestManager";
import { WEB_ROUTERS } from "../../../utils/routes";
import { TextField } from "../../common/components/TextField";
import { Mail, Phone, User, Lock } from "lucide-react";
import { PrimaryButton } from "../../common/components/PrimaryButton";
import { InfoDialog } from "../TouristRegistration/components/InfoDialog";

const name2validatorsGenerate = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
};

const name2validatorsWithCode = {
  ...name2validatorsGenerate,
  code: [notEmptyValidator()],
};

const name2validatorsPassword = {
  email: [notEmptyValidator()],
  code: [notEmptyValidator()],
  password: [lengthValidator(8, 255)],
  password2: [lengthValidator(8, 255)],
};

const name2validatorsAll = {
  ...name2validatorsWithCode,
  ...name2validatorsPassword,
};

interface IFormData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  code: string;
  password: string;
  password2: string;
}
type IFormMessages = { [key in keyof IFormData]: string[] };

export const ForgotPassword: React.FC<{}> = () => {
  const { requestManager, getText } = useContext(AppContext);

  const [formData, setFormData] = useState<IFormData>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    code: "",
    password: "",
    password2: "",
  });
  const [formMessages, setFormMessages] = useState<IFormMessages>({
    firstname: [],
    lastname: [],
    email: [],
    phone: [],
    code: [],
    password: [],
    password2: [],
  });

  //   const [modalText, setModalText] = useState("");
  const [infoText, setInfoText] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const navigate = useNavigate();

  const checkData = (data: any, n2v: any) => {
    const data_ = { ...data };
    trimForm(data_);
    const [isValid, msgs] = validateFormOptionalExt(data_, n2v);
    setFormMessages((prev) => ({ ...prev, ...msgs }));
    if (!isValid) {
      return null;
    }
    return data_;
  };

  //   const composeCodeData = (
  //     data: { [key: string]: any },
  //     n2v: { [key: string]: any }
  //   ) => {
  //     const invalidFields = validateForm(data, n2v);
  //     if (invalidFields.length > 0) {
  //       const msgs = invalidFields.reduce((r: any, v) => {
  //         const key = v[0];
  //         if (!(key in r)) {
  //           r[key] = [];
  //         }
  //         r[key].push(v[1]);
  //         return r;
  //       }, {});
  //       setFormMessages({ ...formMessages, ...msgs });
  //       return null;
  //     }
  //     return data;
  //   };

  const handleGenerateCode = async () => {
    setLoading(true);
    setVerified(false);
    const data_ = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: formData.phone,
    };
    const data = checkData(data_, name2validatorsGenerate);
    if (!data) {
      setInfoText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const res = await requestManager.postJson(
      API_URLS.USER_PASSWORD_RESETCODE,
      data,
      false
    );
    setLoading(false);
    if (res.status === 200) {
      setInfoText(getText("m_code_sent"));
    } else {
      setInfoText(getText("m_failed_generate_code"));
    }
  };

  const handleValidate = async () => {
    setLoading(true);
    const data_ = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: formData.phone,
      code: formData.code,
    };
    const data = checkData(data_, name2validatorsWithCode);
    if (!data) {
      setInfoText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    const res = await requestManager.postJson(
      API_URLS.USER_PASSWORD_CHECKCODE,
      data,
      false
    );
    setLoading(false);
    if (res.status === 200) {
      setVerified(true);
    } else {
      setVerified(false);
      setInfoText(getText("m_code_invalid"));
    }
  };

  const handleSavePassword = async () => {
    setLoading(true);
    const data_ = {
      email: formData.email,
      password: formData.password,
      password2: formData.password2,
      code: formData.code,
    };
    const data = checkData(data_, name2validatorsPassword);
    if (!data) {
      setInfoText(getText("m_some_fields_invalid"));
      setLoading(false);
      return;
    }
    delete data["password2"];
    const res = await requestManager.postJson(
      API_URLS.USER_PASSWORD_RESET,
      data,
      false
    );
    setLoading(false);
    if (res.status === 200) {
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    } else {
      setInfoText(getText("m_failed_change_pass"));
      setVerified(false);
    }
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setFormData({ ...formData, ...newData });
    const newMsgs = validateFormOptional(newData, name2validatorsAll);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  return (
    <div className="page-content">
      <div className="form-container  card-size">
        <div className="form-card">
          <div className="control__wrap">
            <div className="form__control">
              <TextField
                label={getText("t_firstname")}
                placeholder={getText("ph_firstname")}
                value={formData.firstname}
                onChange={(v) => handleChange("firstname", v)}
                messages={formMessages.firstname}
                icon={<User className="w-5 h-5" />}
              />
            </div>
            <div className="form__control">
              <TextField
                label={getText("t_lastname")}
                placeholder={getText("ph_lastname")}
                value={formData.lastname}
                onChange={(v) => handleChange("lastname", v)}
                messages={formMessages.lastname}
                icon={<User className="w-5 h-5" />}
              />
            </div>
            <div className="form__control">
              <TextField
                type="phone"
                label={getText("l_phone")}
                placeholder={getText("ph_phone")}
                value={formData.phone}
                onChange={(v) => handleChange("phone", v)}
                messages={formMessages.phone}
                icon={<Phone className="w-5 h-5" />}
              />
            </div>
            <div className="form__control">
              <TextField
                type="email"
                label={getText("l_email")}
                placeholder={getText("ph_email")}
                value={formData.email}
                onChange={(v) => handleChange("email", v)}
                messages={formMessages.email}
                icon={<Mail className="w-5 h-5" />}
              />
            </div>
          </div>
          <div className="control_center">
            <PrimaryButton handleClick={handleGenerateCode}>
              {getText("btn_generate_code")}
            </PrimaryButton>
            {/* <div
              className="btn bg_primary"
              onClick={() => handleGenerateCode()}
            >
              {getText("btn_generate_code")}
            </div> */}
          </div>
        </div>

        <div className="form-card">
          <div className="control__wrap" style={{ alignItems: "center" }}>
            <div className="form__control">
              <TextField
                type="text"
                label={getText("l_code")}
                placeholder={getText("ph_code")}
                value={formData.code}
                onChange={(v) => handleChange("code", v)}
                messages={formMessages.code}
                icon={<Lock className="w-5 h-5" />}
              />
            </div>
            <div className="form__control">{getText("msg_code_sent")}</div>
          </div>
          <div className="control_center">
            <PrimaryButton handleClick={handleValidate}>
              {getText("btn_verify_code")}
            </PrimaryButton>
            {/* <div className="btn bg_primary" onClick={() => handleValidate()}>
              {getText("btn_verify_code")}
            </div> */}
          </div>
        </div>

        {verified && (
          <div className="form-card">
            <div className="control__wrap" style={{ alignItems: "center" }}>
              <div className="form__control">
                <TextField
                  type="password"
                  label={getText("l_password")}
                  placeholder={getText("ph_password")}
                  value={formData.password}
                  onChange={(v) => handleChange("password", v)}
                  messages={formMessages.password}
                  icon={<Lock className="w-5 h-5" />}
                />
              </div>
              <div className="form__control">
                <TextField
                  type="password"
                  label={getText("l_password2")}
                  placeholder={getText("ph_password")}
                  value={formData.password2}
                  onChange={(v) => handleChange("password2", v)}
                  messages={formMessages.password2}
                  icon={<Lock className="w-5 h-5" />}
                />
                {formData.password !== formData.password2 && (
                  <p className="error-message">{getText("m_pass_not_match")}</p>
                )}
              </div>
            </div>
            <div className="control_center">
              <PrimaryButton handleClick={handleSavePassword}>
                {getText("btn_change_password")}
              </PrimaryButton>
              {/* <div
                className="btn bg_primary"
                onClick={() => handleSavePassword()}
              >
                {getText("btn_change_password")}
              </div> */}
            </div>
          </div>
        )}
      </div>

      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText('')}
        onConfirm={() => setInfoText('')}
      />
      {/* {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )} */}
      {loading && <MyLoading />}
    </div>
  );
};
