import {
  IGuest,
  IHeaderInfo,
  IReservation,
  IRow,
} from "../components/common/types";
import { TAX_EUR } from "./constants";

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

export function makeDeepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

// Manager Tables
function getQuarterDate(d: Date) {
  const year = d.getFullYear();
  const month = d.getMonth();
  const quarterDates = [
    new Date(year, 2, 30),
    new Date(year, 5, 30),
    new Date(year, 8, 30),
    new Date(year, 11, 31),
  ];
  const quarter = Math.floor((month + 3) / 3) - 1;
  return quarterDates[quarter];
}

function calculateAge(date: Date) {
  // birthday is a date
  var ageDifMs = Date.now() - date.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function createRows(
  reservations: IReservation[],
  allGuests: boolean = true
) {
  const rows: any[] = [];
  reservations.forEach((reservation) => {
    reservation.reservation_applications?.forEach((reservation_application) => {
      let nights: number = 0;
      if (
        reservation_application.checkin_date &&
        reservation_application.checkout_date
      ) {
        const checkin_date_d = new Date(reservation_application.checkin_date);
        const checkout_date_d = new Date(reservation_application.checkout_date);
        nights = Math.floor(
          (checkout_date_d.getTime() - checkin_date_d.getTime()) /
            (1000 * 60 * 60 * 24)
        );
      }
      let ng16 = 0;
      let nle16 = 0;

      reservation_application.guests?.forEach((guest) => {
        let age = 0;
        if (guest.birth_date) {
          const birth_date_d = new Date(guest.birth_date);
          age = calculateAge(birth_date_d);
        }

        if (age >= 16) {
          ng16++;
        } else {
          nle16++;
        }
      });

      

      const transfer = "No"; // NOTE: not implemented
      const canceled = Boolean(reservation_application.canceled_at);
      const canceled_at =
        prepare_date_str(reservation_application.canceled_at) || "";
      const transmitted = Boolean(reservation_application.transmitted_at);
      const transmitted_at: string =
        prepare_date_str(reservation_application.transmitted_at) || "";

      const ieat = reservation_application.tax_amount * TAX_EUR;
      const igi = (ieat * 0.045).toFixed(2);
      // TODO:
      const ieat_paid = Boolean(reservation_application.tax_paid_at);
      const ieat_paid_at = prepare_date_str(reservation_application.tax_paid_at);
      const deposit_kept = false;

      // if (reservation_application.checkin_date) {
      //   const checkin_date_d = new Date(reservation_application.checkin_date);
      //   const quarter_d = getQuarterDate(checkin_date_d);
      //   const now = new Date();
      //   ieat_paid = quarter_d.getTime() <= now.getTime();
      //   ieat_paid_at = format_date(quarter_d);
      // }

      const formatRow = (guest: IGuest) => {
        const row = {
          r_id: reservation.id,
          ra_id: reservation_application.id,
          g_id: guest.id,
          name: `${guest.lastname} ${guest.firstname}`,
          checkin: reservation_application.checkin_date,
          checkout: reservation_application.checkout_date,
          nights,
          ng16,
          nle16,
          transfer,
          canceled,
          canceled_at,
          transmitted,
          transmitted_at,
          ieat,
          igi,
          ieat_paid,
          ieat_paid_at,
          deposit: reservation_application.deposit_amount,
          deposit_kept,
          deposit_kept_at: "",
          deposit_paid_back: false,
          deposit_paid_back_at: "",
          property: reservation.address,
          invoiced: 9.99,
        };
        return row;
      };

      if (reservation_application.guests) {
        if (allGuests) {
          reservation_application.guests.forEach((guest) => {
            const row = formatRow(guest);
            rows.push(row);
          });
        } else if (reservation_application.guests.length > 0) {
          const row = formatRow(reservation_application.guests[0]);
          rows.push(row);
        }
      }
    });
  });
  return rows;
}

export const headerInfosReservations: IHeaderInfo[] = [
  { label: "Guest Name", key: "name" },
  { label: "Checkin", key: "checkin" },
  { label: "Checkout", key: "checkout" },
  { label: "Nights", key: "nights" },
  { label: "Nb > 16", key: "ng16" },
  { label: "Nb <= 16", key: "nle16" },
  { label: "Transfer", key: "transfer" },
  { label: "Cancel", key: "canceled_at" },
  { label: "Transmitted", key: "transmitted_at" },
];

export const headerInfosIEAT: IHeaderInfo[] = [
  { label: "Guest Name", key: "name" },
  { label: "Checkin", key: "checkin" },
  { label: "Checkout", key: "checkout" },
  { label: "Nights", key: "nights" },
  { label: "Nb > 16", key: "ng16" },
  { label: "Nb <= 16", key: "nle16" },
  { label: "IEAT", key: "ieat" },
  { label: "IGI", key: "igi" },
  { label: "Cancel", key: "canceled_at" },
  { label: "Paid", key: "ieat_paid_at" },
];

export const headerInfosDeposit: IHeaderInfo[] = [
  { label: "Guest Name", key: "name" },
  { label: "Checkin", key: "checkin" },
  { label: "Checkout", key: "checkout" },
  { label: "Nights", key: "nights" },
  { label: "Nb > 16", key: "ng16" },
  { label: "Nb <= 16", key: "nle16" },
  { label: "Deposit", key: "deposit" },
  { label: "Keep", key: "deposit_kept" },
  { label: "Paid back", key: "deposit_paid_back_at" },
];

export const headerInfosInvoices: IHeaderInfo[] = [
  { label: "Property", key: "property" },
  { label: "Month", key: "date" },
  { label: "Invoiced", key: "invoiced" },
  { label: "Select", key: "select" },
];

function checkRow(
  row: IRow,
  rId: number,
  fromDate: string,
  toDate: string,
  guestName: string
) {
  if (row.r_id === undefined) return true;
  if (rId !== -1 && row.r_id !== rId) {
    return false;
  }
  if (fromDate && row.checkin < fromDate) {
    return false;
  }
  if (toDate && row.checkout > toDate) {
    return false;
  }
  if (guestName && row.name.indexOf(guestName) === -1) {
    return false;
  }
  return true;
}
export function filterRows(
  rows: IRow[],
  rId: string,
  fromDate: string,
  toDate: string,
  guestName: string
) {
  // console.log("filterRows", rows, rId, fromDate, toDate, guestName);
  const res = rows.filter((row: IRow) =>
    checkRow(row, rId === "" ? -1 : +rId, fromDate, toDate, guestName)
  );
  return res;
}
export function sortRows(rows: any[], key: string | null, asc: boolean = true) {
  // console.log("sortRows", rows, key, asc);
  const res = Array.from(rows);
  if (key) {
    res.sort((lhs: any, rhs: any) => {
      const lhs_val = lhs[key];
      const rhs_val = rhs[key];
      if (lhs_val === rhs_val) return 0;
      const res = lhs_val < rhs_val ? -1 : 1;
      return asc ? res : -res;
    });
  }
  return res;
}

export function prepare_date_str(dStr: string) {
  if (!dStr) return null;
  const date = new Date(dStr);
  return format_date(date);
}
export function format_date(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return [
    year,
    (month > 9 ? "" : "0") + month,
    (day > 9 ? "" : "0") + day,
  ].join("-");
}

export function monthDiff(d1: Date, d2: Date) {
  let months = 0;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
