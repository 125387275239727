import React, { useContext, useEffect, useRef, useState } from "react";
import { Logo } from "./components/Logo";
import { PropertySelector } from "./components/PropertySelector";
import { DateRangeFilter } from "./components/DateRangeFilter";
import { BookingTable } from "./components/BookingTable";
import { TouristDetailsModal } from "./components/TouristDetailsModal";
import { TouristEntries } from "./components/TouristEntries";
import { ConfirmationDialog } from "./components/ConfirmationDialog";
import { UndoToast } from "./components/UndoToast";
import { SearchBar } from "./components/SearchBar";
// import { mockBookings } from "./utils/mockData";
import { Booking, Property, Tourist } from "./types/booking";
import { filterBookingsByTouristName } from "./utils/filterUtils";
import { sortBookings, SortConfig, SortField } from "./utils/sortUtils";
import {
  IGuest,
  IReservation,
  IReservationApplication,
} from "../../common/types";
import { AppContext } from "../../../utils/AppContext";
import { API_URLS } from "../../../utils/RequestManager";
import { WEB_ROUTERS } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import { isOver16 } from "./utils/dateUtils";
import { MyLoading } from "../../common/MyModal";
import { format_date } from "../../../utils/helpers";
import { InfoDialog } from "./components/InfoDialog";
import { getPropertyName } from "./utils/propertyUtils";
import { ENGLISH_TO_ISO_INFO } from "../../../utils/iso_codes";

function tryGetISOInfoByEnglish(english: string) {
  if (english in ENGLISH_TO_ISO_INFO) {
    return ENGLISH_TO_ISO_INFO[english];
  }
  return null;
}
function tryFormatDate(dateStr: string) {
  const parts = dateStr.split("-");
  if (parts.length !== 3) {
    return dateStr;
  }
  return [parts[2], parts[1], parts[0]].join("/");
}
function reservationInfos2Bookings(
  reservationInfos: IReservation[]
): [Booking[], Property[]] {
  const bookings: Booking[] = [];
  const properties: Property[] = [];
  reservationInfos.forEach((reservation: IReservation) => {
    const property: Property = {
      id: "" + reservation.id,
      name: reservation.roat_number,
      location: reservation.address,
    };
    properties.push(property);
    reservation.reservation_applications?.forEach(
      (reservationApplication: IReservationApplication) => {
        const entryDate = reservationApplication.checkin_date;

        let status: "pending" | "complete" | "cancelled" | "submitted" =
          "pending";
        if (reservationApplication.canceled_at) {
          status = "cancelled";
        } else if (reservationApplication.transmitted_at) {
          status = "submitted";
        } else if (reservationApplication.finalized_at) {
          status = "complete";
        }
        const booking: Booking = {
          id: "" + reservationApplication.id,
          propertyId: "" + reservation.id,
          tourists: [],
          entryDate: reservationApplication.checkin_date,
          exitDate: reservationApplication.checkout_date,
          taxPaid: Boolean(reservationApplication.tax_paid_at),
          taxPaidDate: reservationApplication.tax_paid_at,
          governmentSubmissionDate: reservationApplication.transmitted_at,
          cancellationDate: reservationApplication.canceled_at,
          status: status,
          lastModified: reservationApplication.finalized_at,
        };
        reservationApplication?.guests?.forEach((guest: IGuest) => {
          const isOver16_ = guest.birth_date
            ? isOver16(guest.birth_date, entryDate)
            : true;
          const validPhotoIds = guest.id_images
            .filter((im) => im.valid)
            .map((im) => im.id);
          const photoId =
            validPhotoIds.length > 0 ? validPhotoIds.join(",") : null;
          const tourist: Tourist = {
            id: "" + guest.id,
            name: guest.lastname,
            firstName: guest.firstname,
            dateOfBirth: guest.birth_date,
            photoId: photoId,
            isOver16: isOver16_,
            citizenship: guest.citizenship,
            place_of_birth: guest.place_of_birth,
            residency: guest.residency,
          };
          booking.tourists.push(tourist);
        });

        bookings.push(booking);
      }
    );
  });
  return [bookings, properties];
}

export default function TouristRegistrationPage() {
  const [reservationInfos, setReservationInfos] = useState<IReservation[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [showTourists, setShowTourists] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: "id",
    direction: "asc",
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState<string | null>(null);
  const [showUndoToast, setShowUndoToast] = useState(false);
  const [lastCanceledBooking, setLastCanceledBooking] =
    useState<Booking | null>(null);
  const [loading, setLoading] = useState(false);
  const [infoText, setInfoText] = useState<string | null>(null);

  const { user, requestManager } = useContext(AppContext);
  const inProgress = useRef(false);

  const navigate = useNavigate();

  // NOTE: getting real data
  useEffect(() => {
    if (!user) {
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    }
    refreshReservationInfos();
  }, []);
  useEffect(() => {
    if (!user) {
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    }
    convertReservationInfosToBookings();
  }, [reservationInfos]);
  const refreshReservationInfos = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await loadReservationsInfo();
    inProgress.current = false;
  };
  const loadReservationsInfo = async () => {
    // console.log("loadReservationsInfo");
    // setLoading(true);
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_INFO_GETALL
    );
    // console.log(resp);
    setLoading(false);
    if (resp.status === 200) {
      const reservations_ = resp.data;
      setReservationInfos(reservations_);
      return reservations_;
    } else {
      // alert("failed to get reservation infos" + JSON.stringify(resp));
      // setModalText("Failed to load reservation info");
    }
    // setLoading(false);
    return [];
  };
  const convertReservationInfosToBookings = () => {
    const [bookings_, properties_] =
      reservationInfos2Bookings(reservationInfos);
    // console.log(bookings_, properties_);
    setBookings(bookings_);
    setProperties(properties_);
  };

  const handleSort = (field: SortField) => {
    setSortConfig((current) => ({
      field,
      direction:
        current.field === field && current.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleCancelBooking = (id: string) => {
    setBookingToCancel(id);
    setShowConfirmDialog(true);
  };

  const handleReservationCancel = async (
    reservation_application_id: number,
    canceled_at: string | null
  ) => {
    setLoading(true);
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_APPLICATIONS_SAVE,
      { id: reservation_application_id, canceled_at }
    );
    if (resp.status === 200) {
      await refreshReservationInfos();
      setLoading(false);
      return true;
    }
    setLoading(false);
    setInfoText(
      "Failed to modify reservation with id " + reservation_application_id
    );
    return false;
  };

  const confirmCancelBooking = async () => {
    if (bookingToCancel) {
      const bookingToUpdate = bookings.find((b) => b.id === bookingToCancel);
      if (bookingToUpdate) {
        // try update
        const nowStr = format_date(new Date());
        const succ = await handleReservationCancel(
          Number(bookingToUpdate.id),
          nowStr
        );
        if (!succ) {
        }
        setLastCanceledBooking({ ...bookingToUpdate });
        setBookings((current) =>
          current.map((booking) =>
            booking.id === bookingToCancel
              ? {
                  ...booking,
                  status: "cancelled",
                  cancellationDate: new Date().toISOString(),
                }
              : booking
          )
        );
        setShowUndoToast(true);
      }
    }
    setShowConfirmDialog(false);
    setBookingToCancel(null);
  };

  const handleUndo = async () => {
    if (lastCanceledBooking) {
      await handleReservationCancel(Number(lastCanceledBooking.id), null);
      setBookings((current) =>
        current.map((booking) =>
          booking.id === lastCanceledBooking.id
            ? { ...lastCanceledBooking }
            : booking
        )
      );
      setShowUndoToast(false);
      setLastCanceledBooking(null);
    }
  };

  const filteredBookings = filterBookingsByTouristName(
    bookings.filter((booking) => {
      if (selectedProperty && booking.propertyId !== selectedProperty)
        return false;
      if (startDate && booking.entryDate < startDate) return false;
      if (endDate && booking.exitDate > endDate) return false;
      return true;
    }),
    searchTerm
  );

  const handleExportCSV = () => {
    // 'firstname',
    // 'lastname',
    // 'citizenship',
    // 'birth_date',
    // 'place_of_birth',
    // 'residency',
    // 'col_1',
    // 'col_2',
    // 'col_3',
    // 'col_4',
    // 'col_5',
    // 'col_6',
    // 'checkin_date',
    // 'col_8',
    // 'checkout_date',
    // 'col_9',
    // 'roat_number',
    setLoading(true);
    const sortedBookings = sortBookings(bookings, sortConfig);
    const rows: any[] = [];
    sortedBookings.forEach((booking: Booking) => {
      // birth_date - d/m/Y
      const propertyName = getPropertyName(booking.propertyId, properties);
      booking.tourists.forEach((tourist: Tourist) => {
        const citizenshipAlpha2 =
          tryGetISOInfoByEnglish(tourist.citizenship)?.["alpha2"] ??
          tourist.citizenship;
        const dateOfBirth = tryFormatDate(tourist.dateOfBirth);
        const placeOfBirthCatalan =
          tryGetISOInfoByEnglish(tourist.place_of_birth)?.["catalan"] ??
          tourist.place_of_birth;
        const residencyAlpha2 =
          tryGetISOInfoByEnglish(tourist.residency)?.["alpha2"] ??
          tourist.residency;
        const row = [
          tourist.firstName,
          tourist.name,
          citizenshipAlpha2,
          dateOfBirth,
          placeOfBirthCatalan,
          residencyAlpha2,
          ",,,,",
          "",
          "",
          "",
          "",
          "",
          booking.entryDate,
          "",
          booking.exitDate,
          "",
          propertyName,
        ];
        rows.push(row.map((s) => JSON.stringify(s)));
      });
    });

    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach((rowArray: any[]) => {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "result.csv");
    document.body.appendChild(link); // Required for FF

    setLoading(false);
    link.click(); // This will download the data file named "my_data.csv".
  };

  const handleExportCSV2 = async () => {
    setLoading(true);
    const sortedBookings = sortBookings(bookings, sortConfig);
    const reservation_application_ids: number[] = sortedBookings.map(
      (b) => +b.id
    );
    const resp = await requestManager.postBlob(
      API_URLS.MANAGER_V2_RESERVATIONS_APPLICATIONS_EXPORT,
      { reservation_application_ids }
    );
    
    // console.log("resp", resp);
    if (resp.status === 200) {
      var encodedUri = window.URL.createObjectURL(resp.data);
      // var encodedUri = encodeURI(resp.data);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "result.csv");
      document.body.appendChild(link); // Required for FF

      setLoading(false);
      link.click(); // This will download the data file named "my_data.csv".
    } else {
      setLoading(false);
      setInfoText("Failed to export bookings");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <Logo />
      </header>

      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-[#E47E7B]">
            Tourist Check-in
          </h1>
          <p className="mt-2 text-gray-600">
            Manage and track tourist registrations
          </p>
        </div>

        <div className="mb-6 space-y-4">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
              <PropertySelector
                properties={properties}
                selectedProperty={selectedProperty}
                onPropertyChange={setSelectedProperty}
              />
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
              />
            </div>
            <div className="flex" style={{ gap: "1rem" }}>
              <button
                onClick={handleExportCSV2}
                className="bg-[#E47E7B] text-white px-4 py-2 rounded hover:bg-[#D16B68] transition-colors"
              >
                Export
              </button>
              <button
                onClick={() => setShowTourists(!showTourists)}
                className="bg-[#E47E7B] text-white px-4 py-2 rounded hover:bg-[#D16B68] transition-colors"
              >
                {showTourists ? "Show Bookings" : "Show Tourists"}
              </button>
            </div>
          </div>

          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search tourist names..."
          />
        </div>

        {showTourists ? (
          <TouristEntries bookings={filteredBookings} />
        ) : (
          <BookingTable
            properties={properties}
            bookings={filteredBookings}
            sortConfig={sortConfig}
            onSort={handleSort}
            onCancelBooking={handleCancelBooking}
            onViewDetails={(id) => {
              const booking = bookings.find((b) => b.id === id);
              if (booking) {
                setSelectedBooking(booking);
              }
            }}
          />
        )}

        {selectedBooking && (
          <TouristDetailsModal
            booking={selectedBooking}
            isOpen={true}
            onClose={() => setSelectedBooking(null)}
          />
        )}

        <ConfirmationDialog
          isOpen={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={confirmCancelBooking}
          title="Cancel Booking"
          message="Are you sure you want to cancel this booking? This action can be undone."
        />

        <InfoDialog
          isOpen={!!infoText}
          title="Information"
          message={infoText ?? ""}
          onClose={() => setInfoText(null)}
          onConfirm={() => setInfoText(null)}
        />

        {showUndoToast && (
          <UndoToast
            message="Booking cancelled successfully"
            onUndo={handleUndo}
            onClose={() => setShowUndoToast(false)}
          />
        )}
      </main>
      {loading && <MyLoading />}
    </div>
  );
}
