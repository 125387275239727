import React, { useContext } from "react";
import { SidebarItem } from "./SidebarItem";
import { WEB_ROUTERS } from "../utils/routes";
import { Link } from "react-router-dom";
import { AppContext } from "../utils/AppContext";
import { THE_CODE_LINES } from "../utils/constants";
import {
  Users,
  Building2,
  BookOpen,
  CreditCard,
  Scale,
  HelpCircle,
} from "lucide-react";

export function Sidebar() {
  const { getText } = useContext(AppContext);

  return (
    <div className="sidebar w-64 min-h-screen bg-white border-r border-gray-200 flex flex-col">
      <div className="p-4">
        <Link to={WEB_ROUTERS.ROOT}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={"/images/roatandorra_logo2.png"}
              className="logo"
              alt="logo"
            />
          </div>
        </Link>
      </div>

      <nav className="flex-1 pt-4">
        <SidebarItem
          icon={
            <Users className="w-5 h-5" />
          }
          label={getText("sb_tourists_checkin")}
          href={WEB_ROUTERS.TOURIST_RESERVATION}
        />

        <SidebarItem
          icon={
            <Building2 className="w-5 h-5" />
          }
          label={getText("sb_property_manager")}
          href={WEB_ROUTERS.CUSTOMER_PROPERTIES}
        />

        <SidebarItem
          icon={
            <BookOpen className="w-5 h-5" />
          }
          label={getText("sb_user_guide")}
          href={WEB_ROUTERS.USER_GUIDE}
        />
{/* 
        <SidebarItem
          icon={
            <BookOpen className="w-5 h-5" />
          }
          label={getText("sb_tourist_reservations")}
          href={WEB_ROUTERS.CUSTOMER_TOURIST_REGISTRATIONS}
        /> */}

        {/* <SidebarItem
          icon={
            <CreditCard className="w-5 h-5" />
          }
          label={getText("sb_terms_prices")}
          href={WEB_ROUTERS.PRICES}
        /> */}
        <SidebarItem
          icon={
            <CreditCard className="w-5 h-5" />
          }
          label={getText("sb_services")}
          href={WEB_ROUTERS.SERVICES}
        />

        <SidebarItem
          icon={
            <Scale className="w-5 h-5" />
          }
          label={getText("sb_legislation_in_force")}
          href={WEB_ROUTERS.LEGISLATION_IN_FORCE}
        />

        <SidebarItem
          icon={
            <HelpCircle className="w-5 h-5" />
          }
          label={getText("sb_app_download")}
          href={WEB_ROUTERS.APP_DOWNLOAD}
        />
      </nav>

      <div className="the-code">
        {THE_CODE_LINES.map((t: string, i: number) => (
          <p key={i}>{t}</p>
        ))}
      </div>

      <div className="sidebar__contacts">
        <h3 className="contacts__header">{getText("h_contactus")}</h3>
        <div className="contacts__content">
          <div className="contacts__item">
            <img src={"/images/phone.svg"} alt="phone" />
            {getText("contacts_phone")}
          </div>
          <div className="contacts__item">
            <img src={"/images/time.svg"} alt="time" />
            {getText("contacts_time")}
          </div>
          <div className="contacts__item">
            <img src={"/images/email.svg"} alt="email" />
            {getText("contacts_email")}
          </div>
        </div>
      </div>

      <div className="p-4 border-t border-gray-200">
        <div className="text-xs text-gray-500 text-center">
          © 2025 Roatandorra
        </div>
      </div>
    </div>
  );
}
