export const TRANSLATION_RECORDS: { [key: string]: string }[] = [
  // MAIN
  {
    key: "sb_tourists_checkin",
    spanish: "Registro de turistas",
    english: "Tourists Check-in",
    french: "Enregistrements",
    russian: "Турист",
  },
  {
    key: "sb_property_manager",
    spanish: "Administrador ",
    english: "Property Manager",
    french: "Administrateur",
    russian: "Менеджер",
  },
  {
    key: "sb_user_guide",
    spanish: "Guía de usuario",
    english: "User Guide",
    french: "Guide de l'utilisateur",
    russian: "Руководство пользователя",
  },
  {
    key: "sb_terms_prices",
    spanish: "Condiciones y precios",
    english: "Terms & Prices",
    french: "Conditions et prix",
    russian: "Условия & Цены",
  },
  {
    key: "sb_services",
    spanish: "Servicios",
    english: "Services",
    french: "Services",
    russian: "Услуги",
  },
  {
    key: "sb_legislation_in_force",
    spanish: "Legislación vigente",
    english: "Legislation in force",
    french: "Législation en vigueur",
    russian: "Действующее законодательство",
  },
  {
    key: "sb_app_download",
    spanish: "Descarga de la App",
    english: "App download",
    french: "Télécharger l'App",
    russian: "Скачать приложение",
  },
  {
    key: "h_topbar",
    spanish: "Servicio de registro de alojamientos turísticos en Andorra",
    english: "Tourist accommodation registration service in Andorra",
    french: "Servei de registre d'ocupació d'allotjaments turístics d'Andorra",
    russian: "Служба регистрации туристического жилья в Андорре",
  },
  {
    key: "h_contactus",
    spanish: "Contacte con nosotros",
    english: "Contact Us",
    french: "Contactez nous",
    russian: "Свяжитесь с нами",
  },
  {
    key: "contacts_phone",
    spanish: "+376 388 887 [WhatsApp]",
    english: "+376 388 887 [WhatsApp]",
    french: "+376 388 887 [WhatsApp]",
    russian: "+376 388 887 [WhatsApp]",
  },
  {
    key: "contacts_email",
    spanish: "support@telsight.eu",
    english: "support@telsight.eu",
    french: "support@telsight.eu",
    russian: "support@telsight.eu",
  },
  {
    key: "contacts_time",
    spanish: "09:00 to 17:00",
    english: "09:00 to 17:00",
    french: "09:00 to 17:00",
    russian: "09:00 to 17:00",
  },
  // ReservationFind
  {
    key: "h_tourist_reservation",
    spanish:
      "Herramienta de registro turístico rápida y automatizada para viajes en Andorra",
    english:
      "Swift and automated tourist registration tool for trips in Andorra",
    french:
      "Outil d'enregistrement touristique rapide et automatisé pour les voyages en Andorre",
    russian:
      "Быстрый, автоматизированный инструмент регистрации туристов для поездок в Андорру",
  },
  {
    key: "l_apartment_code",
    spanish: "Código de alojamiento",
    english: "Apartment Code",
    french: "Code logement",
    russian: "Код квартиры",
  }, // ph_apartment_code
  {
    key: "ph_apartment_code",
    spanish: "XXX000",
    english: "XXX000",
    french: "XXX000",
    russian: "XXX000",
  },
  {
    key: "l_checkin",
    spanish: "Fecha de entrada",
    english: "Date Check-in",
    french: "Date d'entrée",
    russian: "Дата заселения",
  },
  {
    key: "l_checkout",
    spanish: "Fecha de salida",
    english: "Date Check-out",
    french: "Date de départ",
    russian: "Дата отъезда",
  },
  {
    key: "l_email",
    spanish: "Su correo electrónico",
    english: "Your Email",
    french: "Votre adresse électronique",
    russian: "Ваш Email",
  },
  {
    key: "ph_email",
    spanish: "email@domain.com",
    english: "email@domain.com",
    french: "email@domain.com",
    russian: "email@domain.com",
  },
  {
    key: "l_code",
    spanish: "Código recibido",
    english: "Code Received",
    french: "Code reçu",
    russian: "Полученный код",
  },
  {
    key: "ph_code",
    spanish: "S2W9X1",
    english: "S2W9X1",
    french: "S2W9X1",
    russian: "S2W9X1",
  },
  {
    key: "btn_validate",
    spanish: "Validar datos",
    english: "Validate Data",
    french: "Valider les données",
    russian: "Подтвердить",
  },
  {
    key: "btn_start_checkin",
    spanish: "Iniciar el registro",
    english: "Start Check-in",
    french: "Début de l'enregistrement",
    russian: "Начать резервацию",
  },
  {
    key: "msg_code_sent",
    spanish: "Se le ha enviado un correo electrónico con un código",
    english: "Email was sent to you with a code",
    french: "Un email vous a été envoyé avec un code",
    russian: "Вам было отправлено электронное письмо с кодом",
  },
  {
    key: "h_watch_checkin",
    spanish: "Vea nuestro vídeo, proceso de registro de huéspedes",
    english: "Watch our video, guests check-in process",
    french: "Regardez notre vidéo, processus d'enregistrement des invités",
    russian: "Посмотрите наше видео, процесс регистрации гостей",
  },
  {
    key: "m_res_not_found",
    spanish: "Reserva no encontrada",
    english: "Reservation not found",
    french: "Réservation non trouvée",
    russian: "Резервация не найдена",
  },
  {
    key: "m_res_error",
    spanish: "Error al determinar la reserva",
    english: "Error while determining reservation",
    french: "Erreur lors de la détermination de la réservation",
    russian: "Ошибка при поиске резервации",
  },
  {
    key: "m_code_sent",
    spanish: "Se le ha enviado un correo electrónico con un código",
    english: "Email was sent to you with a code",
    french: "Un courriel vous a été envoyé avec un code",
    russian: "Вам было отправлено электронное письмо с кодом",
  },
  {
    key: "m_code_send_failed",
    spanish: "Error al enviar el código",
    english: "Failed to send code",
    french: "Un courriel vous a été envoyé avec un code",
    russian: "Не удалось отправить код",
  },
  {
    key: "m_validate_res",
    spanish: "Por favor, valide primero la reserva",
    english: "Please, validate reservation first",
    french: "Veuillez d'abord valider la réservation",
    russian: "Пожалуйста, сначала подтвердите резервацию",
  },
  {
    key: "m_code_invalid",
    spanish: "el código no es válido",
    english: "Invalid code",
    french: "Code invalide",
    russian: "Неверный код",
  },
  {
    key: "m_failed_authorize",
    spanish: "No se ha autorizado",
    english: "Failed to authorize",
    french: "Échec de l'autorisation",
    russian: "Не удалось авторизоваться",
  },
  {
    key: "m_res_app_error",
    spanish: "No se ha podido determinar la reserva",
    english: "Failed to determine reservation application",
    french: "Échec de la détermination de la demande de réservation",
    russian: "Не удалось определить заявку на резервацию",
  },
  {
    key: "btn_ok",
    spanish: "Ok",
    english: "Ok",
    french: "Ok",
    russian: "Ok",
  },
  {
    key: "m_try_login",
    spanish: "Por favor, conéctese",
    english: "Please, Log in",
    french: "Veuillez vous connecter",
    russian: "Пожалуйста, войдите в систему",
  },
  // GUESTS
  {
    key: "t_expected_photo",
    spanish:
      "Foto o archivo esperado, como en el ejemplo de al lado. Página del pasaporte con las 2 líneas de texto en la parte inferior, suficiente luz, sin desenfoque, página completa, sin dedos enmascarando ningún texto. Para los documentos de identidad lo mismo pero de ambas caras",
    english:
      "Expected photo or file, as above example. Passport page with the 2 lines of text at the bottom, enough light, no blur, full page, no fingers masking any text. For identity cards same thing but of both sides",
    french:
      "Photo ou fichier attendu, comme dans l'exemple ci-contre. Page de passeport avec les 2 lignes de texte en bas, lumière suffisante, pas de flou, pleine page, pas de doigts masquant le texte. Pour les documents d'identité, même chose mais des deux côtés.",
    russian:
      "Ожидаемая фотография или файл, как в примере напротив. Страница паспорта с 2 строками текста внизу, достаточно света, без размытия, на всю страницу, пальцы не загораживают текст. Для документов, удостоверяющих личность, то же самое, но с обеих сторон.",
  },
  {
    key: "btn_new_guest",
    spanish: "Nuevo huesped",
    english: "New Guest",
    french: "Nouvel hôte",
    russian: "Новый гость",
  },
  {
    key: "btn_checkin",
    spanish: "Check-in",
    english: "Check-in",
    french: "Check-in",
    russian: "Регистрация",
  },
  {
    key: "btn_save_guest",
    spanish: "Guardar huesped",
    english: "Save Guest",
    french: "Sauvegarder l’hôte",
    russian: "Сохранить гостя",
  },
  {
    key: "m_guest_limit",
    spanish: "Se admiten 6 huéspedes por reserva",
    english: "6 guests are allowed per reservation",
    french: "6 personnes sont autorisées par réservation",
    russian: "Допускается 6 гостей на одно бронирование",
  },
  {
    key: "m_data_not_validated",
    spanish: "Por favor, valide los datos",
    english: "Please validate the data",
    french: "Veuillez valider les données",
    russian: "Пожалуйста, проверьте данные",
  },
  {
    key: "m_image_extract_error",
    spanish: "Error al extraer datos de la imagen",
    english: "Failed to extract data from image",
    french: "Échec de l'extraction des données de l'image",
    russian: "Не удалось извлечь данные из изображения",
  },
  {
    key: "m_some_fields_invalid",
    spanish: "Algunos campos no son válidos o están vacíos",
    english: "Some fields are invalid or empty",
    french: "Certains champs sont invalides ou vides",
    russian: "Некоторые поля недействительны или пусты",
  },
  {
    key: "btn_passport",
    spanish: "Pasaporte",
    english: "Passport",
    french: "Passeport",
    russian: "Паспорт",
  },
  {
    key: "btn_idcard",
    spanish: "Documento de identidad",
    english: "Identity card",
    french: "Carte d'identité",
    russian: "идентификационная карта",
  },
  {
    key: "l_photo",
    spanish: "Foto",
    english: "Photo",
    french: "Photo",
    russian: "Фото",
  },
  {
    key: "l_photo_recto",
    spanish: "Anverso de la foto",
    english: "Take a photo recto",
    french: "Recto de la photo",
    russian: "Передняя сторона",
  },
  {
    key: "l_photo_verso",
    spanish: "Reverso de la foto",
    english: "Take a photo verso",
    french: "Verso de la photo",
    russian: "Задняя сторона",
  },
  {
    key: "btn_upload",
    spanish: "Cargar archivo",
    english: "Upload Photo",
    french: "Télécharger le fichier",
    russian: "Загрузить фото",
  },
  {
    key: "btn_read",
    spanish: "Validar",
    english: "Read",
    french: "Valider",
    russian: "Подтвердить",
  },
  {
    key: "m_some_fields_or_images_invalid",
    spanish: "Algunos campos o imágenes no son válidos o están vacíos",
    english: "Some fields or images are invalid or empty",
    french: "Certains champs ou images sont invalides ou vides",
    russian: "Некоторые поля или изображения недействительны или пусты",
  },
  {
    key: "h_guest",
    spanish: "Huesped ",
    english: "Guest ",
    french: "Hôte ",
    russian: "Гость ",
  },
  {
    key: "h_guest_end",
    spanish: ", empiece por hacer o subir una foto de su documento de identidad, como exige la ley.",
    english: ", start by taking or loading a pictures of the ID card, it is mandatory by law",
    french: ", commencez par prendre ou charger une photo de la carte d'identité, la loi l’exige.",
    russian: ", сделайте или загрузите фотографию своего удостоверения личности, как того требует закон.",
  },
  {
    key: "m_photo_confirm",
    spanish: "¿Confirma que ha cargado una foto del documento de identidad de este huésped?",
    english: "Do you confirm that you have uploaded a photo of the identity document for this guest?",
    french: "Confirmez-vous que vous avez téléchargé une photo du document d'identité de cet invité ?",
    russian: "Подтверждаете ли вы, что загрузили фотографию документа, удостоверяющего личность этого гостя?",
  },
  {
    key: "m_checkin_confirm",
    spanish: "¿Confirma que ha registrado a todos los viajeros? ¿Confirma que ha cargado una foto para cada viajero?",
    english: "Do you confirm that you have registered all travellers? Do you confirm that you have uploaded a photo for each traveller?",
    french: "Confirmez-vous avoir enregistré tous les voyageurs ? Confirmez-vous avoir chargé une photo pour chaque voyageur ?",
    russian: "Подтверждаете ли вы, что зарегистрировали всех гостей? Подтверждаете ли вы, что загрузили фотографию для каждого гостя?",
  },
  {
    key: "btn_yes",
    spanish: "SI",
    english: "YES",
    french: "OUI",
    russian: "ДА",
  },
  {
    key: "btn_no",
    spanish: "NO",
    english: "NO",
    french: "NON",
    russian: "НЕТ",
  },
  {
    key: "t_firstname",
    spanish: "Nombre",
    english: "First Name",
    french: "Prénom",
    russian: "Имя",
  },
  {
    key: "ph_firstname",
    spanish: "Jordi",
    english: "Jordi",
    french: "Jordi",
    russian: "Jordi",
  },
  {
    key: "t_lastname",
    spanish: "Apellido",
    english: "Last Name",
    french: "Nom",
    russian: "Фамилия",
  },
  {
    key: "ph_lastname",
    spanish: "Pujol",
    english: "Pujol",
    french: "Pujol",
    russian: "Pujol",
  },
  {
    key: "t_birth_date",
    spanish: "Fecha de nacimiento",
    english: "Birth Date",
    french: "Date de naissance",
    russian: "Дата рождения",
  },
  {
    key: "t_citizenship",
    spanish: "Ciudadanía",
    english: "Citizenship",
    french: "Citoyenneté",
    russian: "Гражданство",
  },
  {
    key: "t_residency",
    spanish: "Residencia",
    english: "Residency",
    french: "Résidence",
    russian: "Резиденция",
  },
  {
    key: "t_place_of_birth",
    spanish: "Lugar de nacimiento",
    english: "Place of Birth",
    french: "Lieu de naissance",
    russian: "Место рождения",
  },
  {
    key: "ph_france",
    spanish: "France",
    english: "France",
    french: "France",
    russian: "France",
  },
  // LOGIN
  {
    key: "btn_login",
    spanish: "Conectarse",
    english: "Log in",
    french: "Se connecter",
    russian: "Войти",
  },
  {
    key: "btn_reset_password",
    spanish: "¿Ha olvidado su contraseña?",
    english: "Forgot Password?",
    french: "Vous avez oublié votre mot de passe ?",
    russian: "Забыли пароль?",
  },
  {
    key: "m_invalid_creds",
    spanish: "Credenciales no válidas",
    english: "Invalid credentials",
    french: "Informations d'identification invalides",
    russian: "Неверные учетные данные",
  },
  {
    key: "l_password",
    spanish: "Contraseña",
    english: "Password",
    french: "Mot de passe",
    russian: "Пароль",
  },
  {
    key: "l_password2",
    spanish: "Repetir contraseña",
    english: "Repeat Password",
    french: "Répéter le mot de passe",
    russian: "Повторите пароль",
  },
  {
    key: "ph_password",
    spanish: "123*******",
    english: "123*******",
    french: "123*******",
    russian: "123*******",
  },
  // Register
  {
    key: "m_customer_exists",
    spanish: "Ya existe un cliente con este email",
    english: "Customer with this email already exists",
    french: "Le client avec cet email existe déjà",
    russian: "Пользователь с таким электронным адресом уже существует",
  },
  {
    key: "m_customer_exists_not_vefified",
    spanish:
      "Ya existe un cliente con esta dirección de correo electrónico, pero no está verificado. Se ha enviado un nuevo código de verificación a su correo electrónico.",
    english:
      "A customer with this email address already exists but is not verified. A new verification code has been sent to your email.",
    french:
      "Un client avec cette adresse e-mail existe déjà mais n'est pas vérifié. Un nouveau code de vérification a été envoyé à votre adresse électronique.",
    russian:
      "Пользователь с таким адресом электронной почты уже существует, но не проверен. На ваш электронный адрес отправлен новый код проверки.",
  },
  {
    key: "l_phone",
    spanish: "Su móvil",
    english: "Your Mobile",
    french: "Votre téléphone portable",
    russian: "Ваш телефон",
  },
  {
    key: "ph_phone",
    spanish: "12345 67891",
    english: "12345 67891",
    french: "12345 67891",
    russian: "12345 67891",
  },
  {
    key: "m_pass_not_match",
    spanish: "Las contraseñas no coinciden",
    english: "Passwords do not match",
    french: "Les mots de passe ne correspondent pas",
    russian: "Пароли не совпадают",
  },
  {
    key: "btn_register",
    spanish: "Registrarse",
    english: "Register",
    french: "S’enregistrer",
    russian: "Зарегистрироваться",
  },
  {
    key: "h_login_register2",
    spanish:
      "Tiene una propiedad turística y dedica demasiado tiempo a registrar a sus huéspedes y transmitir la información de éstos al servidor gubernamental. Aliviaremos sus obligaciones por un mínimo de 7,99 € al mes por propiedad.",
    english:
      "You have a tourist property and you're spending too much time checking in your guests and transmitting guests’ information to the government server. We'll ease your obligations for a minimum of 7,99€ per month per property.",
    french:
      "Vous avez un établissement touristique et vous passez trop de temps à enregistrer vos clients et à transmettre les informations les concernant au serveur du gouvernement. Nous vous soulageons de ces obligations pour un minimum de 7,99€ par mois et par établissement.",
    russian:
      "У вас есть туристическая недвижимость, и вы тратите слишком много времени на регистрацию гостей и передачу информации о них на государственный сервер. Мы облегчим ваши обязанности минимум за 7,99 евро в месяц за объект.",
  },
  {
    key: "h_login_register3",
    spanish:
      "Con RoatAndorra sus huéspedes disponen de un servicio sencillo y seguro que les permite registrarse por sí mismos, antes de su viaje. Sus datos se te envían automáticamente y se cargan en el servidor del gobierno andorrano.",
    english:
      "With RoatAndorra your guests have a simple and secure service that allows them to register by themselves, before their journey. Their information is automatically sent to you and uploaded onto the Andorran government server.",
    french:
      "Avec RoatAndorra, vos invités disposent d'un service simple et sécurisé qui leur permet de s'enregistrer eux-mêmes, avant leur voyage. Leurs informations vous sont automatiquement envoyées et téléchargées sur le serveur du gouvernement andorran.",
    russian:
      "С RoatAndorra у ваших гостей есть простой и безопасный сервис, который позволяет им самостоятельно зарегистрироваться перед поездкой. Их информация автоматически отправляется вам и загружается на сервер правительства Андорры.",
  },

  // Accomodations
  {
    key: "m_res_app_exist",
    spanish: "Esta reserva ya existe",
    english: "This reservation already exists",
    french: "Cette réservation existe déjà",
    russian: "Эта резервация уже существует",
  },
  {
    key: "btn_add_property",
    spanish: "Añadir propiedad",
    english: "Add property",
    french: "Ajouter un bien",
    russian: "Добавить собственность",
  },
  {
    key: "btn_register_tourist_batch",
    spanish: "Registrar un lote de usuarios",
    english: "Register a batch of users",
    french: "Enregistrer un lot d'utilisateurs",
    russian: "Регистрация партии пользователей",
  },
  {
    key: "btn_view_reservation",
    spanish: "Ver reservas",
    english: "View Reservations",
    french: "Voir les réservations",
    russian: "Посмотреть резервацию",
  },
  {
    key: "btn_invoices",
    spanish: "Facturas",
    english: "Invoices",
    french: "Factures",
    russian: "Счета",
  },
  {
    key: "h_registered_property",
    spanish: "Ver reservas de la propiedad ",
    english: "Registered Property ",
    french: "Propriété enregistrée ",
    russian: "Зарегистрированная собственность ",
  },

  {
    key: "l_roat_number",
    spanish: "Código Roat HUT",
    english: "HUT Roat Code",
    french: "Code HUT Roat",
    russian: "Код HUT Roat",
  },
  {
    key: "ph_roat_number",
    spanish: "HUT5-00000",
    english: "HUT5-00000",
    french: "HUT5-00000",
    russian: "HUT5-00000",
  },
  {
    key: "l_address",
    spanish: "Dirección",
    english: "Street Address",
    french: "Adresse",
    russian: "Адрес",
  },
  {
    key: "ph_address",
    spanish: "Av.Dr Mitjavila, 30",
    english: "Av.Dr Mitjavila, 30",
    french: "Av.Dr Mitjavila, 30",
    russian: "Av.Dr Mitjavila, 30",
  },
  {
    key: "l_postal_code_city",
    spanish: "Código postal",
    english: "Postel Code",
    french: "Code postal",
    russian: "Почтовый индекс",
  },
  {
    key: "ph_postal_code_city",
    spanish: "AD500 Andorra la vella",
    english: "AD500 Andorra la vella",
    french: "AD500 Andorra la vella",
    russian: "AD500 Andorra la vella",
  },
  {
    key: "l_room_code",
    spanish: "Su código de alojamiento",
    english: "Your Room Code",
    french: "Votre code de l'hébergement",
    russian: "Код недвижимости",
  },
  {
    key: "ph_room_code",
    spanish: "JJJ999",
    english: "JJJ999",
    french: "JJJ999",
    russian: "JJJ999",
  },
  {
    key: "t_room_code",
    spanish:
      "Este código es el que comunicará a sus huéspedes cuando tengan que registrarse en este alojamiento",
    english:
      "This code is the code you will communicate to your guests when they have to check-in in this apartment",
    french:
      "Ce code est celui que vous communiquerez à vos invités lorsqu'ils devront s'enregistrer dans cet appartement",
    russian:
      "Этот код вы будете сообщать своим гостям, когда им понадобится заселиться в эту квартиру.",
  },

  // Guide
  {
    key: "btn_see_prices",
    spanish: "Ver precios",
    english: "See Prices",
    french: "Voir les prix",
    russian: "Посмотреть цены",
  },
  {
    key: "btn_see_terms",
    spanish: "Ver condiciones",
    english: "See Terms",
    french: "Voir les conditions",
    russian: "Посмотреть условия",
  },
  {
    key: "h_guide",
    spanish:
      "Tiene una propiedad turística y está perdiendo demasiado tiempo registrando a sus huéspedes y transmitiendo la información de éstos al servidor del gobierno. Por 1€ por registro, quedará exento de una parte de sus obligaciones.",
    english:
      "You have a tourist property and you're wasting too much time checking in your guests and transmitting guests’ information to the government server. For 1€ per check-in you will be relieved from all obligations",
    french:
      "Vous avez un établissement touristique et vous perdez trop de temps à enregistrer vos hôtes et à transmettre leurs informations au serveur de l'administration. Pour 1 € par enregistrement, vous serez dispensé de certaines de vos obligations.",
    russian:
      "У вас есть туристическая недвижимость, и вы тратите слишком много времени на регистрацию гостей и передачу информации о них на государственный сервер. За 1 евро за резервацию вы будете освобождены от всех обязательств",
  },
  {
    key: "t_guide",
    spanish:
      "¿Cómo podemos ayudarle? Proporcionamos a sus huéspedes un servicio simplificado que les permite registrarse, antes de su estancia, de forma sencilla y segura. Cuando estancia, la información se envía automáticamente al servidor del gobierno andorrano.",
    english:
      "How can we help you? We provide your guests with a simplified service that allows them to check in, before their stay, in a simple and secure way. When their stay starts, the information is sent automatically to the Andorran government server",
    french:
      "Comment pouvons-nous vous aider ? Nous proposons à vos hôtes un service simplifié qui leur permet de s'inscrire avant leur séjour, de manière simple et sécurisée. Lors de leur séjour, les informations vous sont automatiquement envoyées et au serveur du gouvernement andorran.",
    russian:
      "Чем мы можем вам помочь? Мы предоставляем вашим гостям упрощенную услугу, которая позволяет им зарегистрироваться до начала пребывания в отеле простым и безопасным способом. После начала пребывания информация автоматически отправляется на сервер правительства Андорры.",
  },
  {
    key: "h_terms",
    spanish: "Condiciones generales de venta",
    english: "General terms and conditions of sale",
    french: "Conditions générales de vente",
    russian: "Правила и условия продажи",
  },
  {
    key: "t_terms",
    spanish:
      "Registre su residencia en el sitio web. Recibirá un código único por cada residencia que registre. Hay una cuota de alta para cada residencia (1 o 2 euros). Cuando tenga invitados, envíeles el código único de la propiedad que le hemos proporcionado y el enlace a este sitio web. Introducen el código, las fechas de entrada y salida y hacen una foto de todos los documentos de identidad. Se leen los documentos y pueden registrarse. Una vez que se hayan registrado, se le cobrarán 1 o 2 euros por la transacción (o menos, según el volumen). La información se le envía por correo electrónico una vez finalizado el check-in. Puede impugnar una factura escribiendo a sales@roatandorra.com con la referencia de la factura, la fecha y el código de reserva que le enviamos por correo electrónico.",
    english:
      "Register your residence on the website. You will receive a unique code for each residence you register. There is a set-up fee for each residence (1 or 2 euros). When you have guests, send them the unique property code we provided you and the link to this web site. They enter the code, the check-in and check-out dates, and take a photo of all the identity documents. The documents are read and they can check in. Once they have checked in, you will be charged 1 or 2 euros for the transaction (or less depending on the volume). The information is sent to you by email upon check-in completed. You can dispute an invoice by writing to sales@roatandorra.com with the invoice reference, date and booking code sent to you by email.",
    french:
      "Enregistrez votre résidence sur le site web. Vous recevrez un code unique pour chaque résidence enregistrée. Il y a des frais d'enregistrement pour chaque résidence (1 ou 2 euros). Lorsque vous recevez des invités, envoyez-leur le code unique de la résidence que nous vous avons fourni et le lien vers ce site web. Ils entrent le code, les dates d'arrivée et de départ et prennent une photo de tous leurs documents d'identité. Les documents sont lus et ils peuvent s'enregistrer. Une fois l'enregistrement effectué, la transaction vous sera facturée 1 ou 2 euros (ou moins, en fonction du volume). Les informations vous sont envoyées par e-mail une fois l'enregistrement effectué. Vous pouvez contester une facture en écrivant à sales@roatandorra.com en indiquant la référence de la facture, la date et le code de réservation qui vous a été envoyé par e-mail.",
    russian:
      "Зарегистрируйте свою квартиру на сайте. Вы получите уникальный код для каждой зарегистрированной квартиры. За регистрацию взимается плата (1 или 2 евро). Когда у вас будут гости, отправьте им уникальный код резиденции, который мы вам предоставили, и ссылку на этот сайт. Они вводят код, указывают даты заезда и выезда и фотографируют все документы, удостоверяющие личность. Документы считываются, и они могут регистрироваться. После того как они зарегистрируются, с вас возьмут 1 или 2 евро за транзакцию (или меньше, в зависимости от объема). Информация будет отправлена вам по электронной почте после завершения регистрации. Вы можете оспорить счет, написав на адрес sales@roatandorra.com с указанием ссылки на счет, даты и кода бронирования, присланного вам по электронной почте.",
  },
  {
    key: "t_terms_note_1",
    spanish:
      "Puede darse de baja en cualquier momento, de forma gratuita, enviando un simple correo electrónico a support@telsight.eu con su nombre, número de móvil y los códigos de propiedad asociados. En caso de reclamación entre las dos partes, se tratará en los Tribunales del Principado de Andorra, sin posibilidad de aplicar otra legislación de otro país. Persona de contacto y dirección: Equipo comercial de Univo, sales@roatandorra.com, +376388887.",
    english:
      "You can terminate your subscription at any time, free of charge, sending a simple email to support@telsight.eu with your name, mobile number and the associated property codes. In the event of a claim between the two parties, it will be dealt with in Andorran courts. Contact person and address. : Univo sales team, sales@roatandorra.com, +376388887.",
    french:
      "Vous pouvez vous désinscrire à tout moment et gratuitement en envoyant un simple e-mail à support@telsight.eu avec votre nom, votre numéro de téléphone portable et les codes de propriété associés. En cas de litige entre les deux parties, celui-ci sera traité par les tribunaux de la Principauté d'Andorre, sans possibilité d'appliquer la législation d'un autre pays. Personne de contact et adresse : Équipe commerciale d'Univo, sales@roatandorra.com, +376388887.",
    russian:
      "Вы можете в любой момент бесплатно прекратить подписку, отправив простое электронное письмо на адрес support@telsight.eu с указанием вашего имени, номера мобильного телефона и соответствующих кодов собственности. В случае возникновения претензий между двумя сторонами, они будут рассматриваться в судах Андорры. Контактное лицо и адрес: отдел продаж Univo, sales@roatandorra.com, +376388887.",
  },
  {
    key: "t_terms_note_2",
    spanish:
      "En caso de reclamación entre las dos partes, se tratará en los Tribunales del Principado de Andorra, sin posibilidad de aplicar otra legislación de otro país.",
    english:
      "En cas de reclamació entre les dues parts, es tractarà en Tribunals del Principat d’Andorra, sense possibilitat d’aplicar una altra legislació d’un altre país.",
    french: "",
    russian: "",
  },
  {
    key: "h_customer_guide_lines",
    spanish: "Líneas directrices para los clientes",
    english: "Customers guide lines",
    french: "Lignes directrices pour les clients",
    russian: "Рекомендации для клиентов",
  },
  {
    key: "h_customer_guide_lines_info",
    spanish:
      "Nuestros clientes, propietarios de viviendas turísticas, directores de hoteles, ... Turistas y huéspedes",
    english:
      "Our customers, tourist properties owners, hotel managers, ... Tourists and guests",
    french:
      "Nos clients, propriétaires de maisons de vacances, gérants d'hôtels, ... Touristes et hôtes",
    russian:
      "Наши клиенты, владельцы домов отдыха, менеджеры отелей, ... Туристы и гости",
  },
  {
    key: "h_step",
    spanish: "PASO ",
    english: "STEP ",
    french: "ÉTAPE ",
    russian: "Шаг ",
  },
  {
    key: "t_gl_customer_1",
    spanish:
      "En la App o en la página web, nuestros clientes definen un nombre de usuario y una contraseña para gestionar su cuenta Roat App Cuenta de cliente creado",
    english:
      "In the App or in the website, our customers define a user name and password to manage their Roat App Account Customer account created",
    french:
      "Dans l'App ou sur le site web, nos clients définissent un nom d'utilisateur et un mot de passe pour gérer leur compte Roat App Compte client créé",
    russian:
      "В приложении или на веб-сайте наши клиенты определяют имя пользователя и пароль для управления созданной учетной записью клиента Roat App",
  },
  {
    key: "t_gl_customer_2",
    spanish:
      "Nuestros clientes registran sus residencias utilizando el código HUT de sus propiedades turísticas, y lo insertan en la App Roat, ejemplo de estructura HUT: HUT5-999999 Código interno de la propiedad generado",
    english:
      "Our customers register their residencies using the government HUT  code of their tourists properties, and insert it in the App Roat, example of HUT structure: HUT5-999999 Internal property code generated",
    french:
      "Nos clients enregistrent leurs résidences en utilisant le code HUT de leurs propriétés touristiques et l'insèrent dans l'application Roat, exemple de structure HUT : HUT5-999999. Code interne de la propriété généré",
    russian:
      "Наши клиенты регистрируют свои резиденции, используя государственный код HUT своей туристической недвижимости, и вставляют его в приложение Roat, пример структуры HUT: HUT5-999999 сгенерированный внутренний код недвижимости",
  },
  {
    key: "t_gl_customer_3",
    spanish:
      "Los clientes nos facilitan su nombre de usuario Roat Government (también llamado el MIL) y la contraseña existente, que utilizan para acceder a sus cuentas del servidor ROAT y donde normalmente cargan la información de sus huéspedes. Así la App cargará automáticamente los datos. El cliente proporciona la MIL y la contraseña",
    english:
      "The customers provide us with their Roat Government User Name (also called the MIL) and the existing password, that they use to access their ROAT server accounts and where they normally load their guests information. So the App will upload automatically the data\n Customer provides MIL & pasword",
    french:
      "Les clients nous fournissent leur nom d'utilisateur Roat Government (également appelé MIL) et le mot de passe existant, qu'ils utilisent pour accéder à leur compte ROAT. pour accéder à leurs comptes de serveur ROAT et où ils téléchargent normalement les informations relatives à leurs invités. L'application téléchargera alors automatiquement les données. Le client fournit le MIL et le mot de passe",
    russian:
      "Клиенты предоставляют нам свое имя пользователя Roat Government (также известное как MIL) и пароль, которые они используют для доступа к своим учетным записям на сервере ROAT и куда они обычно загружают информацию о гостях. Таким образом, приложение автоматически загрузит данные, предоставленные MIL и паролем клиента.",
  },
  {
    key: "t_gl_customer_4",
    spanish:
      'Los clientes envían a sus invitados, "Roat App" nombre para Google App / Apple Store, la dirección del sitio web www.roatandorra.com Y el código de la propiedad (nunca comunique su código ROAT HUT)',
    english:
      "The customers send to their guests, “Roat App” name for Google App / Apple Store download, website address www.roatandorra.com AND the property code (never communicate your ROAT HUT code)",
    french:
      'Les clients envoient à leurs invités le nom de "Roat App" pour Google App / Apple Store, l\'adresse du site web www.roatandorra.com et le  code de la propriété (ne jamais communiquer le code de votre ROAT HUT)',
    russian:
      'Клиенты отправляют своим гостям название "Roat App" из Google App / Apple Store, адрес сайта www.roatandorra.com и код собственности (никогда не сообщайте код вашего ROAT HUT).',
  },

  {
    key: "h_tourist_guide_lines",
    spanish: "Guías turísticas",
    english: "Tourists guide lines",
    french: "Guides touristiques",
    russian: "Рекомендации для туристов",
  },
  {
    key: "h_tourist_guide_lines_info",
    spanish: "Turistas e invitados Cuenta de cliente creada",
    english: "Tourists and guests Customer account created",
    french: "Touristes et invités Création d'un compte client",
    russian: "Туристы и гости Создание учетной записи клиента",
  },
  {
    key: "t_gl_tourist_1",
    spanish:
      "Los huéspedes descargan e instalan la aplicación Roat en su smartphone o utilizar el sitio web",
    english:
      "Guests download and install the Roat App on their smartphone or use the website",
    french:
      "Les hôtes téléchargent et installent l'application Roat sur leur smartphone ou utilisent le site web Roat.",
    russian:
      "Гости скачивают и устанавливают приложение Roat на свой смартфон или используют веб-сайт Roat.",
  },
  {
    key: "t_gl_tourist_2",
    spanish:
      "Introduzca la residencia, utilizando el código interno de la propiedad proporcionado por el propietario de la residencia o la dirección del hotel, las fechas de entrada y salida un correo electrónico para recibir la confirmación",
    english:
      "Enter residence, using the internal property code provided by the residency owner or hotel management, the check-in and check-out dates, an email to receive the confirmation",
    french:
      "Saisissez la résidence, en utilisant le code interne fourni par le propriétaire de la résidence ou l'adresse de l'hôtel, les dates d'arrivée et de départ et l'adresse électronique à laquelle vous recevrez la confirmation.",
    russian:
      "Указывают место проживания, используя код собственности, предоставленный владельцем резиденции или администрацией отеля, даты заезда и отъезда, электронную почту для получения подтверждения",
  },
  {
    key: "t_gl_tourist_3",
    spanish:
      "Tome las fotos o cargue los pasaportes o documentos de identidad, y pulse extraer los datos y corregirlos manualmente si es necesario. Check-in completado, correo electrónico enviado, datos transmitidos al Servidor ROAT, datos almacenados si se ha seleccionado la opción",
    english:
      "Take the photos or upload the passports or identity cards, and press extract the data and correct manually if needed.\n\nCheck-in complete, email sent, data transmitted to government ROAT server, data stored if option selected",
    french:
      "Prenez des photos ou téléchargez des passeports ou des documents d'identité, puis cliquez sur Extraire les données et corrigez-les manuellement si nécessaire. L'enregistrement est terminé, l'e-mail est envoyé, les données sont transmises au serveur ROAT, les données sont stockées si vous avez sélectionné l'option",
    russian:
      "Фотографируют или загружают паспорта или идентификационные карты, затем извлекают данные и при необходимости исправляют их вручную.\n\nПроисходит завершение регистрации, отправка электронной почты, передача данных на правительственный сервер ROAT, сохранение данных, если выбрана опция",
  },
  {
    key: "t_gl_tourist_4",
    spanish:
      "Los clientes envían a sus huéspedes la dirección del sitio web www.roatandorra.com y el código de la propiedad",
    english:
      "The customers send to their guests, the website address www.roatandorra.com and the property code",
    french:
      "Les clients envoient à leurs invités l'adresse du site web www.roatandorra.com et le code de la propriété",
    russian:
      "Клиенты отправляют своим гостям адрес сайта www.roatandorra.com и код недвижимости",
  },
  {
    key: "t_gl_summary_title",
    spanish:
      "Tiene una propiedad turística y está perdiendo demasiado tiempo registrando a sus huéspedes y transmitiendo la información de éstos al servidor del gobierno. Por 1€ por registro, quedará exento de una parte de sus obligaciones.",
    english:
      "You have a tourist property and you're wasting too much time checking in your guests and transmitting guests’ information to the government server. For 1€ per check-in you will be relieved from all obligations",
    french:
      "Les clients envoient à leurs invités l'adresse du site web www.roatandorra.com et le code de la propriété",
    russian:
      "Вы управляете туристическим заведением и тратите слишком много времени на регистрацию гостей и передачу их данных на административный сервер. За 1 евро за регистрацию вы будете освобождены от некоторых обязательств.",
  },
  {
    key: "t_gl_summary",
    spanish:
      "¿Cómo podemos ayudarle? Proporcionamos a sus huéspedes un servicio simplificado que les permite registrarse, antes de su estancia, de forma sencilla y segura. Cuando estancia, la información se envía automáticamente al servidor del gobierno andorrano.",
    english:
      "How can we help you? We provide your guests with a simplified service that allows them to check in themselves, before their stay, in a simple and secure way. When their stay starts, the information is sent automatically to the Andorran government server",
    french:
      "Comment pouvons-nous vous aider ? Nous proposons à vos hôtes un service simplifié qui leur permet de s'inscrire avant leur séjour, de manière simple et sécurisée. Lors de leur séjour, les informations vous sont automatiquement envoyées et au serveur du gouvernement andorran.",
    russian:
      "Чем мы можем вам помочь? Мы предлагаем вашим гостям простую и безопасную услугу, которая позволяет им зарегистрироваться до начала пребывания. Во время их пребывания информация автоматически отправляется вам и на сервер правительства Андорры.",
  },
  {
    key: "h_video_tourist",
    spanish: "Nuestros vídeos Registro de turistas",
    english: "Our videos Tourists check-in",
    french: "Nos vidéos Enregistrement touristique",
    russian: "Наше видео Регистрация туристов",
  },
  {
    key: "h_video_customer_register",
    spanish: "Nuestros vídeos Registro de clientes",
    english: "Our videos Customers registration",
    french: "Nos vidéos Enregistrement des clients",
    russian: "Наше видео Регистрация клиентов",
  },
  {
    key: "h_video_customer_property",
    spanish: "Nuestros vídeos Registro de la propiedad nueva",
    english: "Our videos Registration of new property",
    french: "Vidéos pour l'enregistrement d'une nouvelle résidence",
    russian: "Наше видео Регистрация новой недвижимости",
  },
  // Prices

  {
    key: "h_prices",
    spanish:
      "Sin cuota anual, sin suscripción mínima, sin cargos ocultos, cancelación gratuita cuando quiera, sólo se cobra por transacción",
    english:
      "No annual fee, no minimum subscription, no hidden fee, cancel for free whenever you want, charged only by transaction",
    french:
      "Pas de cotisation annuelle, pas d'abonnement minimum, pas de frais cachés, résiliation gratuite quand vous le souhaitez, facturation par transaction uniquement.",
    russian:
      "Без ежегодной платы, без минимальной подписки, без скрытых платежей, бесплатная отмена по вашему желанию, оплата только за транзакцию.",
  },
  {
    key: "h_prices_charges",
    spanish: "¿Cómo se le cobrará?",
    english: "How will you be charged?",
    french: "Comment le service vous sera facturé ?",
    russian: "Как вам будет выставлен счет за услуги?",
  },
  {
    key: "t_prices_charges_1",
    spanish:
      "Cuando renueve/cambie su tarjeta de crédito, se le aplicará la misma comisión de apertura.",
    english:
      "When you renovate/change your credit card, the same set up fee is applicable.",
    french:
      "Lorsque vous renouvelez/changez votre carte de crédit, les mêmes frais de mise en service s'appliquent.",
    russian:
      "При обновлении/замене кредитной карты взимается та же плата за оформление.",
  },
  {
    key: "t_prices_charges_2",
    spanish:
      "Cuando configure inicialmente el servicio, se le cobrará 1€ si decide almacenar los datos de sus invitados o 2€ si prefiere que almacenemos sus datos por usted.",
    english:
      "When you initially set up the service, you are either charged 1€ if you decide to store your guests’ data or 2€ if you prefer we store their data for you.",
    french:
      "Lors de la mise en place initiale du service, vous devrez payer 1 € si vous choisissez de stocker les données de vos invités ou 2 € si vous préférez que nous stockions leurs données pour vous.",
    russian:
      "При первоначальной настройке сервиса с вас будет взиматься плата в размере 1 евро, если вы решите хранить данные своих гостей, или 2 евро, если вы предпочтете, чтобы мы хранили их данные за вас.",
  },
  {
    key: "t_prices_charges_3",
    spanish:
      "Una vez que sus huéspedes se hayan registrado, se le cobrará 1,00€ si ha decidido asumir la responsabilidad de almacenar los datos de sus huéspedes durante un año (normativa legal legal del gobierno), o 2€ si nos deja almacenar los datos de los huéspedes en nuestros servidores seguros y redundantes, independientemente del número de huéspedes que entren en la residencia (hasta 6 huéspedes por reserva como máximo).",
    english:
      "Once your guests have checked in, you will be charged 1,00€ if you have decided to take responsibility of storing your guests' details for one year (legal government’s regulation), or 2€ if you let us store the guests’ details on our secure and redundant servers, regardless of the number of guests entering the residence (up to 6 guests per reservation maximum).",
    french:
      "Après l'enregistrement de vos invités, vous serez facturé 1,00 € si vous avez décidé de prendre la responsabilité de conserver les données de vos invités pendant un an (réglementation gouvernementale légale), ou 2,00 € si vous nous laissez conserver les données de vos invités sur nos serveurs sécurisés et redondants, quel que soit le nombre d'invités entrant dans la résidence (jusqu'à 6 invités par réservation au maximum).",
    russian:
      "После заселения гостей с вас будет снята плата в размере 1,00 евро, если вы решили взять на себя ответственность за хранение данных ваших гостей в течение одного года (по закону), или 2,00 евро, если вы разрешите нам хранить данные ваших гостей на наших защищенных, резервных серверах, независимо от количества гостей, въехавших в резиденцию (не более 6 гостей за одно бронирование).",
  },
  {
    key: "t_prices_charges_4",
    spanish:
      "Los datos de los huéspedes con la información de la reserva se le envían siempre por correo electrónico antes de que lleguen.",
    english:
      "The guests details with reservation information is always sent to you by email before they arrive.",
    french:
      "Les coordonnées du client et les informations relatives à la réservation vous sont toujours envoyées par courrier électronique avant l'arrivée.",
    russian:
      "Данные о гостях с информацией о бронировании всегда отправляются вам по электронной почте до их приезда.",
  },
  {
    key: "t_prices_note",
    spanish:
      "En caso de reclamación entre las dos partes se tratará en tribunales andorranos. Persona de contacto y dirección. : Univo SL, sales@roatandorra.com, +376388887",
    english:
      "In the event of a claim between the two parties, it will be dealt with in Andorran courts. En cas de reclamació entre les dues parts es tractarà en tribunals andorrans. Persona de contacte i adreça. : Univo SL, sales@roatandorra.com, +376388887",
    french:
      "En cas de litige entre les deux parties, celui-ci sera traité par les tribunaux andorrans. Personne de contact et adresse. Univo SL, sales@roatandorra.com, +376388887",
    russian:
      "Любые споры между двумя сторонами будут рассматриваться в судах Андорры. Контактное лицо и адрес. Univo SL, sales@roatandorra.com, +376388887",
  },
  {
    key: "h_discounting_rate",
    spanish: "¿Cómo se aplica la tarifa reducida?",
    english: "How the discounted rate is applied?",
    french: "Comment le tarif réduit est-il appliqué ?",
    russian: "Как применяется сниженная ставка?",
  },
  {
    key: "t_discounting_rate",
    spanish:
      "Cuando el número de reservas llegue a 500 durante el año natural, las 500 reservas siguientes se cobrarán automáticamente 0,77 euros (sin almacenamiento) o 1,52 euros (con almacenamiento), tal y como se describe en la tabla lateral anterior.",
    english:
      "When the number of reservation reaches 500 during the calendar year, the next 500 reservation, are charged 0,77€ (no storage) or 1,52€ (with storage) as described in the side table above.",
    french:
      "Lorsque le nombre de réservations atteint 500 au cours de l'année civile, les 500 réservations suivantes seront automatiquement facturées 0,77 EUR (sans stockage) ou 1,52 EUR (avec stockage), comme décrit dans le tableau latéral ci-dessus.",
    russian:
      "Когда количество бронирований в течение календарного года достигнет 500, следующие 500 бронирований будут автоматически оплачиваться по цене EUR 0,77 (без хранения) или EUR 1,52 (с хранением), как описано в таблице выше.",
  },
  {
    key: "th_num_of_res",
    spanish: "Número de reserva (hasta)",
    english: "Number of annual резервирований",
    french: "Nombre de réserves (jusqu'à)",
    russian: "Количество резервов (до)",
  },
  {
    key: "th_fee_no_storage",
    spanish: "Tarifa por reserva o registro Sin almacenamiento de datos",
    english: "Fee per reservation or check-ins No data storage",
    french:
      "Frais par réservation ou enregistrement Pas de stockage de données",
    russian:
      "Плата за каждое резервирование или регистрацию Без хранения данных",
  },
  {
    key: "th_fee_with_storage",
    spanish: "Tarifa por reserva o registro con almacenamiento de datos",
    english: "Fee per reservation or check-ins with data storage",
    french: "Frais par réservation ou enregistrement avec stockage des données",
    russian:
      "Плата за каждое резервирование или регистрацию с хранением данных",
  },
  {
    key: "th_discount",
    spanish: "Nivel de descuento basado en registros volumen anual",
    english: "Discount level base on check-ins annual volum",
    french: "Niveau de remise basé sur les  enregistrements volume annuel",
    russian: "Уровень скидки на основе годовой объем записей",
  },

  // Password Reset
  {
    key: "btn_generate_code",
    spanish: "Generar código",
    english: "Generate Code",
    french: "Générer le code",
    russian: "Сгенерировать код",
  },
  {
    key: "btn_verify_code",
    spanish: "Verificar código",
    english: "Verify Code",
    french: "Vérifier le code",
    russian: "Подтвердить код",
  },
  {
    key: "btn_change_password",
    spanish: "Cambiar contraseña",
    english: "Change Password",
    french: "Modifier le mot de passe",
    russian: "Изменить пароль",
  },

  // Customer Dashboard
  {
    key: "h_from",
    spanish: "Desde",
    english: "From",
    french: "De",
    russian: "От",
  },
  {
    key: "h_to",
    spanish: "A",
    english: "To",
    french: "A",
    russian: "До",
  },

  {
    key: "th_reservation_code",
    spanish: "Código de reserva",
    english: "Reservation Code",
    french: "Code de réservation",
    russian: "Код резервации",
  },
  {
    key: "th_checkin",
    spanish: "Entrada",
    english: "Check in",
    french: "Entrée",
    russian: "Заезд",
  },
  {
    key: "th_checkout",
    spanish: "Salida",
    english: "Check out",
    french: "Sortie",
    russian: "Отъезд",
  },
  {
    key: "th_lastname",
    spanish: "Apellido",
    english: "Last name",
    french: "Nom",
    russian: "Фамилия",
  },
  {
    key: "th_firstname",
    spanish: "Nombre",
    english: "First name",
    french: "Prénom",
    russian: "Имя",
  },
  {
    key: "th_birthdate",
    spanish: "Nacimiento fecha",
    english: "Birth date",
    french: "Date de naissance",
    russian: "Дата рождения",
  },
  {
    key: "th_birthplace",
    spanish: "Lugar de nacimiento",
    english: "Birth place",
    french: "Lieu de naissance",
    russian: "Место рождения",
  },
  {
    key: "th_citizenship",
    spanish: "Ciudadanía",
    english: "Citizenship",
    french: "Citoyenneté",
    russian: "Гражданство",
  },
  {
    key: "th_residency",
    spanish: "Residencia",
    english: "Residency",
    french: "Résidence",
    russian: "Резиденция",
  },
  {
    key: "th_edit",
    spanish: "Editar",
    english: "Edit",
    french: "Editer",
    russian: "Редактировать",
  },
  {
    key: "th_edit2",
    spanish: "Editar",
    english: "Edit",
    french: "Editer",
    russian: "Изменено",
  },
  {
    key: "th_guest_cancel",
    spanish: "Invitado Cancelar",
    english: "Guest Cancel",
    french: "Invité Annuler",
    russian: "Отменить гостя",
  },
  {
    key: "th_cancel",
    spanish: "Cancelar",
    english: "Cancel",
    french: "Annuler",
    russian: "Отмена",
  },
  {
    key: "th_date_canceled",
    spanish: "Fecha cancelado",
    english: "Date cancelled",
    french: "Date annulé",
    russian: "Дата отмены",
  },
  {
    key: "th_date_checked",
    spanish: "Fecha de control",
    english: "Date checked",
    french: "Date de contrôle",
    russian: "Дата заполнения",
  },
  {
    key: "th_date_modified",
    spanish: "Fecha modificado",
    english: "Date modified",
    french: "Date modifié",
    russian: "Дата изменения",
  },
  {
    key: "th_transmitted",
    spanish: "Transmitido",
    english: "Transmitted",
    french: "Transmis",
    russian: "Отправлено",
  },
  {
    key: "th_date_transmitted",
    spanish: "Fecha de transmisión",
    english: "Date transmitted",
    french: "Date de transmission",
    russian: "Дата отправки",
  },

  {
    key: "h_view_property",
    spanish: "Ver propiedad ",
    english: "View Property ",
    french: "Voir la propriété ",
    russian: "Просмотр недвижимости ",
  },
  {
    key: "h_reservation",
    spanish: " Reserva",
    english: " Reservation",
    french: " Réservation",
    russian: " Резервация",
  },
  {
    key: "btn_save",
    spanish: "Consulte",
    english: "Save",
    french: "Voir",
    russian: "Сохранить",
  },
  {
    key: "btn_stop_editing",
    spanish: "Dejar de editar",
    english: "Stop Editing",
    french: "Arrêter l'édition",
    russian: "Прекратить редактирование",
  },

  {
    key: "btn_dash",
    spanish: "-",
    english: "-",
    french: "-",
    russian: "-",
  },
  {
    key: "m_no_changes",
    spanish: "No se han encontrado cambios",
    english: "No changes found",
    french: "Aucune modification n'a été trouvée",
    russian: "Изменения не найдены",
  },
  {
    key: "m_commit_failed",
    spanish: "No se han podido guardar los cambios",
    english: "Failed to save changes",
    french: "Échec de l'enregistrement des modifications",
    russian: "Не удалось сохранить изменения",
  },

  // Admin
  {
    key: "btn_visualize",
    spanish: "Visualice",
    english: "Visualize",
    french: "Visualiser",
    russian: "Просмотр",
  },
  {
    key: "btn_global",
    spanish: "Global",
    english: "Global",
    french: "Mondial",
    russian: "Глобальный",
  },
  {
    key: "btn_customer",
    spanish: "Cliente",
    english: "Customer",
    french: "Client",
    russian: "Клиент",
  },
  {
    key: "btn_reservation",
    spanish: "Property",
    english: "Property",
    french: "Property",
    russian: "Property",
  },
  {
    key: "btn_reservation_application",
    spanish: "Reservation",
    english: "Reservation",
    french: "Reservation",
    russian: "Reservation",
  },

  {
    // table customer
    key: "th_client_code",
    spanish: "Código cliente",
    english: "Client code",
    french: "Code client",
    russian: "Код клиента",
  },
  {
    key: "th_mobile_number",
    spanish: "Móvil Nb",
    english: "Mobile Nb",
    french: "Mobile Nb",
    russian: "Номер телефона",
  },
  {
    key: "th_email",
    spanish: "Correo electrónico",
    english: "Email",
    french: "Adresse électronique",
    russian: "Электронная почта",
  },
  {
    key: "th_date_register",
    spanish: "Registro de fechas",
    english: "Date register",
    french: "Registre des dates",
    russian: "Дата регистрации",
  },
  {
    key: "th_number_of_property",
    spanish: "Número de bienes",
    english: "Nb of property",
    french: "Nombre de biens",
    russian: "Количество объектов недвижимости",
  },
  {
    key: "th_number_of_checkin",
    spanish: "Número de registros",
    english: "Nb of check-in",
    french: "Nombre d'enregistrement",
    russian: "Количество регистраций",
  },
  {
    key: "th_data_storage",
    spanish: "Almacenamiento de datos",
    english: "Data storage",
    french: "Stockage des données",
    russian: "Хранение данных",
  },
  {
    key: "th_date_storage",
    spanish: "Almacenamiento de fechas",
    english: "Date storage",
    french: "Stockage des dates",
    russian: "Дата хранения данных",
  },
  {
    key: "th_fee_applied",
    spanish: "Tasa aplicada",
    english: "Fee applied",
    french: "Frais appliqués",
    russian: "Применяемая плата",
  },
  {
    key: "th_total_invoiced",
    spanish: "Total facturado",
    english: "Total Invoiced",
    french: "Total facturé",
    russian: "Всего выставлено счетов",
  },
  {
    key: "th_roat_code",
    spanish: "Código Roat",
    english: "Roat code",
    french: "Code Roat",
    russian: "Код Roat",
  },
  {
    key: "th_address",
    spanish: "Dirección",
    english: "Adress",
    french: "Adresse",
    russian: "Адрес",
  },
  {
    key: "th_city",
    spanish: "Ciudad",
    english: "City",
    french: "Ville",
    russian: "Город",
  },
  {
    key: "th_register_at",
    spanish: "Registro de fechas",
    english: "Date register",
    french: "Registre des dates",
    russian: "Дата регистрации",
  },
  {
    key: "th_reservation_count",
    spanish: "Reserva",
    english: "Reservation",
    french: "Réservation",
    russian: "Резервирование",
  },
  {
    key: "th_extraction_count",
    spanish: "Extracción Att.",
    english: "Extraction Att.",
    french: "Extraction Att.",
    russian: "Попытки извлечения",
  },
  {
    key: "th_success",
    spanish: "Éxito",
    english: "Success",
    french: "Succès",
    russian: "Удачные попытки",
  },
  {
    key: "th_rate",
    spanish: "Tarifa",
    english: "Rate",
    french: "Taux",
    russian: "Доля",
  },
  {
    key: "th_last_check_in",
    spanish: "Último registro",
    english: "Last check-in",
    french: "Dernier enregistrement",
    russian: "Последняя регистрация",
  },
  {
    key: "th_room_code",
    spanish: "Código de alojamiento",
    english: "Room Code",
    french: "Code d'hébergement",
    russian: "Код недвижимости",
  },
  {
    key: "t_admin_level",
    spanish: "Admin - nivel",
    english: "Admin - level",
    french: "Admin - niveau",
    russian: "Администратор - уровень",
  },

  {
    key: "t_customer_details",
    spanish: "Datos del cliente",
    english: "Customer details",
    french: "Coordonnées du client",
    russian: "Информация о клиенте",
  },
  {
    key: "t_reservation_details",
    spanish: "Detalles de las propiedades",
    english: "Properties details",
    french: "Détails des propriétés",
    russian: "Информация о недвижимости",
  },
  {
    key: "t_reservation_application_details",
    spanish: "Detalles de la reserva",
    english: "Reservation details",
    french: "Détails de la réservation",
    russian: "Информация о резервации",
  },
  {
    key: "t_guest_details",
    spanish: "Guest details",
    english: "Guest details",
    french: "Guest details",
    russian: "Guest details",
  },
  {
    key: "ph_no_room_code",
    spanish: "Sin código",
    english: "No Code",
    french: "Pas de code",
    russian: "Нет кода",
  },

  {
    key: "btn_new_manager_registration",
    spanish: "Registro de nuevos gestores",
    english: "New Manager Registeration",
    french: "Enregistrement d'un nouveau gestionnaire",
    russian: "Регистрация новых менеджеров",
  },
  {
    key: "h_subscribe_property",
    spanish: "Suscripción Propiedad ",
    english: "Subscribe Property ",
    french: "Abonnement pour la propriété ",
    russian: "Зарегистрируйте собственность ",
  },
  {
    key: "t_subscribe_property",
    spanish:
      "Ahora registrará su propiedad, se le cobrará 1 euro si prefiere almacenar los datos de sus huéspedes durante 1 año. Esto es obligatorio por ley. Se le cobrará 2 euros si prefiere que almacenemos los datos para usted en nuestros servidores securizados.",
    english:
      "You will now register your property, you will be charged 1 euro if you prefer to store your guests data for 1 year. This is mandatory by law. You will be charged 2 euros if you prefer we store the data for you on our secured servers.",
    french:
      "Vous allez maintenant enregistrer votre propriété, vous devrez payer 1 euro si vous préférez conserver les données de vos hôtes pendant un an. Ceci est requis par la loi. Vous devrez payer 2 euros si vous préférez que nous stockions les données pour vous sur nos serveurs sécurisés.",
    russian:
      "Теперь вы зарегистрируете свою собственность, вам придется заплатить 1 евро, если вы хотите сохранить данные своих гостей на один год. Этого требует закон. Вам придется заплатить 2 евро, если предпочтете, чтобы мы хранили данные на наших защищенных серверах.",
  },
  {
    key: "btn_store_customer",
    spanish: "Almaceno los datos",
    english: "I store my guests data",
    french: "Je stocke les données",
    russian: "Я храню данные",
  },
  {
    key: "btn_store_server",
    spanish: "Univo almacena los datos ",
    english: "Univo stores the guests data",
    french: "Univo stocke les données ",
    russian: "Univo хранит данные ",
  },
  {
    key: "t_store_customer",
    spanish: "1 euro per check-in",
    english: "1 euro / check-in",
    french: "1 euro par check-in",
    russian: "1 евро за регистрацию",
  },
  {
    key: "t_store_server",
    spanish: "2 euros per check-in",
    english: "2 euros / check-in",
    french: "2 euros par check-in",
    russian: "2 евро за регистрацию",
  },
  {
    key: "t_subscribe_description",
    spanish:
      "Si sus huéspedes se registran correctamente, se le cobrarán 1 o 2 euros (con o sin almacenamiento de datos).",
    english:
      "Upon a successful check-in by your guests you will either be charged 1 or 2 euros (data storage or not)",
    french:
      "Si vos invités enregistrent correctement leurs données, vous devrez payer 1 ou 2 euros (sans ou avec stockage de données).",
    russian:
      "Если ваши гости правильно зарегистрируют свои данные, с вас будет списано €1 или €2 (с сохранением или без сохранения данных).",
  },
  {
    key: "m_failed_change_storage",
    spanish: "Error al cambiar el almacenamiento",
    english: "Failed to change storage",
    french: "Échec du changement de stockage",
    russian: "Не удалось изменить хранилище",
  },
  // Select the payment method
  {
    key: "t_select_payment_method",
    spanish: "Seleccione el método de pago",
    english: "Select the payment method",
    french: "Sélectionnez la méthode de paiement",
    russian: "Выберите способ оплаты",
  },
  {
    key: "btn_credit_card",
    spanish: "Credit Card - TPV virtual",
    english: "Credit Card - TPV virtual",
    french: "Carte de Credit - TPV virtuel",
    russian: "Кредитная карта - TPV virtual",
  },
  // Registered Customer Log in
  {
    key: "h_login",
    spanish: "Cliente registrado Iniciar sesión",
    english: "Registered Customer Log in",
    french: "Connexion des clients enregistrés",
    russian: "Вход для зарегистрированных клиентов",
  },
  {
    key: "h_register",
    spanish: "Registro gratuito de nuevos clientes ",
    english: "New Customer Free Registeration ",
    french: "Inscription gratuite pour les nouveaux clients ",
    russian: "Бесплатная регистрация новых клиентов",
  },
  {
    key: "h_register_title",
    spanish: "Después de registrarse, nuestros gestores no tienen nada que hacer, excepto dar a sus huéspedes el código de la propiedad y esta dirección de la página web: https://roatandorra.com/ antes de que vengan a Andorra.",
    english: "After registration, our managers have nothing to do, except giving their guests the property code and this web site address: https://roatandorra.com/ before they come to Andorra",
    french: "Après l'enregistrement, nos gestionnaires n'ont rien à faire, si ce n'est communiquer à leurs hôtes le code de la propriété et l'adresse de ce site web : https://roatandorra.com/ avant qu'ils ne viennent en Andorre.",
    russian: "После регистрации нашим менеджерам ничего не остается делать, кроме как сообщить своим гостям код недвижимости и адрес этого сайта: https://roatandorra.com/, прежде чем они приедут в Андорру.",
  },
  // In the event of a claim between the two parties, it will be dealt with in Andorran courts). En cas de reclamació entre les dues parts es tractarà en tribunals andorrans). Persona de contacte i adreça. : Univo SL, sales@roatandorra.com, +376388887
  {
    key: "t_tourists_note",
    spanish:
      "En caso de reclamación entre las dos partes se tratará en tribunales andorranos. Persona de contacte i adreça. : Univo SL, sales@roatandorra.com, +376388887",
    english:
      "In the event of a claim between the two parties, it will be dealt with in Andorran courts). En cas de reclamació entre les dues parts es tractarà en tribunals andorrans). Persona de contacte i adreça. : Univo SL, sales@roatandorra.com, +376388887",
    french:
      "En cas de litige entre les deux parties, les tribunaux andorrans seront compétents. Personne de contact et adresse : Univo SL, sales@roatandorra.com, +376388887",
    russian:
      "В случае возникновения спора между двумя сторонами юрисдикция распространяется на суды Андорры. Контактное лицо и адрес: Univo SL, sales@roatandorra.com, +376388887",
  },
  {
    key: "t_customer_note",
    spanish:
      "En caso de reclamación entre las dos partes se tratará en tribunales andorranos. Persona de contacto y dirección. : Univo SL, sales@roatandorra.com, +376388887",
    english:
      "In the event of a claim between the two parties, it will be dealt with in Andorran courts. En cas de reclamació entre les dues parts es tractarà en tribunals andorrans. Persona de contacte i adreça. : Univo SL, sales@roatandorra.com, +376388887",
    french:
      "En cas de litige entre les deux parties, il sera traité par les tribunaux andorrans. Personne de contact et adresse. Univo SL, sales@roatandorra.com, +376388887",
    russian:
      "Любые споры между двумя сторонами будут рассматриваться в судах Андорры. Контактное лицо и адрес. Univo SL, sales@roatandorra.com, +376388887",
  },
  {
    key: "t_subscribe_note_1",
    spanish:
      "Puede darse de baja en cualquier momento, de forma gratuita, enviando un simple correo electrónico a support@telsight.eu con su nombre, número de móvil y los códigos de propiedad asociados. En caso de reclamación entre las dos partes, se tratará en los Tribunales del Principado de Andorra, sin posibilidad de aplicar otra legislación de otro país. Persona de contacto y dirección: Equipo comercial de Univo, sales@roatandorra.com, +376388887.",
    english:
      "You can terminate your subscription at any time, free of charge, sending a simple email to support@telsight.eu with your name, mobile number and the associated property codes. In the event of a claim between the two parties, it will be dealt with in Andorran courts. Contact person and address. : Univo sales team, sales@roatandorra.com, +376388887.",
    french:
      "Vous pouvez vous désinscrire à tout moment et gratuitement en envoyant un simple e-mail à support@telsight.eu avec votre nom, votre numéro de téléphone portable et les codes de propriété associés. En cas de litige entre les deux parties, celui-ci sera traité par les tribunaux andorrans. Personne de contact et adresse : Équipe commerciale d'Univo, sales@roatandorra.com, +376388887. ",
    russian:
      "Вы можете отказаться от подписки в любое время, бесплатно, отправив простое электронное письмо на адрес support@telsight.eu с указанием вашего имени, номера мобильного телефона и соответствующих кодов собственности. Любые споры между двумя сторонами будут рассматриваться в судах Княжества Андорра без возможности применения законодательства любой другой страны. Контактное лицо и адрес: отдел продаж Univo, sales@roatandorra.com, +376388887. ",
  },
  {
    key: "t_subscribe_note_2",
    spanish:
      "En caso de reclamación entre las dos partes, se tratará en los Tribunales del Principado de Andorra, sin posibilidad de aplicar otra legislación de otro país.",
    english:
      "En cas de reclamació entre les dues parts, es tractarà en Tribunals del Principat d’Andorra, sense possibilitat d’aplicar una altra legislació d’un altre país.",
    french: "",
    russian: "",
  },

  // ROAT UPDATE
  {
    key: "l_roat_client_id",
    spanish: "Identificador de cliente ROAT (número de registro mercantil)",
    english: "ROAT client identifier (commerce registry number)",
    french: "Identifiant du client ROAT (numéro du registre du commerce)",
    russian: "Идентификатор клиента ROAT",
  },
  {
    key: "l_mil_id",
    spanish: "número de identificación ROAT del administrador del establecimiento, asociado a la identidad MIL",
    english: "ROAT identification number of the administrator of the establishment, associated with the MIL identity",
    french: "Numéro d'identification ROAT de l'administrateur de l'établissement, associé à l'identité MIL",
    russian: "идентификационный номер ROAT администратора заведения, связанный с идентификатором MIL",
  },
  {
    key: "l_roat_password",
    spanish: "Contraseña ROAT",
    english: "ROAT Password",
    french: "Mot de passe ROAT",
    russian: "Пароль ROAT",
  },
  {
    key: "h_roat_update",
    spanish: "Actualización de las credenciales ROAT",
    english: "ROAT Credentials update",
    french: "Mise à jour des accréditations ROAT",
    russian: "Обновление учетных данных ROAT",
  },
  {
    key: "btn_roat_credentials",
    spanish: "Actualizar credenciales ROAT",
    english: "Update ROAT Credentials",
    french: "Mise à jour des données d'identification ROAT",
    russian: "Обновление учетных данных ROAT",
  },
  {
    key: "m_failed_roat_update",
    spanish: "Error al actualizar las credenciales ROAT",
    english: "Failed to update ROAT Credentials",
    french: "Échec de la mise à jour des informations d'identification ROAT",
    russian: "Не удалось обновить учетные данные ROAT",
  },
  {
    key: "m_success_roat_update",
    spanish: "Credenciales ROAT actualizadas correctamente",
    english: "ROAT Credentials successfully updated",
    french: "Mise à jour réussie des données d'identification ROAT",
    russian: "Учетные данные ROAT успешно обновлены",
  },
  {
    key: "btn_back",
    spanish: "Volver",
    english: "Back",
    french: "Retour",
    russian: "Назад",
  },

  // GuestStepsPage
  {
    key: "s_travellers_count",
    spanish: "¿Cuántos viajeros inscribirá hoy?",
    english: "How many travellers will you be registering today?",
    french: "Combien de voyageurs allez-vous enregistrer aujourd'hui ?",
    russian: "Сколько путешественников вы зарегистрируете сегодня?",
  },
  {
    key: "s_travellers_count_note",
    spanish: "(de acuerdo con las leyes del Gobierno de Andorra, todos los viajeros DEBEN estar registrados)",
    english: "(according to the laws of the Government of Andorra, all travellers MUST be registered)",
    french: "(conformément aux lois du gouvernement d'Andorre, tous les voyageurs DOIVENT être enregistrés)",
    russian: "(согласно законам правительства Андорры, все путешественники должны быть зарегистрированы)",
  },
  {
    key: "s_document_select",
    spanish: "¿Va a facturar con pasaporte o documento de identidad?",
    english: "Are you checking-in with a passport or and identity card?",
    french: "Vous enregistrez-vous avec un passeport ou une carte d'identité ?",
    russian: "Вы регистрируетесь по паспорту или удостоверению личности?",
  },
  {
    key: "s_document_select_note",
    spanish: "(permiso de conducir, permiso de trabajo, seguridad social, ... no están permitidos)",
    english: "(driving license, work permit, social security, ... are not permitted)",
    french: "(permis de conduire, permis de travail, sécurité sociale, ... ne sont pas autorisés)",
    russian: "(водительские права, разрешение на работу, социальное страхование, ... не допускаются)",
  },
  {
    key: "s_take_photo",
    spanish: "Ahora pulsa el botón verde para hacer una foto o cargar una imagen",
    english: "Now click the green button to take a photo or load an image",
    french: "Cliquez maintenant sur le bouton vert pour prendre une photo ou charger une image",
    russian: "Теперь нажмите зеленую кнопку, чтобы сделать снимок или загрузить изображение",
  },
  {
    key: "s_take_photo_recto",
    spanish: "Ahora carga el recto, haz clic en el botón verde para hacer una foto o cargar una imagen",
    english: "Now load the recto, click the green button to take a photo or load an image",
    french: "Chargez maintenant le recto, cliquez sur le bouton vert pour prendre une photo ou charger une image.",
    russian: "Теперь загрузите ректо, нажмите зеленую кнопку, чтобы сделать снимок или загрузить изображение.",
  },
  {
    key: "s_take_photo_verso",
    spanish: "Ahora tome una foto o cargue una imagen del reverso de su Documento de Identidad",
    english: "Now take a photo or load an image of the verso of your Identity Card",
    french: "Prenez une photo ou chargez une image du verso de votre carte d'identité.",
    russian: "Теперь сфотографируйте или загрузите изображение оборотной стороны вашей идентификационной карты.",
  },
  {
    key: "s_take_photo_note",
    spanish: "La foto debe ser como se muestra\nLa foto debe tener las 2 líneas de texto en la parte inferior, suficiente luz, sin desenfoque, página completa, sin dedos enmascarando ningún texto. Para los documentos de identidad, las mismas recomendaciones pero por ambas caras",
    english: "Photo must be as shown\nPhoto must have the 2 lines of text at the bottom, enough light, no blur, full page, no fingers masking any text. For identity cards same recommendations but for both sides",
    french: "La photo doit être conforme à l'illustration. Elle doit comporter les deux lignes de texte en bas, être suffisamment claire, ne pas être floue, être en pleine page et ne pas comporter de doigts masquant le texte. Pour les cartes d'identité, les mêmes recommandations s'appliquent, mais pour les deux côtés",
    russian: "Фотография должна быть такой, как показано на рисунке\nФотография должна иметь 2 строки текста внизу, достаточно света, без размытия, на всю страницу, без пальцев, маскирующих текст. Для удостоверения личности те же рекомендации, но для обеих сторон",
  },
  {
    key: "s_correct_data",
    spanish: "Compruebe ahora los datos siguientes, corríjalos si es necesario y, a continuación, valídelos",
    english: "Now check the data below, correct if needed, then validate",
    french: "Vérifiez maintenant les données ci-dessous, corrigez-les si nécessaire, puis validez",
    russian: "Теперь проверьте приведенные ниже данные, исправьте их, если нужно, и подтвердите",
  },
  {
    key: "s_recap",
    spanish: "Por favor, compruebe los siguientes datos, si son incorrectos modifíquelos, hágalo para cada huésped",
    english: "Please check the data below, if incorrect then modify, do this for each guest",
    french: "Veuillez vérifier les données ci-dessous, si elles sont incorrectes, modifiez-les, procédez ainsi pour chaque invité.",
    russian: "Пожалуйста, проверьте данные ниже, если они неверны, то измените их, сделайте это для каждого гостя",
  },
  {
    key: "s_checkin",
    spanish: "Pulse check-in para registrarse y salir",
    english: "Press check-in to register and exit",
    french: "Appuyer sur check-in pour s'enregistrer et quitter",
    russian: "Нажмите кнопку \"Регистрация\", чтобы зарегистрироваться и выйти",
  },
  {
    key: "s_newguest",
    spanish: "Pulsa \"Nuevo invitado\" para añadir un nuevo viajero",
    english: "Press \"New Guest\" to add a next traveller",
    french: "Appuyer sur \"New Guest\" pour ajouter un nouveau voyageur",
    russian: "Нажмите \"Новый гость\", чтобы добавить следующего путешественника",
  },
  {
    key: "btn_photo_recto",
    spanish: "RECTO",
    english: "RECTO",
    french: "RECTO",
    russian: "RECTO",
  },
  {
    key: "btn_photo_verso",
    spanish: "VERSO",
    english: "VERSO",
    french: "VERSO",
    russian: "VERSO",
  },
  {
    key: "h_register_tourist_batch",
    spanish: "Registrar varios usuarios con un archivo Excel",
    english: "Register multiple users with an Excel file",
    french: "Enregistrer plusieurs utilisateurs à l'aide d'un fichier Excel",
    russian: "Регистрация нескольких пользователей с помощью файла Excel",
  },
  {
    key: "t_drag_and_drop_excel",
    spanish: "Arrastre y suelte su archivo Excel",
    english: "Drag and drop your Excel file",
    french: "Glisser-déposer votre fichier Excel",
    russian: "Перетащите и отпустите файл Excel",
  },
  {
    key: "t_click_to_browse",
    spanish: "Haga clic para navegar",
    english: "Click to browse",
    french: "Cliquer pour parcourir",
    russian: "Нажмите для обзора",
  },
  {
    key: "btn_process",
    spanish: "Proceso",
    english: "Process",
    french: "Processus",
    russian: "Обработать",
  },
  {
    key: "btn_download_template",
    spanish: "Proceso",
    english: "Download the template",
    french: "Processus",
    russian: "Обработать",
  },
  {
    key: "m_conflict",
    spanish: "Sorry, batch registration failed",
    english: "Sorry, batch registration failed",
    french: "Sorry, batch registration failed",
    russian: "Sorry, batch registration failed",
  },
  {
    key: "h_legislation",
    spanish: "Normativa y recursos del Gobierno andorrano",
    english: "Andorran Government regulations and resources",
    french: "Réglementation et ressources du gouvernement andorran",
    russian: "Нормативные акты и ресурсы правительства Андорры",
  },
  // 
  {
    key: "h_app_download",
    spanish: "Normativa y recursos del Gobierno andorrano",
    english: "RoatAndorra App resources",
    french: "Réglementation et ressources du gouvernement andorran",
    russian: "Нормативные акты и ресурсы правительства Андорры",
  },
  {
    key: "btn_alert_me",
    spanish: "Alertarme",
    english: "Alert me",
    french: "M'alerter",
    russian: "Предупредите меня",
  },

  {
    key: "sb_properties",
    spanish: "Properties",
    english: "Properties",
    french: "Properties",
    russian: "Properties",
  },
  {
    key: "sb_reservations",
    spanish: "Reservations",
    english: "Reservations",
    french: "Reservations",
    russian: "Reservations",
  },
  {
    key: "sb_tourist_reservations",
    spanish: "Tourist Reservations",
    english: "Tourist Reservations",
    french: "Tourist Reservations",
    russian: "Tourist Reservations",
  },
  {
    key: "sb_ieat",
    spanish: "IEAT",
    english: "IEAT",
    french: "IEAT",
    russian: "IEAT",
  },
  {
    key: "sb_deposit",
    spanish: "Deposit",
    english: "Deposit",
    french: "Deposit",
    russian: "Deposit",
  },
  {
    key: "sb_account",
    spanish: "Account",
    english: "Account",
    french: "Account",
    russian: "Account",
  },
  {
    key: "sb_invoices",
    spanish: "Invoices",
    english: "Invoices",
    french: "Invoices",
    russian: "Invoices",
  },
  {
    key: "l_password_old",
    spanish: "Contraseña antigua",
    english: "Old Password",
    french: "Ancien mot de passe",
    russian: "Старый пароль",
  },



  {
    key: "m_user_data_update_succ",
    spanish: "User data was successfully updated",
    english: "User data was successfully updated",
    french: "User data was successfully updated",
    russian: "User data was successfully updated",
  },
  {
    key: "m_user_data_update_fail",
    spanish: "Failed to update user data",
    english: "Failed to update user data",
    french: "Failed to update user data",
    russian: "Failed to update user data",
  },
  {
    key: "m_user_password_update_succ",
    spanish: "User password was successfully updated",
    english: "User password was successfully updated",
    french: "User password was successfully updated",
    russian: "User password was successfully updated",
  },
  {
    key: "m_user_password_update_fail",
    spanish: "Failed to update user password",
    english: "Failed to update user password",
    french: "Failed to update user password",
    russian: "Failed to update user password",
  },
  {
    key: "t_companyname",
    spanish: "Company name",
    english: "Company name",
    french: "Company name",
    russian: "Company name",
  },
  {
    key: "ph_companyname",
    spanish: "Lesieur",
    english: "Lesieur",
    french: "Lesieur",
    russian: "Lesieur",
  },
  {
    key: "t_companyname_nrt",
    spanish: "Company NRT",
    english: "Company NRT",
    french: "Company NRT",
    russian: "Company NRT",
  },
  {
    key: "ph_companyname_nrt",
    spanish: "L-124563-B",
    english: "L-124563-B",
    french: "L-124563-B",
    russian: "L-124563-B",
  },
  {
    key: "btn_save_data",
    spanish: "Save Data",
    english: "Save Data",
    french: "Save Data",
    russian: "Save Data",
  },
  {
    key: "btn_save_new_password",
    spanish: "Set New Password",
    english: "Set New Password",
    french: "Set New Password",
    russian: "Set New Password",
  },
  {
    key: "l_password_old",
    spanish: "Old Password",
    english: "Old Password",
    french: "Old Password",
    russian: "Old Password",
  },
  {
    key: "ph_password_old",
    spanish: "321*******",
    english: "321*******",
    french: "321*******",
    russian: "321*******",
  },

  {
    key: "h_admin_login_title",
    spanish: "ADMINISTRATION",
    english: "ADMINISTRATION",
    french: "ADMINISTRATION",
    russian: "ADMINISTRATION",
  },
  {
    key: "h_admin_login_subtitle",
    spanish: "NO EXTERNAL USER ALLOWED - IF YOU SEE THIS PAGE PLEASE ALERT  support@telsight.eu",
    english: "NO EXTERNAL USER ALLOWED - IF YOU SEE THIS PAGE PLEASE ALERT  support@telsight.eu",
    french: "NO EXTERNAL USER ALLOWED - IF YOU SEE THIS PAGE PLEASE ALERT  support@telsight.eu",
    russian: "NO EXTERNAL USER ALLOWED - IF YOU SEE THIS PAGE PLEASE ALERT  support@telsight.eu",
  },
  {
    key: "btn_buy_package",
    spanish: "Buy package",
    english: "Buy package",
    french: "Buy package",
    russian: "Buy package",
  },
  {
    key: "btn_download",
    spanish: "Download",
    english: "Download",
    french: "Download",
    russian: "Download",
  },
  {
    key: "btn_guests",
    spanish: "All Guests",
    english: "All Guests",
    french: "All Guests",
    russian: "All Guests",
  },
  {
    key: "btn_managers",
    spanish: "Managers",
    english: "Managers",
    french: "Managers",
    russian: "Managers",
  },
  {
    key: "m_dont_have_account",
    spanish: "¿No tiene cuenta?",
    english: "Don't have an account?",
    french: "Vous n'avez pas de compte ?",
    russian: "У вас нет аккаунта?",
  },
  {
    key: "btn_register_now",
    spanish: "Regístrese ahora",
    english: "Register now",
    french: "S'inscrire",
    russian: "Зарегистрируйтесь сейчас",
  },
  {
    key: "h_property_manager_login",
    spanish: "Inicio de sesión del administrador de la propiedad",
    english: "Property Manager Login",
    french: "Connexion du gestionnaire immobilier",
    russian: "Вход для менеджера по недвижимости",
  },
  {
    key: "h_create_account",
    spanish: "Crear cuenta",
    english: "Create Account",
    french: "Créer un compte",
    russian: "Создать аккаунт",
  },
  {
    key: "m_already_have_account",
    spanish: "¿Ya tiene una cuenta?",
    english: "Already have an account?",
    french: "Vous avez déjà un compte ?",
    russian: "У вас уже есть аккаунт?",
  },
  {
    key: "btn_sign_in",
    spanish: "Iniciar sesión",
    english: "Sign in",
    french: "S'inscrire",
    russian: "Войти",
  }
];

export function getTranslation(language: string) {
  return TRANSLATION_RECORDS.reduce((r, v) => {
    r[v.key] = v[language];
    return r;
  }, {});
}
