import React from 'react';
import { Booking } from '../types/booking';
import { TouristStats } from './TouristStats';
import { TouristList } from './TouristList';

interface TouristEntriesProps {
  bookings: Booking[];
}

export function TouristEntries({ bookings }: TouristEntriesProps) {
  const allTourists = bookings.flatMap(booking => booking.tourists);
  
  return (
    <div className="space-y-6">
      <TouristStats tourists={allTourists} />
      <TouristList tourists={allTourists} />
    </div>
  );
}