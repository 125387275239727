import React from "react";
import { SortedHeader } from "./SortedHeader";

interface ISortedTableProps {
  //   selects: { key: string; label: string }[];
  sortConfig: { field: string; direction: "asc" | "desc" };
  onSort: (field: string) => void;
  headers: { key: string; label: string; sortable: boolean }[];
  rows: { [key: string]: any }[];
  renderRow: (row: any, index: number) => any;
}

export function SortedTable({
  //   selects,
  sortConfig,
  onSort,
  headers,
  rows,
  renderRow,
}: ISortedTableProps) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-[#E47E7B]">
        <thead className="bg-[#E47E7B]">
          <tr>
            {headers.map((h) =>
              h.sortable ? (
                <SortedHeader
                  label={h.label}
                  field={h.key}
                  currentSort={sortConfig}
                  onSort={onSort}
                />
              ) : (
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  {h.label}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-[#E47E7B]">
          {rows.map((row: any, index: number) => renderRow(row, index))}
        </tbody>
      </table>
    </div>
  );
}
