import React, { PropsWithChildren } from "react";

interface ILightButtonProps {
  type?: "button" | "submit" | "reset";
  handleClick?: any;
}

export const LightButton: React.FC<ILightButtonProps & PropsWithChildren> = ({
  type = "button",
  handleClick,
  children,
}) => {
  return (
    <button
      type={type}
      className="text-sm text-[#E47E7B] hover:text-[#D16B68]"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
