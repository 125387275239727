import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";

import { MyLoading, MyModal } from "../common/MyModal";
import { WEB_ROUTERS } from "../../utils/routes";
// import { CustomerLogin } from "./Login";
import { LoginForm } from "./Auth/Login";

export const LoginPage: React.FC<{}> = () => {
  const { getText } = useContext(AppContext);

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate(WEB_ROUTERS.CUSTOMER_REGISTER);
  };
  const handleForgotPasswordClick = () => {
    navigate(WEB_ROUTERS.CUSTOMER_PASSWORD_RESET);
  };

  return (
    <div className="page-content">
      {/* <h3 className="header-tag">{getText("h_login_register")}</h3> */}
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-bold text-[#E47E7B]">
            {getText("h_property_manager_login")}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <LoginForm
              setModalText={setModalText}
              setLoading={setLoading}
              onRegisterClick={handleRegisterClick}
              onForgotPasswordClick={handleForgotPasswordClick}
            />
            {/* <CustomerLogin setModalText={setModalText} setLoading={setLoading} /> */}
          </div>
        </div>
      </div>

      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
};
